import React, { useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css"
import { getBranches } from "../../utils/helper";
import { store } from "../../store";

const Branches = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const [branches, setBranches] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getUpdatedBranches();
    }, [])

    const getUpdatedBranches = () => {
        getBranches(globalCmpId).then((x) => {
            setBranches(x);
            setIsLoading(false);
        })
    }

    const Branches_rows = {
        bra_id: "",
        bra_name: "",
        bra_phone: "",
        bra_address: "",
        bra_cmp_id: "",
    };

    const addBranch = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}branches/add_branch`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedBranches();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updBranch = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}branches/upd_branch`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedBranches();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delBranch = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}branches/del_branch`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedBranches();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedBranch = [];
            const updatedBranch = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.bra_id);
                } else if (row.type === "insert") {
                    addedBranch.push({ ...row.data, bra_cmp_id: globalCmpId });
                } else {
                    const obj = { ...row.key, ...row.data, bra_cmp_id: globalCmpId };
                    updatedBranch.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delBranch(deletedIds);
            }
            if (addedBranch.length > 0) {
                addBranch(addedBranch);
            }
            if (updatedBranch.length > 0) {
                updBranch(updatedBranch);
            }
        }
    };


    const renderColumns = () => {
        const res = [];

        for (var key in Branches_rows) {
            if (Branches_rows.hasOwnProperty(key)) {
                if ("bra_id" === key) {
                    res.push(
                        <Column
                            dataField="bra_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("bra_name" === key) {
                    res.push(
                        <Column
                            dataField="bra_name"
                            caption="Name"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("bra_phone" === key) {
                    res.push(
                        <Column
                            dataField="bra_phone"
                            caption="Phone Number"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("bra_address" === key) {
                    res.push(
                        <Column
                            dataField="bra_address"
                            caption="Address"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("bra_cmp_id" === key) {
                    res.push(
                        <Column
                            dataField="bra_cmp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    dataSource={branches}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <FilterBuilder defaultFields={Branches_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default Branches;