import './Pos.css'
import '../../GlobalCSS.css'
import { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, IconButton, InputLabel, List, ListItem, ListItemText, Stack, Switch, Tab, Tabs, TextField } from '@mui/material';
import { Add, Delete, KeyboardBackspace, Paid, Remove } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { getCategories, getItems, getCurrencies, getRatesToAndFrom, convertDate, getPaymentMethods } from '../../utils/helper';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { NumericFormat } from 'react-number-format';
import {
    Column,
    Paging,
    Pager,
    Editing,
    ColumnChooser,
    Toolbar,
    Item,
    Scrolling,
    KeyboardNavigation,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import { useLocation } from 'react-router';
import * as global from '../../utils/globals';
import swal from "sweetalert"
import { store } from "../../store";
import image from "../../assets/images/dataharbor.png"
// const searchBar = () => (
//     <Box>
//         <FormControl variant="standard">
//             <InputLabel sx={{ fontSize: 20, }}>
//                 <b>Search</b>
//             </InputLabel>
//             <Input
//                 startAdornment={
//                     <InputAdornment position="start">
//                         <Search />
//                     </InputAdornment>
//                 }
//             />
//         </FormControl>
//     </Box>
// );

var id = 0;

const Pos = () => {
    const datagridRef = useRef(null);
    const cartDatagridRef = useRef(null);
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalUserID = store.getState().mainReducer.usr_id;
    const globalRates = store.getState().mainReducer.rates;
    const globalFirstCur = store.getState().mainReducer.cur_id1;
    const globalSecondCur = store.getState().mainReducer.cur_id2;

    const location = useLocation();
    const [item, setItem] = useState([]);
    const [tempItems, setTempItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [payPopup, setPayPopup] = useState(false);
    const [value, setValue] = useState(0);
    const currencies = useRef([]);
    const [cartItems, setCartItems] = useState([]);
    const [calcNum, setCalcNum] = useState(0);
    const [paymentDataGrid, setPaymentDataGrid] = useState([]);
    const [okPayment, setOkPayment] = useState();
    const [firstCurr, setFirstCurr] = useState();
    const [secondCurr, setSecondCurr] = useState();
    const [ratesToAndFrom, setRatesToAndFrom] = useState([]);
    const [tips, setTips] = useState(0);
    const [discperc, setDiscPerc] = useState(0);
    const [switchPaid, setSwitchPaid] = useState(1);
    const [numCur, setNumCur] = useState("");
    const [onHoldBtn, setOnHoldBtn] = useState("Hold");
    const [heldItems, setHeldItems] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const variantStyle = useRef([]);
    const [variantStyleButton, setVariantStyleButton] = useState([]);

    const [taxTotal, setTaxTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [paidTotal, setPaidTotal] = useState(0);
    const [discAmt, setDiscAmt] = useState(0);
    const total = subTotal - discAmt + taxTotal;

    useEffect(() => {
        getCategories(globalCmpId).then((x) => setCategories(x));
        getCurrencies(globalCmpId).then((x) => {
            currencies.current = x;
            if (x.length > 0) {
                let cur1, cur2;
                for (let cur of x) {
                    if (cur.cur_priority === 1) {
                        cur1 = cur;
                        setFirstCurr(cur);
                        setOkPayment(cur.cur_code);
                    };
                    if (cur.cur_priority === 2) {
                        cur2 = cur;
                        setSecondCurr(cur);
                    }
                }
                getRatesToAndFrom(cur1.cur_id, cur2.cur_id).then((x) => setRatesToAndFrom(x));
            }
        });
        getNewItems();
        getPaymentMethods(globalCmpId).then((x) => {
            setPaymentMethod(x);
            const tab = [];
            for (let i of x) {
                tab.push('outlined');
            }
            setVariantStyleButton(tab);
        });
    }, [])

    const getNewItems = () => {
        getItems(globalCmpId).then((x) => {
            if (x.length > 0) {
                const newIt = []
                for (let i of x) {
                    if (i.it_cur_id !== globalFirstCur) {
                        for (let exc of globalRates) {
                            if (i.it_cur_id === exc.rate_cur_id2 && globalFirstCur === exc.rate_cur_id1) {
                                i.it_unitprice = Math.round(((i.it_unitprice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                i.it_cur_id = globalFirstCur;
                                break;
                            } else if (i.it_cur_id === exc.rate_cur_id1 && globalFirstCur === exc.rate_cur_id2) {
                                i.it_unitprice = Math.round(((i.it_unitprice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                i.it_cur_id = globalFirstCur;
                                break;
                            }
                        }
                    }
                    newIt.push(i);
                }
                setItem(newIt);
                setTempItems(newIt);
            }
        });
    }

    const paymentTypeClick = (e, value, ref, i) => {
        const tab = [];
        for (let j = 0; j < variantStyleButton.length; j++) {
            if (j === i) tab[j] = 'contained';
            else tab[j] = 'outlined';
        }
        setVariantStyleButton(tab);

        for (let c of currencies.current) {
            if (c.cur_id === value) {
                setOkPayment(c.cur_code);
                setNumCur(c.cur_code);
                return;
            }
        }
    }

    const openPayPopup = () => {
        //calculatePay();
        setPayPopup(true);
    };

    const handleChange = (e, newValue) => {
        setValue(newValue)
    };

    const onCategoryClicked = (e, value) => {
        const catId = [];
        for (let cat of item) {
            if (cat.it_cat_id === value.cat_id) {
                catId.push(cat);
            }
        }
        setTempItems(catId);
    }

    const onAllCategoryClicked = (e) => {
        setTempItems(item);
    }

    const checkItemAvailability = (item) => {
        for (let ci of cartItems) {
            if (ci.it_id === item.it_id)
                return true;
        }
        return false;
    }

    const onItemClick = (e, value) => {
        if (value && !checkItemAvailability(value)) {
            const newItem = {
                it_id: value.it_id,
                it_name: value.it_name,
                it_qty: 1,
                it_total: value.it_unitprice,
                it_unitprice: value.it_unitprice,
                it_cur_id: value.it_cur_id,
                it_tax: value.it_tax,
                it_tax1: value.it_tax1,
                it_tax2: value.it_tax2,
                it_discount: 0,
            }
            setCartItems([...cartItems, newItem]);
        }
        else {
            for (let ci of cartItems) {
                if (ci.it_id === value.it_id) {
                    ci.it_qty++;
                    roundNumber(ci.it_total = ci.it_unitprice * ci.it_qty);
                    break;
                }
            }
            setCartItems([...cartItems]);
        }
    }

    useEffect(() => {
        var gross = 0;
        var totalTax = 0;
        var totalTax1 = 0;
        var totalTax2 = 0;

        for (let row of cartItems) {
            if (row.it_qty && row.it_unitprice) {
                gross = gross + (row.it_qty * row.it_unitprice) - (row.it_qty * row.it_unitprice * row.it_discount / 100);
            }
            if (row.it_tax && row.it_total) {
                totalTax = totalTax + (row.it_tax * row.it_total / 100);
            }

            if (row.it_tax1 && row.it_total) {
                totalTax1 = totalTax1 + (row.it_tax1 * row.it_total / 100);
            }

            if (row.it_tax2 && row.it_total) {
                totalTax2 = totalTax2 + (row.it_tax2 * row.it_total / 100);
            }
        }
        setDiscAmt(gross * discperc / 100);
        setSubTotal(gross);
        setTaxTotal(roundNumber((totalTax - totalTax * discperc / 100) + (totalTax1 - totalTax1 * discperc / 100) + (totalTax2 - totalTax2 * discperc / 100)));


    }, [cartItems]);

    const onRestCart = (e) => {
        setCartItems([]);
        onAllCategoryClicked(e);
        setValue(0);
        setTips(0);
        setDiscPerc(0);
        setCalcNum(0);
        setPaymentDataGrid([]);
        setTaxTotal(0);
        setSubTotal(0);
        setPaidTotal(0);
        setDiscAmt(0);
    }

    const onQtyChange = (e, value) => {
        for (let ci of cartItems) {
            if (ci.it_id === value.it_id) {
                ci.it_qty = e.target.value;
                break;
            }
        }
        setCartItems([...cartItems]);
    }

    const onButtonClick = (e, value) => {

        if (calcNum === 0 && value !== "clr") {
            setCalcNum(value);
            return;
        }

        if (value === "clr") {
            setCalcNum(0);
            return;
        }

        setCalcNum(calcNum + "" + value)
    }

    const roundNumber = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    const onFirstCurrClick = (e, value) => {
        setOkPayment(value);
        setNumCur(value);
    }

    const onSecondCurrClick = (e, value) => {
        setOkPayment(value);
        setNumCur(value);
    }

    const onBackSpaceClick = (e) => {
        if (calcNum !== 0) {
            setCalcNum(calcNum.toString().slice(0, -1));
        } else {
            setCalcNum((prevState) => prevState.toString().slice(0, -1));
        }

        if (calcNum === "" || calcNum === 0) {
            setCalcNum(0);
        }
    }

    const onOkButtonClick = (e) => {
        const newdata = [...paymentDataGrid];

        if (calcNum === "" || calcNum === 0) {
            setCalcNum(0);
            return;
        }
        else {
            newdata.push({
                id: id++,
                amount: calcNum,
                cur_code: okPayment,
            })
        }
        setCalcNum(0);
        setPaymentDataGrid([...newdata]);
    }

    useEffect(() => {
        var newAmt = 0;
        for (let i of paymentDataGrid) {
            if (i.cur_code === secondCurr?.cur_code) {
                let amt = parseInt(i.amount, 10)
                amt /= ratesToAndFrom[0]?.rate_rate;
                newAmt += amt;
            } else {
                let amt = parseInt(i.amount, 10)
                newAmt += amt;
            }
        }
        setPaidTotal(newAmt)
    }, [paymentDataGrid]);

    const handleTipsChange = (e) => {
        if (cartItems.length <= 0) {
            setTips(0);
        } else
            setTips(e.target.value);
    }

    const handleTotalDisc = (e) => {
        if (e.target.value < 0 || e.target.value > 100) {
            return;
        }
        setDiscPerc(e.target.value);
        setCartItems([...cartItems]);
    }

    const handleSwitchPaid = (e) => {
        if (e.target.checked) {
            setSwitchPaid(1);
        } else {
            setSwitchPaid(0);
        }
    }

    const getCurCode = (id) => {
        return currencies.current.find(option => option.cur_id === id)?.cur_code;
    }

    const onSaving = (data) => {
        data.changes.forEach((row, i) => {
            if (row.type === "remove") {
                const delID = row.key.id;
                const newPaymentDataGrid = [...paymentDataGrid];
                var index = newPaymentDataGrid.findIndex(i => i.id === delID);

                if (index !== -1) {
                    newPaymentDataGrid.splice(index, 1);
                    setPaymentDataGrid([...newPaymentDataGrid]);
                }
            }
        });
    }

    const onSavingCart = (data) => {
        const delID = data.it_id;
        const newCartDataGrid = [...cartItems];
        var index = newCartDataGrid.findIndex(i => i.it_id === delID);

        if (index !== -1) {
            newCartDataGrid.splice(index, 1);
            setCartItems([...newCartDataGrid]);
        }
    }

    const onEditorPreparing = (e) => {

        if (e.dataField === "it_id" && e.parentType === "dataRow") {
            e.editorOptions.min = null;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value === null) {
                    swal("Error", "Please put an Item to the cart.", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                for (let i of item) {
                    if (i.it_id === args.value) {
                        cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_unitprice", i.it_unitprice);
                        cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_tax", i.it_tax);
                        cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_tax1", i.it_tax1);
                        cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_tax2", i.it_tax2);
                        cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_total", (i.it_unitprice *
                            cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_qty")));
                    }
                }
                cartDatagridRef.current.instance.saveEditData();
                setCartItems([...cartItems]);
            }
        }

        if (e.dataField === "it_unitprice" && e.parentType === "dataRow") {
            e.editorOptions.min = null;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value === null) {
                    swal("Error", "Please put a price for the item.", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_total", (args.value *
                    cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_qty")));
                cartDatagridRef.current.instance.saveEditData();
                setCartItems([...cartItems]);

            }
        }

        if (e.dataField === "it_qty" && e.parentType === "dataRow") {
            e.editorOptions.min = null;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value === null) {
                    swal("Error", "Please add a Quantity for the item.", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_total", (args.value *
                    cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_unitprice")));
                cartDatagridRef.current.instance.saveEditData();
                setCartItems([...cartItems]);

            }
        }

        if (e.dataField === "it_discount" && e.parentType === "dataRow") {
            e.editorOptions.min = null;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value > 100 || args.value < 0) {
                    swal("Error", "Please put a value between 0 and 100.", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                let disc = (cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_unitprice") *
                    cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_qty") * args.value) / 100

                cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_total", (cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_unitprice") *
                    cartDatagridRef.current.instance.cellValue(e.row.rowIndex, "it_qty") - disc));

                cartDatagridRef.current.instance.saveEditData();

                setCartItems([...cartItems]);

            }
        }

        if (e.dataField === "it_tax" && e.parentType === "dataRow") {
            e.editorOptions.min = null;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value > 100 || args.value < 0) {
                    swal("Error", "Please put a value between 0 and 100.", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                cartDatagridRef.current.instance.saveEditData();
                setCartItems([...cartItems]);
            }
        }

        if (e.dataField === "it_tax1" && e.parentType === "dataRow") {
            e.editorOptions.min = null;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value > 100 || args.value < 0) {
                    swal("Error", "Please put a value between 0 and 100.", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                cartDatagridRef.current.instance.saveEditData();
                setCartItems([...cartItems]);
            }
        }

        if (e.dataField === "it_tax2" && e.parentType === "dataRow") {
            e.editorOptions.min = null;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value > 100 || args.value < 0) {
                    swal("Error", "Please put a value between 0 and 100.", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                cartDatagridRef.current.instance.saveEditData();
                setCartItems([...cartItems]);
            }
        }

    }

    const addInvoice = (params, iparams) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(`${global.baseUrl}invoices/add_invoice`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    addInvoiceItem(iparams, response.inv_id[0][0].inv_id);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error, "error");
                }
            }).catch((err) => console.log(err));
    }

    const addInvoiceItem = (params, id) => {
        for (let it of params)
            it.iit_inv_id = id;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(`${global.baseUrl}invoiceitems/add_invoiceitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    onRestCart();
                    setPayPopup(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error, "error");
                }
            }).catch((err) => console.log(err));
    }

    const onFinishClick = (e) => {

        const params = {};

        params.inv_cmp_id = globalCmpId;
        params.inv_duedate = convertDate(new Date());
        params.inv_tt_code = null;
        params.inv_trxnb = null;
        params.inv_cur_id = globalFirstCur
        params.inv_discountperc = discperc;
        params.inv_discountamount = discAmt;
        params.inv_wa_id = null;
        params.inv_bra_id = null;
        params.inv_prj_id = null;
        params.inv_note = "";
        params.inv_tp_id = null;
        params.inv_paid = switchPaid;
        params.inv_subtotal = subTotal;
        params.inv_totaltax = taxTotal;
        params.inv_total = total;
        params.inv_total1 = total * ratesToAndFrom[0].rate_rate;
        params.inv_user_id = globalUserID;
        params.inv_tips = tips;
        params.inv_change = roundNumber((total - paidTotal));
        params.inv_docurl = "";
        params.inv_delivered = true;

        const data = [];
        data.push(params);

        const iparams = [];
        for (let item of cartItems) {
            const param = {};
            let discAmount = 0;
            let newUnitPrice = item.it_unitprice;
            if (item.it_discount > 0 && item.it_cur_id === globalFirstCur) {
                discAmount = ((newUnitPrice * item.it_qty) * item.it_discount) / 100;
            } else if (item.it_discount > 0 && item.it_cur_id !== globalFirstCur) {
                discAmount = (((newUnitPrice * item.it_qty) / ratesToAndFrom[0]?.rate_rate) * item.it_discount) / 100;
            }

            if (item.it_cur_id !== globalFirstCur) {
                newUnitPrice /= ratesToAndFrom[0]?.rate_rate;
            }

            param.iit_it_id = item.it_id;
            param.iit_it_price = newUnitPrice;
            param.iit_qty = item.it_qty;
            param.iit_discountperc = item.it_discount;
            param.iit_discountamount = discAmount;
            param.iit_tax = item.it_tax;
            param.iit_wa_id = null;
            param.iit_note = "";
            param.iit_user_id = globalUserID;
            param.iit_div_id = null;
            param.iit_tax1 = item.it_tax1;
            param.iit_tax2 = item.it_tax2;
            iparams.push(param);
        };
        addInvoice(data, iparams);
    }

    const onHoldItems = (e, onHoldBtn) => {
        const data = [];

        if (onHoldBtn === "Hold" && cartItems.length === 0) {
            return;
        }

        if (onHoldBtn === "Hold") {
            setOnHoldBtn("Previous");
            for (let item of cartItems) {
                const held = {};
                held.it_id = item.it_id;
                held.it_name = item.it_name;
                held.it_qty = item.it_qty;
                held.it_unitprice = item.it_unitprice;
                held.it_cur_id = item.it_cur_id;
                held.it_tax = item.it_tax;
                held.it_tax1 = item.it_tax1;
                held.it_tax2 = item.it_tax2;
                held.it_discount = item.it_discount;
                held.it_total = item.it_total

                data.push(held);
            };
            setHeldItems(data);
            onRestCart(e);
            return;
        }
        if (onHoldBtn === "Previous") {
            setOnHoldBtn("Hold");
            setCartItems([...heldItems]);
            return;
        }
    };

    const deleteCellRender = (data) => (<Button onClick={() => handleDelete(data)}>Del</Button>)

    const handleDelete = (data) => {
        onSavingCart(data.data);
    }

    const renderDataGrid = () => {
        return (
            <div>
                <DataGrid
                    ref={datagridRef}
                    dataSource={paymentDataGrid}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={30}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={5} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 25, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <Editing
                        allowAdding={false}
                        allowUpdating={false}
                        allowDeleting={true}
                        confirmDelete={false}
                        mode="row"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    <Column
                        dataField='id'
                        visible={false}
                    >
                    </Column>
                    <Column
                        dataField="amount"
                        caption='Amount'
                        visible={true}
                        dataType='number'
                        alignment='left'
                    ></Column>
                    <Column
                        dataField="cur_code"
                        caption='Currency'
                        visible={true}
                    ></Column>

                </DataGrid>
            </div>
        );
    };

    const renderCartDataGrid = () => {
        return (
            <div style={{ padding: 2 }}>
                <DataGrid
                    ref={cartDatagridRef}
                    dataSource={cartItems}
                    onEditorPreparing={onEditorPreparing}
                    showBorders={false}
                    showColumnLines={true}
                    showRowLines={false}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={false}
                    allowColumnResizing={true}
                    columnMinWidth={30}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={5} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 25, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <Toolbar visible={false}>
                        <Item location="before" visible={false} name="addRowButton" />
                        <Item location="before" visible={false} name="saveButton" />
                        <Item location="before" visible={true} name="revertButton" />
                        <Item location="before" visible={true} name="searchPanel" />
                        <Item location="before" visible={true} name="groupPanel" />
                        <Item location="before" visible={false} name="columnChooserButton" />
                    </Toolbar>
                    <Editing
                        allowAdding={false}
                        allowUpdating={true}
                        allowDeleting={false}
                        confirmDelete={false}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    <Column
                        dataField='it_id'
                        visible={false}
                    ></Column>
                    <Column
                        dataField='it_name'
                        visible={true}
                        caption='Name'
                        dataType='string'
                    ></Column>
                    <Column
                        dataField='it_unitprice'
                        visible={true}
                        caption='Price'
                        dataType='number'
                        alignment='left'
                    ></Column>
                    <Column
                        dataField="it_qty"
                        caption='Quantity'
                        visible={true}
                        dataType='number'
                        alignment='left'
                    ></Column>
                    <Column
                        dataField="it_discount"
                        caption='Discount %'
                        visible={true}
                        dataType='number'
                        alignment='left'
                    ></Column>
                    <Column
                        dataField="it_tax"
                        caption='VAT'
                        visible={true}
                        dataType='number'
                        alignment='left'
                    ></Column>
                    <Column
                        dataField="it_tax1"
                        caption='Tax 1'
                        visible={true}
                        dataType='number'
                        alignment='left'
                    ></Column>
                    <Column
                        dataField="it_tax2"
                        caption='Tax 2'
                        visible={true}
                        dataType='number'
                        alignment='left'
                    ></Column>
                    <Column
                        dataField="it_total"
                        caption='Total'
                        visible={true}
                        dataType='number'
                        alignment='left'
                    ></Column>
                    <Column
                        cellRender={deleteCellRender}
                        visible={true}
                    ></Column>
                </DataGrid>
            </div>
        );
    };

    return (
        <div className='background'>
            {/* <div className='ridge-search'>
                {searchBar()}
            </div> */}
            <div className='spacing'>
                <div className='ridge-left'>
                    <div>
                        <Box sx={{
                            flexGrow: 1,
                            maxWidth: { xs: 320, sm: 910 },
                            display: 'flex',
                            borderLeftStyle: 'groove',
                            borderRadius: 12,
                            borderRightStyle: 'groove',
                        }}>
                            <Tabs
                                variant='scrollable'
                                indicatorColor='secondary'
                                textColor='inherit'
                                value={value}
                                onChange={handleChange}
                                scrollButtons
                                sx={{
                                    ['& .${tabsClasses.scrollButtons}']: {
                                        '&.Mui-disabled': { opacity: 0.3 }
                                    },
                                }}
                            >
                                <Tab label='All Categories' onClick={(e) => onAllCategoryClicked(e)} />
                                {categories.map((value) => (
                                    <Tab key={value.cat_id} label={value.cat_name} onClick={(e) => onCategoryClicked(e, value)} />
                                ))}
                            </Tabs>
                        </Box>
                        <div className='ridge-item'>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    '& > *': {
                                        justifyContent: 'start',
                                        ml: 3,
                                        mt: 1
                                    },
                                }}>
                                <Grid container>
                                    {tempItems.map((value) => (
                                        //<Grid item xs={4} sm={4} md={4} key={value.it_id}>
                                        <Card sx={{ boxShadow: 4, minWidth: 200, m: 1 }} key={value.it_id} variant='outlined'>
                                            <CardActionArea onClick={(e) => onItemClick(e, value)}>
                                                <CardMedia
                                                    component="img"
                                                    image={image}
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div" >
                                                        {value.it_name}
                                                    </Typography>
                                                    <Typography variant="h7" color="text.secondary" >
                                                        <b>{value.it_unitprice} {getCurCode(value.it_cur_id)}</b>
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                        //</Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </div>
                    </div>
                </div>
                <div className='ridge-right'>
                    <div className='ridge-cart'>
                        <Box>
                            <Box component="div"
                                sx={{
                                    p: 2,
                                    justifyContent: "space-evenly",
                                    display: "flex"
                                }}>
                                <b>Cart</b>
                            </Box>
                            {renderCartDataGrid()}
                        </Box >
                    </div>
                    <div className='ridge-sum'>
                        <div style={{ justifyContent: "start", alignItems: 'start', flexDirection: 'row', display: 'flex' }}>
                            <InputLabel sx={{ marginLeft: 1, marginTop: 1, fontSize: 20, width: 100 }}><b>Tax: </b></InputLabel>
                            <TextField
                                sx={{ marginLeft: 1, width: 180 }}
                                variant='standard'
                                value={" " + roundNumber(taxTotal)}
                                InputProps={{
                                    style: { fontSize: 20 },
                                    readOnly: true,
                                    endAdornment: firstCurr?.cur_code
                                }}
                            />
                        </div>
                        <div style={{ justifyContent: "start", alignItems: 'start', flexDirection: 'row', display: 'flex' }}>
                            <InputLabel sx={{ marginLeft: 1, marginTop: 1, fontSize: 20, width: 100 }}><b>Sub total: </b></InputLabel>
                            <TextField
                                sx={{ marginLeft: 1, width: 180 }}
                                variant='standard'
                                value={" " + roundNumber(subTotal)}
                                InputProps={{
                                    style: { fontSize: 20 },
                                    readOnly: true,
                                    endAdornment: firstCurr?.cur_code
                                }}
                            />
                        </div>
                        <div style={{ justifyContent: "start", alignItems: 'start', flexDirection: 'row', display: 'flex' }}>
                            <InputLabel sx={{ marginLeft: 1, marginTop: 1, fontSize: 20, width: 100 }}><b>Total: </b></InputLabel>
                            <TextField
                                sx={{ marginLeft: 1, width: 180 }}
                                variant='standard'
                                value={" " + roundNumber(total)}
                                InputProps={{
                                    style: { fontSize: 20 },
                                    readOnly: true,
                                    endAdornment: firstCurr?.cur_code
                                }}
                            />
                            <InputLabel sx={{ marginLeft: 8, marginTop: 1, fontSize: 20, width: 115 }}><b>Total Disc%: </b></InputLabel>
                            <TextField
                                sx={{
                                    marginLeft: 1,
                                    width: 40?.width,
                                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                                        WebkitAppearance: "none",
                                        margin: 0,
                                    },
                                    "input[type=number]": {
                                        MozAppearance: "textfield",
                                    },
                                    overflow: 'hidden'
                                }}
                                type='number'
                                variant='standard'
                                value={discperc}
                                onChange={(e) => handleTotalDisc(e)}
                                InputProps={{
                                    style: { fontSize: 20 },
                                    inputProps: { min: 0, max: 100 },
                                }}
                            />

                        </div>
                        <div>
                            <Button variant='contained' endIcon={<Paid />} sx={{ m: 1, mt: 5, height: 75, width: 200 }} onClick={openPayPopup}>Pay</Button>
                            <Button variant='outlined' sx={{ m: 1, ml: 5  }}>Advenced Options</Button>
                            <Button variant='outlined' sx={{ m: 1 }} onClick={(e) => onHoldItems(e, onHoldBtn)}>{onHoldBtn}</Button>
                            <Button variant='contained' color="error" sx={{ ml: 5 }} onClick={(e) => onRestCart(e)}>Reset</Button>
                        </div>

                    </div>
                    <div>
                        <Box>
                            <div>
                                <Popup
                                    visible={payPopup}
                                    onHiding={() => setPayPopup(false)}
                                    dragEnabled={false}
                                    showCloseButton={true}
                                    showTitle={true}
                                    width={1500}
                                    height={700}
                                >
                                    <Position
                                        at="center"
                                        my="center"
                                    />
                                    <ToolbarItem
                                        text="Payment Options"
                                        location="before">
                                    </ToolbarItem>
                                    <div>
                                        <Box sx={{ width: '100%' }}>
                                            <div className='pay'>
                                                <div className='pay-left'>
                                                    {paymentMethod.map((pay, index) => (
                                                        <Button
                                                            key={pay.pmt_id}
                                                            ref={(el) => (variantStyle.current[index] = el)}
                                                            onClick={(e) => paymentTypeClick(e, pay.pmt_cur_id, variantStyle.current[index], index)}
                                                            variant={variantStyleButton[index]}
                                                            size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4, width: 200 }}>
                                                            {pay.pmt_name}
                                                        </Button>
                                                    ))}
                                                </div>
                                                <div className='pay-middle'>
                                                    <div className='btn-ok-top'>
                                                        <div className='btn-ok'>
                                                            <NumericFormat
                                                                value={calcNum}
                                                                thousandSeparator
                                                                customInput={TextField}
                                                                suffix={" " + numCur}
                                                            />
                                                        </div>
                                                        <div className='btn-ok'>
                                                            <Button variant='contained' size='large' sx={{ pt: 2, pb: 2, pl: 4.5, pr: 4.5 }}
                                                                onClick={(e) => onOkButtonClick(e)}
                                                            >
                                                                Ok
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className='btns-pos'>
                                                        <Button onClick={(e) => onButtonClick(e, 7)} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>7</Button>
                                                        <Button onClick={(e) => onButtonClick(e, 8)} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>8</Button>
                                                        <Button onClick={(e) => onButtonClick(e, 9)} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>9</Button>
                                                    </div>
                                                    <div className='btns-pos'>
                                                        <Button variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}
                                                            onClick={(e) => onBackSpaceClick(e)}
                                                        >
                                                            <KeyboardBackspace />
                                                        </Button>
                                                    </div>
                                                    <div>
                                                        <div className='btns-pos'>
                                                            <Button onClick={(e) => onButtonClick(e, 4)} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>4</Button>
                                                            <Button onClick={(e) => onButtonClick(e, 5)} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>5</Button>
                                                            <Button onClick={(e) => onButtonClick(e, 6)} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>6</Button>
                                                        </div>
                                                        <div className='btns-pos'>
                                                            <Button variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}
                                                                onClick={(e) => onFirstCurrClick(e, (firstCurr?.cur_code))}>
                                                                {firstCurr?.cur_code}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='btns-pos'>
                                                            <Button onClick={(e) => onButtonClick(e, 1)} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>1</Button>
                                                            <Button onClick={(e) => onButtonClick(e, 2)} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>2</Button>
                                                            <Button onClick={(e) => onButtonClick(e, 3)} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>3</Button>
                                                        </div>
                                                        <div className='btns-pos'>
                                                            <Button variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4.2, pr: 4.2 }}
                                                                onClick={(e) => onSecondCurrClick(e, (secondCurr?.cur_code))}>
                                                                {secondCurr?.cur_code}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className='btns-pos'>
                                                        <Button onClick={(e) => onButtonClick(e, 0)} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>0</Button>
                                                        <Button onClick={(e) => onButtonClick(e, "000")} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 2.9, pr: 2.9 }}>000</Button>
                                                        <Button onClick={(e) => onButtonClick(e, "000000")} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 1, pr: 1 }}>000,000</Button>
                                                    </div>
                                                    <div className='btns-pos'>
                                                        <Button onClick={(e) => onButtonClick(e, "clr")} variant='outlined' size='large' sx={{ pt: 2, pb: 2, pl: 4, pr: 4 }}>
                                                            Clr
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className='pay-right'>
                                                    {renderDataGrid()}
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <div className='btns-pos'>
                                                        <InputLabel sx={{ marginLeft: 1, marginTop: 1, fontSize: 20 }}><b>Total: </b>
                                                            <TextField
                                                                sx={{ marginLeft: 2, width: 180 }}
                                                                variant='standard'
                                                                value={" " + roundNumber(total)}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: firstCurr?.cur_code
                                                                }}
                                                            />
                                                        </InputLabel>
                                                    </div>
                                                    <div className='btns-pos'>
                                                        <InputLabel sx={{ marginLeft: 1, marginTop: 1, fontSize: 20 }}><b>Total: </b>
                                                            <TextField
                                                                sx={{ marginLeft: 2, width: 180 }}
                                                                variant='standard'
                                                                value={" " + roundNumber(total * ratesToAndFrom[0]?.rate_rate)}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: secondCurr?.cur_code
                                                                }}
                                                            />
                                                        </InputLabel>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='btns-pos'>
                                                        <InputLabel sx={{ marginLeft: 1, marginTop: 1, fontSize: 20 }}><b>Paid: </b>
                                                            <TextField
                                                                sx={{ marginLeft: 2, width: 180 }}
                                                                variant='standard'
                                                                value={" " + roundNumber(paidTotal)}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: firstCurr?.cur_code
                                                                }}
                                                            />
                                                        </InputLabel>
                                                    </div>
                                                    <div className='btns-pos'>
                                                        <InputLabel sx={{ marginLeft: 1, marginTop: 1, fontSize: 20 }}><b>Paid: </b>
                                                            <TextField
                                                                sx={{ marginLeft: 2, width: 180 }}
                                                                variant='standard'
                                                                value={" " + roundNumber(paidTotal * ratesToAndFrom[0]?.rate_rate)}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: secondCurr?.cur_code
                                                                }}
                                                            />
                                                        </InputLabel>
                                                    </div>
                                                    <div className='switch'>
                                                        <Stack direction="row" spacing={1} alignItems="center">
                                                            <Typography>Not Paid</Typography>
                                                            <Switch defaultChecked value={switchPaid} onChange={(e) => handleSwitchPaid(e)} />
                                                            <Typography>Paid</Typography>
                                                        </Stack>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='btns-pos'>
                                                        <InputLabel sx={{ marginLeft: 1, marginTop: 1, fontSize: 20 }}><b>Return: </b>
                                                            <TextField
                                                                sx={{ marginLeft: 1, width: 170 }}
                                                                variant='standard'
                                                                value={" " + roundNumber((total - paidTotal))}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: firstCurr?.cur_code
                                                                }}
                                                            />
                                                        </InputLabel>
                                                    </div>
                                                    <div className='btns-pos'>
                                                        <InputLabel sx={{ marginLeft: 1, marginTop: 1, fontSize: 20 }}><b>Return: </b>
                                                            <TextField
                                                                sx={{ marginLeft: 1, width: 170 }}
                                                                variant='standard'
                                                                value={" " + roundNumber(((total * ratesToAndFrom[0]?.rate_rate) - (paidTotal * ratesToAndFrom[0]?.rate_rate)))}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: secondCurr?.cur_code
                                                                }}
                                                            />
                                                        </InputLabel>
                                                    </div>
                                                    <div className='tips'>
                                                        <InputLabel sx={{ marginLeft: 1, marginTop: 1, fontSize: 20 }}><b>Tips: </b>
                                                            <TextField
                                                                sx={{
                                                                    marginLeft: 1,
                                                                    width: 40?.width,
                                                                    "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                                                                        WebkitAppearance: "none",
                                                                        margin: 0,
                                                                    },
                                                                    "input[type=number]": {
                                                                        MozAppearance: "textfield",
                                                                    },
                                                                    overflow: 'hidden'
                                                                }}
                                                                type='number'
                                                                variant='standard'
                                                                value={tips}
                                                                onChange={(e) => handleTipsChange(e)}
                                                                InputProps={{
                                                                    endAdornment: firstCurr?.cur_code
                                                                }}
                                                            />
                                                        </InputLabel>
                                                    </div>
                                                    <div className='btns-finish'>
                                                        <Button variant='contained' onClick={(e) => onFinishClick(e)} size='large'>Finish</Button>
                                                    </div>
                                                    <div className='btns-finish'>
                                                        <Button variant='outlined' size='large'>Print</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Box>
                                    </div>
                                </Popup>
                            </div>
                        </Box>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Pos;