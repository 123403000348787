import * as global from './globals'
import swal from "sweetalert";

export const getBranches = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}branches/get_branches?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching branches",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getBrands = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}brands/get_brands?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching brands",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getCategories = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}categories/get_categories?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Categories",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getDivisions = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}divisions/get_divisions?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Divisions",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getItems = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}items/get_items?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Items",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getColors = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}colors/get_item_colors?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Colors",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getSizes = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}sizes/get_item_sizes?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Sizes",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getUnits = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}units/get_units?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Units",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getWarehouses = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}warehouses/get_warehouses?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching WareHouses",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getCurrencies = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}currencies/get_currencies?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Currency",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}


export const getMainCurrencies = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}currencies/get_main_currencies?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Currency",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getProfile = (pro_id, cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}profiles/get_profile?pro_id=${pro_id}&cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Profile",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getRoles = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}roles/get_roles?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Roles",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getDepartment = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}departments/get_departments?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Departments",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getPosition = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}positions/get_positions?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Position",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getUser = (userid) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}users/get_user?user_id=${userid}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching User",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getMainRates = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}rates/get_main_rates?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Rates",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getTransactionTypes = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}transactiontypes/get_transactiontypes?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Rates",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getCashClient = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}thirdparties/get_default_thirdparty?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Main Client",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getThirdparties = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}thirdparties/get_thirdparties?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Third parties",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getThirdpartiesType = (type, cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}thirdparties/get_thirdparties_type?type=${type}&cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Third parties",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getRates = (cur) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}rates/get_rates?cur=${cur}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Rates",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getRatesToAndFrom = (cur1, cur2) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}rates/get_fromrate_torate?rate_cur_id1=${cur1}&rate_cur_id2=${cur2}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Rates To and From",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getProjects = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}projects/get_projects?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Projects",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getEmployees = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}employees/get_employees?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Employees",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getEmployeesSalaries = (dep_id, cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}employeessalaries/get_employeessalaries?dep_id=${dep_id}&cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Employees Salary",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getEmployeesSalariesAllDeps = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}employeessalaries/get_employeessalaries_alldeps?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching All Employees Salary",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getInvoices = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}invoices/get_invoices?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Invoices",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getInvoiceItems = (inv_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}invoiceitems/get_invoiceitems?inv_id=${inv_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Invoice Items",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getPurchases = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}purchases/get_purchases?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Purchases",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getPayments = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}payments/get_payments?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Payments",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getPaymentItems = (pay_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}paymentitems/get_paymentitems?pay_id=${pay_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Payment Items",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getPurchaseItems = (pur_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}purchaseitems/get_purchaseitems?pur_id=${pur_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Purchased Items",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getPaymentMethods = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}paymentmethods/get_paymentmethods?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Payment Methods",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getReceipts = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}receipts/get_receipts?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Receipts",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getReceiptItems = (rec_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}receiptitems/get_receiptitems?rec_id=${rec_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Receipt Items",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
};

export const getStockAdjustment = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}stockadjustment/get_stockadjustments?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Stocks",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getStockAdjustmentItems = (sta_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}stockadjustmentitems/get_stockadjustmentitems?sta_id=${sta_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Stock Items",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getStockInOut = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}stockinout/get_stockinouts?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Stock In Out",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getStockInOutItems = (sio_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}stockinoutitems/get_stockinoutitems?sio_id=${sio_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Stock In Out Items",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getCompany = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}companies/get_company?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Company info",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getProductionOrders = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}productionorders/get_productionorders?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Production Orders",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getProductionOrderItems = (prod_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}productionorderitems/get_productionorderitems?prod_id=${prod_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Production Order Items",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getSections = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}sections/get_sections?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Sections",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getTables = (cmp_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}tables/get_tables?cmp_id=${cmp_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Tables",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const getMoneyBills = (pmt_id) => {
    return new Promise((resolve) => {
        fetch(`${global.baseUrl}moneybills/get_moneybills?pmt_id=${pmt_id}`)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    resolve(response);
                } else {
                    swal(
                        "error fetching Money Bills",
                        response.error.originalError.info.message,
                        "error"
                    );
                }
            })
            .catch((err) => console.log(err));
    });
}

export const convertDate = (str) => {
    var date = new Date(str);
    var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var hours = ("0" + date.getHours()).slice(-2);
    var minutes = ("0" + date.getMinutes()).slice(-2);
    var seconds = ("0" + date.getSeconds()).slice(-2);
    var year = date.getFullYear();
    return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`;
};

export const convertDateOnly = (str) => {
    var date = new Date(str);
    var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var year = date.getFullYear();
    return `${year}-${mnth}-${day}`;
};