import '../../GlobalCSS.css';
import './Dashboard.css';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fragment, useEffect, useRef, useState } from 'react';
import {
    Category, ColorizeSharp, LocationCity, Warehouse, Scale, Straighten, BrandingWatermark, Storefront, ViewModule, GroupWork
} from '@mui/icons-material';
import { getInvoiceItems, getInvoices, getThirdparties, getPurchaseItems, getPurchases, convertDateOnly, getEmployees } from '../../utils/helper';
import { store } from '../../store';
import { blue } from '@mui/material/colors';
import ButtonBase from '@mui/material/ButtonBase';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#2979ff",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: '#fafafa',
    },
    '&:nth-of-type(odd)': {
        backgroundColor: '#eeeeee',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableCellHistory = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#2979ff",
        color: theme.palette.common.white,
    }
}));

const StyledTableRowHistory = styled(TableRow)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: '#eeeeee',
    }
}));

/////
const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 100,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.60,//onhover
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: blue[900],
    opacity: 1,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));
/////

const Dashboard = ({ openDrawer, setPage, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const thirdPartyName = useRef([]);
    const [rows, setRows] = useState([]);
    const [purRows, setPurRows] = useState([]);
    const [empRows, setEmpRows] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getThirdparties(globalCmpId).then((x) => {
            x.unshift({
                tp_id: null,
                tp_name: ''
            })
            thirdPartyName.current = x;
        });

        getPurchases(globalCmpId).then(async (x) => {
            const newRows = [];
            for (let p of x) {
                const datarows = {}
                await getPurchaseItems(p.pur_id).then((y) => {
                    for (let pit of y) {
                        if (pit.pit_pur_id === p.pur_id) {
                            datarows.time = convertDateOnly(pit.pit_timestamp);
                            datarows.amount = pit.pit_qty;
                        }
                    }
                });

                let paid = "false";
                if (p.pur_paid) {
                    paid = "true";
                }
                for (let tp of thirdPartyName.current) {
                    if (tp.tp_id === p.pur_tp_id) {
                        datarows.name = tp.tp_name;
                        break;
                    }
                }

                datarows.id = p.pur_id;
                datarows.disc = p.pur_discountamount;
                datarows.number = p.pur_trxnb;
                datarows.paid = paid;
                datarows.totalitemprice = p.pur_subtotal;
                datarows.total = p.pur_total;
                datarows.totaltax = p.pur_totaltax;


                newRows.push(datarows)
            }
            setPurRows(newRows);
        });

        getInvoices(globalCmpId).then(async (x) => {
            const newRows = [];
            for (let i of x) {
                const datarows = {}
                await getInvoiceItems(i.inv_id).then((y) => {
                    for (let iit of y) {
                        if (iit.iit_inv_id === i.inv_id) {
                            datarows.time = convertDateOnly(iit.iit_timestamp);
                            datarows.amount = iit.iit_qty;
                        }
                    }
                });

                let paid = "false";
                if (i.inv_paid) {
                    paid = "true";
                }

                for (let tp of thirdPartyName.current) {
                    if (tp.tp_id === i.inv_tp_id) {
                        datarows.name = tp.tp_name;
                        break;
                    }
                }

                datarows.id = i.inv_id;
                datarows.disc = i.inv_discountamount;
                datarows.number = i.inv_trxnb;
                datarows.paid = paid;
                datarows.totalitemprice = i.inv_subtotal;
                datarows.total = i.inv_total;
                datarows.totaltax = i.inv_totaltax;

                newRows.push(datarows)
            }
            setRows(newRows);
        });

        getEmployees(globalCmpId).then((x) => {
            const newRows = [];

            for (let e of x) {
                const datarows = {};
                datarows.id = e.emp_id;
                datarows.firstname = e.emp_firstname;
                datarows.lastname = e.emp_lastname;
                datarows.empnumber = e.emp_numb;
                datarows.email = e.emp_email;

                newRows.push(datarows);
            }
            setEmpRows(newRows)
            setIsLoading(false);
        });

    }, [])

    const images = [
        {
            icon: <BrandingWatermark />,
            title: 'Branches',
            width: '20%',
            click: () => setPage("/Branches"),
        },
        {
            icon: <LocationCity />,
            title: 'Brands',
            width: '20%',
            click: () => setPage("/Brands"),
        },
        {
            icon: <Category />,
            title: 'Category',
            width: '20%',
            click: () => setPage("/Categories"),
        },
        {
            icon: <Storefront />,
            title: 'Divisions',
            width: '20%',
            click: () => setPage("/Divisions"),
        },
        {
            icon: <Warehouse />,
            title: 'Warehouse',
            width: '20%',
            click: () => setPage("/Warehouse"),
        },
    ];

    const images2 = [
        {
            icon: <ViewModule />,
            title: 'Item',
            width: '20%',
            click: () => setPage("/Item"),
        },
        {
            icon: <ColorizeSharp />,
            title: 'Item Color',
            width: '20%',
            click: () => setPage("/ItemColor"),
        },
        {
            icon: <GroupWork />,
            title: 'Project',
            width: '20%',
            click: () => setPage("/Project"),
        },
        {
            icon: <Straighten />,
            title: 'Sizes',
            width: '20%',
            click: () => setPage("/Sizes"),
        },
        {
            icon: <Scale />,
            title: 'Units',
            width: '20%',
            click: () => setPage("/Units"),
        },
    ];

    const Row = (props) => {
        const { row } = props;
        const [open, setOpen] = useState(false);

        return (
            <Fragment>
                <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <StyledTableCell width={20}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align='left' width={120}>{row.name}</StyledTableCell>
                    <StyledTableCell align="center" width={100}>{row.number}</StyledTableCell>
                    <StyledTableCell align="right" width={100}>{row.paid}</StyledTableCell>
                    <StyledTableCell align="right">{row.totaltax}</StyledTableCell>
                    <StyledTableCell align="right">{row.total}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRowHistory>
                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    History
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <StyledTableRowHistory>
                                            <StyledTableCellHistory align="left" width={200}>Date</StyledTableCellHistory>
                                            <StyledTableCellHistory>Discount</StyledTableCellHistory>
                                            <StyledTableCellHistory align="right">Amount</StyledTableCellHistory>
                                            <StyledTableCellHistory align="right">Total price ($)</StyledTableCellHistory>
                                        </StyledTableRowHistory>
                                    </TableHead>
                                </Table>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCellHistory align="left" width={200}>{row.time}</StyledTableCellHistory>
                                            <StyledTableCellHistory>{row.disc}</StyledTableCellHistory>
                                            <StyledTableCellHistory align="center">{row.amount}</StyledTableCellHistory>
                                            <StyledTableCellHistory align="right">{row.totalitemprice}</StyledTableCellHistory>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </StyledTableCell>
                </StyledTableRowHistory>
            </Fragment>
        );
    }

    return (
        <div className='main-dash'>
            <div className='invoice_purchase'>
                <div className={openDrawer ? 'invoice-right' : 'invoice-left'}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center" colSpan={10} >Invoice</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell />
                                    <StyledTableCell>Client</StyledTableCell>
                                    <StyledTableCell align="center">Number</StyledTableCell>
                                    <StyledTableCell align="right">Paid</StyledTableCell>
                                    <StyledTableCell align="right">Total Tax&nbsp;($)</StyledTableCell>
                                    <StyledTableCell align="right">Total&nbsp;($)</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper} sx={{ maxHeight: 280 }}>
                        <Table aria-label="collapsible table">
                            <TableBody>
                                {rows.map((row) => {
                                    return <Row key={row.id} row={row} />
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={'invoice-left'}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center" colSpan={10}>Purchase</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell />
                                    <StyledTableCell>Supplier</StyledTableCell>
                                    <StyledTableCell align="center">Number</StyledTableCell>
                                    <StyledTableCell align="right">Paid</StyledTableCell>
                                    <StyledTableCell align="right">Total Tax&nbsp;($)</StyledTableCell>
                                    <StyledTableCell align="right">Total ($)</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper} sx={{ maxHeight: 280 }}>
                        <Table aria-label="collapsible table">
                            <TableBody>
                                {purRows.map((row) => (
                                    <Row key={row.id} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <div className='invoice_purchase'>
                <div className={openDrawer ? 'invoice-right' : 'invoice-left'}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>First Name</StyledTableCell>
                                    <StyledTableCell>Last Name</StyledTableCell>
                                    <StyledTableCell>Employee Number</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper} sx={{ maxHeight: 280 }}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {empRows.map((row) => (
                                    <StyledTableRow
                                        key={row.id}
                                    >
                                        <StyledTableCell component="th" scope="row" width={190}>
                                            {row.firstname}
                                        </StyledTableCell>
                                        <StyledTableCell align='left' width={160}>{row.lastname}</StyledTableCell>
                                        <StyledTableCell align='center' width={300}>{row.empnumber}</StyledTableCell>
                                        <StyledTableCell align='left'>{row.email}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={'cards-left'}>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Inventory</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        {images.map((image) => (
                            <ImageButton
                                focusRipple
                                key={image.title}
                                style={{
                                    width: image.width,
                                }}
                                onClick={image.click}
                            >
                                <ImageSrc />
                                <ImageBackdrop className="MuiImageBackdrop-root" />
                                <Image>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        color="inherit"
                                        fontSize={14}
                                        sx={{
                                            position: 'relative',
                                            p: 4,
                                            pt: 2,
                                            pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                        }}
                                    >
                                        {image.title}
                                        <ImageMarked className="MuiImageMarked-root" />
                                    </Typography>
                                </Image>
                            </ImageButton>
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        {images2.map((image) => (
                            <ImageButton
                                focusRipple
                                key={image.title}
                                style={{
                                    width: image.width,
                                }}
                                onClick={image.click}
                            >
                                <ImageSrc style={{ backgroundImage: `url(${image.icon})` }} />
                                <ImageBackdrop className="MuiImageBackdrop-root" />
                                <Image>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        color="inherit"
                                        fontSize={14}
                                        sx={{
                                            position: 'relative',
                                            p: 4,
                                            pt: 2,
                                            pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                                        }}
                                    >
                                        {image.title}
                                        <ImageMarked className="MuiImageMarked-root" />
                                    </Typography>
                                </Image>
                            </ImageButton>
                        ))}
                    </Box>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;