import './App.css';
import '@fontsource/roboto/500.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Main from "./components/Main/Main"
import Login from "./components/Login/Login"
import Dashboard from './components/Dashboard/Dashboard';
import ItemColor from './components/ItemColor/ItemColor';
import Item from './components/Item/Item';
import Warehouse from './components/Warehouse/Warehouse';
import Divisions from './components/Divisions/Divisions';
import Categories from './components/Categories/Categories';
import Units from './components/Units/Units';
import Sizes from './components/Sizes/Sizes';
import Branches from './components/Branches/Branches';
import Brands from './components/Brands/Brands';
import Drawer from './components/Drawer/Drawer';
import AddUser from './components/AddUser/AddUser';
import Profile from './components/Profile/Profile';
import Roles from './components/Roles/Roles';
import Department from './components/Department/Department';
import Position from './components/Position/Position';
import Thirdparty from './components/Thirdparty/ThirdParty';
import Currency from './components/Currency/Currency';
import Rate from './components/Rate/Rate';
import Project from './components/Projects/Project';
import Pos from './components/PointOfSale/Pos';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import Employees from './components/Employees/Employees';
import EmployeeSalary from './components/EmployeeSalary/EmployeeSalary';
import Invoice from './components/Invoice/Invoice';
import Purchase from './components/Purchase/Purchase';
import PaymentMethod from './components/PaymentMethod/PaymentMethod';
import ReportDesigner from './components/ReportDesigner/ReportDesigner';
import TimelineSheet from './components/TimelineSheet/TimelineSheet';
import ReportViewer from './components/ReportViewer/ReportViewer';
import Payments from './components/Payments/Payments';
import Receipt from './components/Receipt/Receipt';
import About from './components/About/About';
import Adjustment from './components/StockAdjustment/Adjustment';
import Inandout from './components/StockInAndOut/Inandout';
import ProductionOrders from './components/ProductionOrders/ProductionOrders';
import Sections from './components/Sections/Sections';
import "./dx.generic.light.css";
import Tables from './components/Tables/Tables';
import MoneyBills from './components/MoneyBills/MoneyBills';


function App() {
  const [message, setMessage] = useState({ message: "", type: "" });

  useEffect(() => {
    if (message.type === "success") {
      toast.success(message.message, {
        position: "top-right",
        style: { fontSize: "20px" },
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    else if (message.type === "error") {
      toast.error(message.message, {
        position: "top-right",
        style: { fontSize: "20px" },
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    else if (message.type === "warning") {
      toast.warning(message.message, {
        position: "top-right",
        style: { fontSize: "20px" },
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
    else if (message.type === "info") {
      toast.info(message.message, {
        position: "top-right",
        style: { fontSize: "20px" },
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  }, [message])

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login setMessage={setMessage} />} />
          <Route path="/Main" element={<Main />} />
          <Route path="/Drawer" element={<Drawer />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/ItemColor" element={<ItemColor />} />
          <Route path="/Item" element={<Item />} />
          <Route path="Warehouse" element={<Warehouse />} />
          <Route path="/Divisions" element={<Divisions />} />
          <Route path="/Categories" element={<Categories />} />
          <Route path="/Units" element={<Units />} />
          <Route path="/Sizes" element={<Sizes />} />
          <Route path="/AddUser" element={<AddUser />} />
          <Route path="/Branches" element={<Branches />} />
          <Route path="/Brands" element={<Brands />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Roles" element={<Roles />} />
          <Route path="/Department" element={<Department />} />
          <Route path="/Position" element={<Position />} />
          <Route path="/Thirdparty" element={<Thirdparty />} />
          <Route path="/Currency" element={<Currency />} />
          <Route path="/Rate" element={<Rate />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/Pos" element={<Pos />} />
          <Route path="/Employees" element={<Employees />} />
          <Route path="/EmployeeSalary" element={<EmployeeSalary />} />
          <Route path="/Invoice" element={<Invoice />} />
          <Route path="/Purchase" element={<Purchase />} />
          <Route path="/PaymentMethod" element={<PaymentMethod />} />
          <Route path="/ReportDesigner" element={<ReportDesigner />} />
          <Route path="/ReportViewer" element={<ReportViewer />} />
          <Route path="/Calendar" element={<TimelineSheet />} />
          <Route path="/Payments" element={<Payments />} />
          <Route path="/Receipt" element={<Receipt />} />
          <Route path="/About" element={<About />} />
          <Route path="/StockAdjustment" element={<Adjustment />} />
          <Route path="/StockInOut" element={<Inandout />} />
          <Route path="/ProductionOrders" element={<ProductionOrders />} />
          <Route path="/Sections" element={<Sections />} />
          <Route path="/Tables" element={<Tables />} />
          <Route path="/MoneyBills" element={<MoneyBills />} />
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
