import { Fragment, useState, useEffect } from 'react';
import { TextField, Button, Stack, InputAdornment, IconButton, RadioGroup, FormControlLabel, Radio, InputLabel, Checkbox, Autocomplete, useMediaQuery } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import './AddUser.css';
import '../../GlobalCSS.css';
import * as global from '../../utils/globals';
import { useTheme } from '@mui/material';
import swal from "sweetalert";
import { getRoles, getPosition, getDepartment } from "../../utils/helper";
import { store } from '../../store';

const AddUser = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const [showPassword, setShowPassword] = useState(false);
    const theme = useTheme();
    const [department, setDepartment] = useState([]);
    const [roles, setRole] = useState([]);
    const [position, setPosition] = useState([]);
    const minW = useMediaQuery('(min-width:600px)');
    const maxW = useMediaQuery('(max-width:900px)');

    useEffect(() => {
        setIsLoading(true);
        getRoles(globalCmpId).then((x) => setRole(x));
        getDepartment(globalCmpId).then((x) => setDepartment(x));
        getPosition(globalCmpId).then((x) => setPosition(x),
            setIsLoading(false)
        );
    }, []);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    var languages = require('language-list')();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        gender: "male",
        dateOfBirth: null,
        address: "",
        phoneNumber: "",
        role: null,
        position: null,
        workLocation: "office",
        language: [],
        department: null,
        usertype: null,
    });

    const handleRoleChange = (e, value) => {
        setFormData({
            ...formData,
            role: value
        });
    }

    const handlePositionChange = (e, value) => {
        setFormData({
            ...formData,
            position: value
        });
    }

    const handleDepartmentChange = (e, value) => {
        setFormData({
            ...formData,
            department: value
        });
    }

    const handleLanguageChange = (e, value) => {
        setFormData({
            ...formData,
            language: value
        });
    }

    const handleUserTypeChange = (e, value) => {
        setFormData({
            ...formData,
            usertype: value
        });
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const addUser = (e) => {
        e.preventDefault();

        const obj = {};
        obj.user_username = formData.email;
        obj.user_name = formData.firstName + ' ' + formData.lastName;
        obj.user_password = formData.password;
        obj.user_active = 1;
        obj.user_type = formData.usertype.id;
        obj.user_cmp_id = globalCmpId;

        const proObj = {};
        proObj.pro_firstname = formData.firstName;
        proObj.pro_lastname = formData.lastName;
        proObj.pro_cmp_id = globalCmpId;
        proObj.pro_email = formData.email;
        proObj.pro_address = formData.address;
        proObj.pro_phonenumber = formData.phoneNumber;
        proObj.pro_role = formData.role.ro_id;
        proObj.pro_position = formData.position.po_id;
        proObj.pro_work_location = formData.workLocation;
        proObj.pro_department = formData.department.dep_id;
        proObj.pro_gender = formData.gender;
        proObj.pro_color = theme.palette.primary.main;

        var strLang = "";
        for (let lan of formData.language) {
            if (lan === formData.language[formData.language.length - 1])
                strLang = strLang + lan.language;
            else strLang = strLang + lan.language + ",";
        }
        proObj.pro_language = strLang;

        const data = [];
        data.push(obj);
        data.push(proObj);

        createUser(data);
    }

    const createUser = (obj) => {
        setIsLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ obj });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(`${global.baseUrl}users/create_user`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setFormData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        password: "",
                        gender: "male",
                        dateOfBirth: null,
                        address: "",
                        phoneNumber: "",
                        role: null,
                        position: null,
                        workLocation: "office",
                        language: [],
                        department: null,
                        usertype: null,
                    })
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error, "error");
                    setIsLoading(false);
                }
            });
    };

    const shifting = (minW, maxW, openDrawer) => {

        let matches = false;

        if (minW && maxW) {
            matches = true;
        }
        if (matches && openDrawer) {
            return 'shiftORight';
        }
        if (matches && !openDrawer) {
            return 'shiftOLeft'
        }
        if (openDrawer) {
            return 'shiftRight';
        }
        return 'shiftLeft'
    }

    return (
        <Fragment>
            <div className='main'>
                <form onSubmit={addUser} className={shifting(minW, maxW, openDrawer)}>
                    <div className='outer-border'>
                        <div className='inner-border'>
                            <Stack spacing={2} direction="row" sx={{ mb: 4, mt: 4, ml: 4, mr: 4 }}>
                                <TextField
                                    type="text"
                                    name='firstName'
                                    variant='outlined'
                                    color='secondary'
                                    label="First Name"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    type="text"
                                    name='lastName'
                                    variant='outlined'
                                    color='secondary'
                                    label="Last Name"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Stack>
                            <TextField
                                type="email"
                                name='email'
                                variant='outlined'
                                color='secondary'
                                label="Email"
                                onChange={handleChange}
                                fullWidth
                                required
                                sx={{ mb: 4, pr: 8, ml: 4 }}
                            />
                            <TextField
                                type={showPassword ? "text" : "password"}
                                name='password'
                                variant='outlined'
                                color='secondary'
                                label="Password"
                                onChange={handleChange}
                                required
                                fullWidth
                                sx={{ mb: 4, pr: 8, ml: 4 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Stack spacing={2} direction="row" sx={{ mb: 4, ml: 4, mr: 4 }}>
                                <TextField
                                    type="date"
                                    name='dateOfBirth'
                                    variant='outlined'
                                    color='secondary'
                                    label="Date of Birth"
                                    onChange={handleChange}
                                    required
                                    sx={{ width: 400 }}
                                />
                                <InputLabel>Gender</InputLabel>
                                <RadioGroup
                                    row
                                    defaultValue="male"
                                    name="gender"
                                    onChange={handleChange}
                                >
                                    <FormControlLabel control={<Radio value="male" size='small' />} label="Male" />
                                    <FormControlLabel control={<Radio size='small' value='female' />} label="Female" />
                                </RadioGroup>
                            </Stack>
                            <Stack spacing={2} direction="row" sx={{ mb: 4, ml: 4, mr: 4 }}>
                                <TextField
                                    type="number"
                                    name='phoneNumber'
                                    variant='outlined'
                                    color='secondary'
                                    label="Phone Number"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    type="text"
                                    name='address'
                                    variant='outlined'
                                    color='secondary'
                                    label="Address"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Stack>
                            <Stack spacing={2} direction="row" sx={{ mb: 4, ml: 4, mr: 4 }}>
                                <Autocomplete
                                    options={roles}
                                    disableCloseOnSelect
                                    value={formData.role || null}
                                    onChange={handleRoleChange}
                                    getOptionLabel={(option) => option.ro_name || option}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.ro_name}
                                        </li>
                                    )}
                                    style={{ width: 210 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Roles" name='role' required />
                                    )}
                                />
                                <Autocomplete
                                    options={position}
                                    disableCloseOnSelect
                                    value={formData.position || null}
                                    onChange={handlePositionChange}
                                    getOptionLabel={(option) => option.po_name}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.po_name}
                                        </li>
                                    )}
                                    style={{ width: 210 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Position" name='position' required />
                                    )}
                                />
                                <Autocomplete
                                    options={department}
                                    disableCloseOnSelect
                                    value={formData.department || null}
                                    onChange={handleDepartmentChange}
                                    getOptionLabel={(option) => option.dep_name}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.dep_name}
                                        </li>
                                    )}
                                    style={{ width: 210 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Department" name='department' required />
                                    )}
                                />
                            </Stack>
                            <Stack spacing={2} direction="row" sx={{ mb: 4, ml: 4, mr: 4 }}>
                                <Autocomplete
                                    multiple
                                    options={languages.getData()}
                                    disableCloseOnSelect
                                    value={formData.language || null}
                                    onChange={handleLanguageChange}
                                    getOptionLabel={(option) => option.language}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                                required
                                            />
                                            {option.language}
                                        </li>
                                    )}
                                    style={{ width: 323 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Language" name='language' />
                                    )}
                                />
                                <Autocomplete
                                    options={uTypes}
                                    disableCloseOnSelect
                                    value={formData.usertype || null}
                                    onChange={handleUserTypeChange}
                                    getOptionLabel={(option) => option.type}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.type}
                                        </li>
                                    )}
                                    style={{ width: 323 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="User Type" name='usertype' required />
                                    )}
                                />
                            </Stack>
                            <InputLabel
                                sx={{ ml: 4 }}
                            >Work Location
                            </InputLabel>
                            <RadioGroup
                                defaultValue="office"
                                name="workLocation"
                                onChange={handleChange}
                                sx={{ pr: 8, ml: 4 }}
                            >
                                <FormControlLabel control={<Radio value="office" />} label="Office" />
                                <FormControlLabel control={<Radio value="house" />} label="House" />
                                <FormControlLabel control={<Radio value="other" />} label="Other" />
                            </RadioGroup>
                            <Button type='submit'
                                sx={{ marginTop: '16px', ml: 4, mb: 4, '&:hover': { backgroundColor: "white", color: "#3a0d63", } }}
                                variant="contained">Add User</Button>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    );
}

const uTypes = [
    { type: 'Admin', id: global.admin_id },
    { type: 'User', id: global.user_id },
]

export default AddUser;