import React, { useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import "devextreme/data/odata/store";
import { getSizes } from "../../utils/helper";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css";
import { store } from "../../store";

const Sizes = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const [sizes, setSizes] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getUpdatedSizes();
    }, [])

    const getUpdatedSizes = () => {
        getSizes(globalCmpId).then((x) => {
            setSizes(x);
            setIsLoading(false);
        });
    }

    const Sizes_rows = {
        sz_id: "",
        sz_code: "",
        sz_desc: "",
        sz_cmp_id: "",
    };

    const addSizes = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}sizes/add_item_size`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedSizes();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updSizes = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}sizes/upd_item_size`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedSizes();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delSizes = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}sizes/del_item_size`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedSizes();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedSizes = [];
            const updatedSizes = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.sz_id);
                } else if (row.type === "insert") {
                    addedSizes.push({ ...row.data, sz_cmp_id: globalCmpId });
                } else {
                    const obj = { ...row.key, ...row.data, sz_cmp_id: globalCmpId };
                    updatedSizes.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delSizes(deletedIds);
            }
            if (addedSizes.length > 0) {
                addSizes(addedSizes);
            }
            if (updatedSizes.length > 0) {
                updSizes(updatedSizes);
            }
        }
    };


    const renderColumns = () => {
        const res = [];

        for (var key in Sizes_rows) {
            if (Sizes_rows.hasOwnProperty(key)) {
                if ("sz_id" === key) {
                    res.push(
                        <Column
                            dataField="sz_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sz_code" === key) {
                    res.push(
                        <Column
                            dataField="sz_code"
                            caption="Code"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("sz_cmp_id" === key) {
                    res.push(
                        <Column
                            dataField="sz_cmp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sz_desc" === key) {
                    res.push(
                        <Column
                            dataField="sz_desc"
                            caption="Description"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    dataSource={sizes}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <FilterBuilder defaultFields={Sizes_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default Sizes;