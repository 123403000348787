import { useState } from "react";
import { ColorBox } from "devextreme-react";

export const ColorTemplateComponent = (props) => {
    const [value, setValue] = useState({ currentValue: props.data.value });

    const handleColorBoxValueChanged = (e) => {
        const newValue = e.value;
        setValue({ currentValue: newValue });
        props.data.setValue(newValue);
    };

    return (
        <div>
            <ColorBox
                value={value.currentValue}
                onValueChanged={handleColorBoxValueChanged}
            />
        </div>
    );
}