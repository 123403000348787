import React, { useRef, useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css"
import { getTables, getSections } from "../../utils/helper";
import { store } from "../../store";
import './Tables.css';
import { ColorTemplateComponent } from "../../utils/templates/ColorTemplateComponent";

const type = () => {
    return [
        { value: "Rectangle" },
        { value: "Circle" },
        { value: "Line" },
        { value: "Text" },
    ];
};

const status = () => {
    return [
        { value: "RTL" },
        { value: "Busy" },
        { value: "Free" },
    ];
};

const Tables = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalUserId = store.getState().mainReducer.usr_id;
    const [tables, setTables] = useState([]);
    const sections = useRef([]);

    useEffect(() => {
        setIsLoading(true);
        getSections(globalCmpId).then((x) => {
            x.unshift({
                sec_id: null,
                sec_name: ''
            })
            sections.current = x;
        });
        getUpdatedTables();
    }, [])

    const getUpdatedTables = () => {
        getTables(globalCmpId).then((x) => {
            setTables(x);
            setIsLoading(false);
        });
    }

    const tab_rows = {
        tab_id: "",
        tab_name: "",
        tab_sec_id: "",
        tab_user_id: "",
        tab_timestamp: "",
        tab_angle: "",
        tab_x1: "",
        tab_x2: "",
        tab_y1: "",
        tab_y2: "",
        tab_type: "",
        tab_status: "",
        tab_inv_id: "",
        tab_cmp_id: "",
        tab_color: "",
    };

    const addTables = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}tables/add_table`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedTables();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updTables = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}tables/upd_table`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedTables();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delTables = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}tables/del_table`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedTables();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedTables = [];
            const updatedTables = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.tab_id);
                } else if (row.type === "insert") {
                    addedTables.push({ ...row.data, tab_cmp_id: globalCmpId, tab_user_id: globalUserId, tab_x1: 50, tab_y1: 50, tab_x2: 1, tab_y2: 1 });
                } else {
                    const obj = { ...row.key, ...row.data, tab_cmp_id: globalCmpId, tab_user_id: globalUserId };
                    updatedTables.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delTables(deletedIds);
            }
            if (addedTables.length > 0) {
                addTables(addedTables);
            }
            if (updatedTables.length > 0) {
                updTables(updatedTables);
            }
        }
    };

    const renderColumns = () => {
        const res = [];

        for (var key in tab_rows) {
            if (tab_rows.hasOwnProperty(key)) {
                if ("tab_id" === key) {
                    res.push(
                        <Column
                            dataField="tab_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tab_name" === key) {
                    res.push(
                        <Column
                            dataField="tab_name"
                            key={key}
                            visible={true}
                            caption="Name"
                        ></Column>
                    );
                } else if ("tab_sec_id" === key) {
                    res.push(
                        <Column
                            dataField="tab_sec_id"
                            caption="Section"
                            key={key}
                            visible={true}
                        >
                            <Lookup valueExpr="sec_id" displayExpr="sec_name" dataSource={sections.current} />
                        </Column>
                    );
                } else if ("tab_user_id" === key) {
                    res.push(
                        <Column
                            dataField="tab_user_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tab_timestamp" === key) {
                    res.push(
                        <Column
                            dataField="tab_timestamp"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tab_angle" === key) {
                    res.push(
                        <Column
                            dataField="tab_angle"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tab_x1" === key) {
                    res.push(
                        <Column
                            dataField="tab_x1"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tab_x2" === key) {
                    res.push(
                        <Column
                            dataField="tab_x2"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tab_y1" === key) {
                    res.push(
                        <Column
                            dataField="tab_y1"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tab_y2" === key) {
                    res.push(
                        <Column
                            dataField="tab_y2"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tab_type" === key) {
                    res.push(
                        <Column
                            dataField="tab_type"
                            key={key}
                            visible={true}
                            caption="Type"
                        >
                            <Lookup dataSource={type} valueExpr="value" displayExpr="value" />
                        </Column>
                    );
                } else if ("tab_status" === key) {
                    res.push(
                        <Column
                            dataField="tab_status"
                            key={key}
                            visible={true}
                            caption="Status"
                        >
                            <Lookup dataSource={status} valueExpr="value" displayExpr="value" />
                        </Column>
                    );
                } else if ("tab_inv_id" === key) {
                    res.push(
                        <Column
                            dataField="tab_inv_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tab_cmp_id" === key) {
                    res.push(
                        <Column
                            dataField="tab_cmp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tab_color" === key) {
                    res.push(
                        <Column
                            dataField="tab_color"
                            key={key}
                            visible={true}
                            caption="Color"
                            editCellComponent={ColorTemplateComponent}
                            width={340}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    dataSource={tables}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <FilterBuilder defaultFields={tab_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default Tables;