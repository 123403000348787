import React, { useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css"
import { getSections } from "../../utils/helper";
import { store } from "../../store";
import './Sections.css'

const Sections = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const [sections, setSections] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getUpdatedSections();
    }, [])

    const getUpdatedSections = () => {
        getSections(globalCmpId).then((x) => {
            setSections(x);
            setIsLoading(false);
        });
    }

    const sec_rows = {
        sec_id: "",
        sec_name: "",
        sec_order: "",
        sec_cmp_id: "",
    };

    const addSections = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}sections/add_section`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedSections();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updSections = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}sections/upd_section`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedSections();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delSections = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}sections/del_section`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedSections();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedSections = [];
            const updatedSections = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.sec_id);
                } else if (row.type === "insert") {
                    addedSections.push({ ...row.data, sec_cmp_id: globalCmpId });
                } else {
                    const obj = { ...row.key, ...row.data, sec_cmp_id: globalCmpId };
                    updatedSections.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delSections(deletedIds);
            }
            if (addedSections.length > 0) {
                addSections(addedSections);
            }
            if (updatedSections.length > 0) {
                updSections(updatedSections);
            }
        }
    };


    const renderColumns = () => {
        const res = [];

        for (var key in sec_rows) {
            if (sec_rows.hasOwnProperty(key)) {
                if ("sec_id" === key) {
                    res.push(
                        <Column
                            dataField="sec_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sec_name" === key) {
                    res.push(
                        <Column
                            dataField="sec_name"
                            key={key}
                            visible={true}
                            caption="Name"
                        ></Column>
                    );
                } else if ("sec_order" === key) {
                    res.push(
                        <Column
                            dataField="sec_order"
                            caption="Order"
                            key={key}
                            visible={true}
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("sec_cmp_id" === key) {
                    res.push(
                        <Column
                            dataField="sec_cmp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    dataSource={sections}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <FilterBuilder defaultFields={sec_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default Sections;