export const baseUrl = "http://localhost:8080/main/";
export const admin_id = "D4329860-A865-4F42-954B-F455042CF16D";
export const user_id = "52A24FDE-4B1E-40BF-85D5-E4E09136F0A7";
export const owner_id = "123DB5DD-4E99-478B-A177-9F9D2D717F51";

export const thirdPartyType = [
    " ",
    "Client/Supplier",
    "Client",
    "Supplier",
    "Bank",
    "Employee",
    "Expense"
];
