import React, { useRef, useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css"
import "./Categories.css"
import { getCategories } from "../../utils/helper";
import { store } from "../../store";
import { Sortable, TreeView } from "devextreme-react";

const Categories = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const [categories, setCategories] = useState([]);
    const [treeItems, setTreeItems] = useState([]);
    const [selectedNode, setSelectedNode] = useState();
    const [childrenNodes, setChildrenNodes] = useState([]);
    const treeView = useRef();

    useEffect(() => {
        setIsLoading(true);
        getUpdatedCat();
    }, [])

    const getUpdatedCat = () => {
        getCategories(globalCmpId).then((x) => {

            const newItems = [];
            newItems.push({
                cat_id: "All",
                cat_parent: null,
                cat_name: "All"
            });

            for (let item of x) {
                newItems.push(
                    {
                        cat_id: item.cat_id,
                        cat_name: item.cat_name,
                        cat_cmp_id: item.cat_cmp_id,
                        cat_parent: !item.cat_parent || item.cat_parent === null ? null : item.cat_parent,
                        isDirectory: true,
                        expanded: true
                    }
                );
            }
            setTreeItems(newItems);
            setCategories(x);
            setIsLoading(false);
        });
    }

    const selectMainNode = () => {
        if (treeView.current) {
            const nodes = treeView.current.instance.getNodes();
            if (nodes.length > 0 && nodes[1]) {
                const children = [];
                getNodesChildren(nodes[1], children);
                setChildrenNodes(children);

                setSelectedNode(nodes[1].itemData);
            }
        }
    }

    const filterValues = () => {
        const filterValues = [];
        if (selectedNode)
            filterValues.push(selectedNode.cat_name);

        for (let node of childrenNodes) {
            filterValues.push(node.itemData.cat_name);
        }
        return filterValues.length === 0 ? "" : filterValues;
    }

    const onDragChange = (e) => {
        if (e.fromComponent === e.toComponent) {
            const fromNode = findNode(treeView.current.instance, e.fromIndex);
            const toNode = findNode(treeView.current.instance, calculateToIndex(e));
            if (toNode !== null && isChildNode(fromNode, toNode)) {
                e.cancel = true;
            }
        }
    }

    const onDragEnd = (e) => {
        if (e.fromComponent === e.toComponent && e.fromIndex === e.toIndex) {
            return;
        }

        const fromTreeView = treeView.current.instance;
        const toTreeView = treeView.current.instance;

        const fromNode = findNode(fromTreeView, e.fromIndex);
        const toNode = findNode(toTreeView, calculateToIndex(e));

        if (e.dropInsideItem && toNode !== null && !toNode.itemData.isDirectory) {
            return;
        }

        const fromTopVisibleNode = getTopVisibleNode(e.fromComponent);
        const toTopVisibleNode = getTopVisibleNode(e.toComponent);

        const fromItems = treeItems;
        const toItems = treeItems;
        moveNode(fromNode, toNode, fromItems, toItems, e.dropInsideItem);

        setTreeItems([...toItems]);
        fromTreeView.scrollToItem(fromTopVisibleNode);
        toTreeView.scrollToItem(toTopVisibleNode);
        updateDataGrid([...toItems]);
    }

    const updateDataGrid = (newData) => {
        const params = [];
        for (let item of newData) {
            if (item.cat_id === "All") continue;
            params.push({
                cat_id: item.cat_id,
                cat_cmp_id: globalCmpId,
                cat_name: item.cat_name,
                cat_parent: item.cat_parent,
            });
        }
        updCategories(params);
    }

    const moveNode = (fromNode, toNode, fromItems, toItems, isDropInsideItem) => {
        const fromIndex = fromItems.findIndex((item) => item.cat_id === fromNode.itemData.cat_id);
        fromItems.splice(fromIndex, 1);

        const toIndex = toNode === null || isDropInsideItem
            ? toItems.length
            : toItems.findIndex((item) => item.cat_id === toNode.itemData.cat_id);
        toItems.splice(toIndex, 0, fromNode.itemData);

        moveChildren(fromNode, fromItems, toItems);
        if (isDropInsideItem) {
            fromNode.itemData.cat_parent = toNode.itemData.cat_id;
        } else {
            fromNode.itemData.cat_parent = toNode != null
                ? toNode.itemData.cat_parent
                : undefined;
        }
    }

    const moveChildren = (node, fromDataSource, toDataSource) => {
        if (!node.itemData.isDirectory) {
            return;
        }

        node.children.forEach((child) => {
            if (child.itemData.isDirectory) {
                moveChildren(child, fromDataSource, toDataSource);
            }

            const fromIndex = fromDataSource.findIndex((item) => item.cat_id === child.itemData.cat_id);
            fromDataSource.splice(fromIndex, 1);
            toDataSource.splice(toDataSource.length, 0, child.itemData);
        });
    }

    const calculateToIndex = (e) => {
        if (e.fromComponent !== e.toComponent || e.dropInsideItem) {
            return e.toIndex;
        }

        return e.fromIndex >= e.toIndex
            ? e.toIndex
            : e.toIndex + 1;
    }

    const findNode = (treeView, index) => {
        const nodeElement = treeView.element().querySelectorAll('.dx-treeview-node')[index];
        if (nodeElement) {
            return findNodeById(treeView.getNodes(), nodeElement.getAttribute('data-item-id'));
        }
        return null;
    }

    const findNodeById = (nodes, id) => {
        for (let i = 0; i < nodes.length; i += 1) {
            if (nodes[i].itemData.cat_id === id) {
                return nodes[i];
            }
            if (nodes[i].children) {
                const node = findNodeById(nodes[i].children, id);
                if (node != null) {
                    return node;
                }
            }
        }
        return null;
    }

    const isChildNode = (parentNode, childNode) => {
        let { parent } = childNode;
        while (parent !== null) {
            if (parent.itemData.cat_id === parentNode.itemData.cat_id) {
                return true;
            }
            parent = parent.parent;
        }
        return false;
    }

    const getTopVisibleNode = (component) => {
        const treeViewElement = component.element();
        const treeViewTopPosition = treeViewElement.getBoundingClientRect().top;
        const nodes = treeViewElement.querySelectorAll('.dx-treeview-node');
        for (let i = 0; i < nodes.length; i += 1) {
            const nodeTopPosition = nodes[i].getBoundingClientRect().top;
            if (nodeTopPosition >= treeViewTopPosition) {
                return nodes[i];
            }
        }

        return null;
    }

    const selectItem = (e) => {
        if (e.itemData.cat_id === "All") {
            setSelectedNode(null);
            setChildrenNodes([]);
        } else {
            const children = [];
            getNodesChildren(e.node, children);
            setChildrenNodes(children);

            var newData = { ...e.itemData };
            setSelectedNode(newData);
        }
    }

    const getNodesChildren = (parent, childrenArray) => {
        if (parent.children.length > 0) {
            for (let child of parent.children) {
                childrenArray.push(child);
                getNodesChildren(child, childrenArray);
            }
        }
        else return;
    }

    const Categories_rows = {
        cat_id: "",
        cat_name: "",
        cat_cmp_id: "",
        cat_parent: "",
    };

    const addCategories = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}categories/add_category`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedCat();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updCategories = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}categories/upd_category`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedCat();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delCategories = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}categories/del_category`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedCat();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedCategories = [];
            const updatedCategories = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.cat_id);
                } else if (row.type === "insert") {
                    addedCategories.push({ ...row.data, cat_cmp_id: globalCmpId });
                } else {
                    const obj = { ...row.key, ...row.data, cat_cmp_id: globalCmpId };
                    updatedCategories.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delCategories(deletedIds);
            }
            if (addedCategories.length > 0) {
                addCategories(addedCategories);
            }
            if (updatedCategories.length > 0) {
                updCategories(updatedCategories);
            }
        }
    };


    const renderColumns = () => {
        const res = [];

        for (var key in Categories_rows) {
            if (Categories_rows.hasOwnProperty(key)) {
                if ("cat_id" === key) {
                    res.push(
                        <Column
                            dataField="cat_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("cat_name" === key) {
                    res.push(
                        <Column
                            dataField="cat_name"
                            caption="Name"
                            key={key}
                            visible={true}
                            filterValues={filterValues()}
                        ></Column>
                    );
                } else if ("cat_cmp_id" === key) {
                    res.push(
                        <Column
                            dataField="cat_cmp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("cat_parent" === key) {
                    res.push(
                        <Column
                            dataField="cat_parent"
                            key={key}
                            visible={true}
                            caption="Parent"
                        >
                            <Lookup dataSource={categories} displayExpr="cat_name" valueExpr="cat_id" />
                        </Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <div style={{ display: "flex" }}>
                    <div style={{ background: "white", marginRight: "10px", width: "10%" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>
                        <Sortable
                            filter=".dx-treeview-item"
                            group="shared"
                            data="tree"
                            allowDropInsideItem={true}
                            allowReordering={true}
                            onDragChange={onDragChange}
                            onDragEnd={onDragEnd}
                        >
                            <TreeView
                                dataStructure="plain"
                                displayExpr="cat_name"
                                parentIdExpr="cat_parent"
                                keyExpr="cat_id"
                                items={treeItems}
                                ref={treeView}
                                id="treeview"
                                searchEnabled={true}
                                searchMode="startswith"
                                selectByClick={true}
                                onItemClick={selectItem}
                                onContentReady={selectMainNode}
                                expandNodesRecursive={false}
                            />
                        </Sortable>
                    </div>
                    <DataGrid
                        style={{ width: "80%" }}
                        dataSource={categories}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        remoteOperations={false}
                        columnAutoWidth={true}
                        onSaving={onSaving}
                        allowColumnResizing={true}
                        columnMinWidth={70}
                        columnResizingMode={"widget"}
                    >
                        <Popup showTitle={true} title="Row in the editing state" />
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 50, 100, 500]}
                            showInfo={true}
                            infoText="Page {0}. Total: {1} ({2} Items)"
                        />
                        <FilterBuilder defaultFields={Categories_rows} />
                        <FilterRow visible={true} />
                        <Sorting visible={true} />
                        <GroupPanel visible={true} />
                        <SearchPanel visible={true} />
                        <Editing
                            allowAdding={true}
                            allowUpdating={true}
                            allowDeleting={true}
                            confirmDelete={true}
                            mode="batch"
                            selectTextOnEditStart={true}
                            useIcons={true}
                        />
                        <ColumnChooser enabled={false} />
                        <Scrolling columnRenderingMode="virtual" />
                        <KeyboardNavigation
                            editOnKeyPress={true}
                            enterKeyAction="moveFocus"
                            enterKeyDirection="column"
                        />
                        {renderColumns()}
                    </DataGrid>
                </div>
            </div>
        );
    };

    return <div className="table-container">
        {renderDataGrid()}
    </div>;
}

export default Categories;