import React, { useRef, useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
    Toolbar,
    Item,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css"
import {
    getPaymentItems, getPayments, getCurrencies, getPaymentMethods,
    getRatesToAndFrom, getBranches, getDivisions, getProjects,
    getThirdpartiesType, getCompany, convertDateOnly
} from "../../utils/helper";
import { Button, DateBox, SelectBox, TextArea, TextBox } from "devextreme-react";
import { Dialog, DialogTitle, InputLabel } from "@mui/material";
import "./Payments.css"
import { store } from "../../store";
import ClearIcon from "@mui/icons-material/Clear";
import Slide from "@mui/material/Slide";
import { useReactToPrint } from "react-to-print";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const exportsSettings = {
    pdf: {
        title: "Invoice",
        author: "GrapeCity",
        subject: "Web Reporting",
        keywords: "reporting, sample",
        userPassword: "pwd",
        ownerPassword: "ownerPwd",
        printing: "none",
        copying: false,
        modifying: false,
        annotating: false,
        contentAccessibility: false,
        documentAssembly: false,
        pdfVersion: "1.7",
        autoPrint: true,
        filename: "Invoice.pdf",
    },
    html: {
        title: "Invoice",
        filename: "Invoice.html",
        autoPrint: true,
        multiPage: true,
        embedImages: "external",
        outputType: "html",
    },
};

// remove items from this array to hide unnecessary Exports Types
const availableExports = ["pdf", "html", "tabular-data"];

const Payments = ({ openDrawer, data, setData, setIsLoading }) => {

    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalUsrId = store.getState().mainReducer.usr_id;
    const globalTransTypes = store.getState().mainReducer.transtypes;
    const paymentsTransTypes = globalTransTypes.filter((tt) => tt.tt_type === 'Payment Voucher' || tt.tt_type === 'Credit Note Supplier' || tt.tt_type === 'Debit Note Supplier');

    const [payments, setPayments] = useState([]);
    const [paymentsItems, setPaymentsItems] = useState([]);
    const [date, setDate] = useState(new Date());
    const [trancactionType, setTransactionType] = useState("");
    const [id, setId] = useState(null);
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [referenceNbr, setReferenceNbr] = useState("");
    const [note, setNote] = useState("");
    const [thirdParty, setThirdParty] = useState(null);
    const [thirdPartyEmp, setThirdPartyEmp] = useState(null);
    const [valueDate, setValueDate] = useState(null);
    const [docUrl, setDocUrl] = useState("");
    const [ratesToAndFrom, setRatesToAndFrom] = useState([]);
    const [viewerPopup, setViewerPopup] = useState(false);
    const [report, setReport] = useState();
    const [company, setCompany] = useState([]);
    const [firstCurr, setFirstCurr] = useState();
    const [secondCurr, setSecondCurr] = useState();
    const [tempPay, setTempPay] = useState([]);
    const paymentMethod = useRef([]);
    const thirdParties = useRef([]);
    const thirdPartiesEmp = useRef([]);
    const division = useRef([]);
    const branches = useRef([]);
    const projects = useRef([]);
    const currencies = useRef([]);
    const datagridRef = useRef(null);
    const currency = useRef([]);

    useEffect(() => {
        setIsLoading(true);
        getUpdatedPaymentItems();
        getCompany(globalCmpId).then((x) => setCompany(x));
        getCurrencies(globalCmpId).then((x) => {
            x.unshift({
                cur_id: null,
                cur_code: ""
            })
            currency.current = x;
        });
        getPaymentMethods(globalCmpId).then((x) => {
            x.unshift({
                pmt_id: null,
                pmt_name: "",
                pmt_cur_id: null,
            })
            paymentMethod.current = x;
        });
        getBranches(globalCmpId).then((x) => {
            x.unshift({
                bra_id: null,
                bra_name: ''
            })
            branches.current = x;
        });
        getProjects(globalCmpId).then((x) => {
            x.unshift({
                prj_id: null,
                prj_name: ''
            })
            projects.current = x;
        });
        getDivisions(globalCmpId).then((x) => {
            x.unshift({
                div_id: null,
                div_name: ''
            })
            division.current = x;
        });
        getThirdpartiesType("Employee", globalCmpId).then((x) => {
            x.unshift({
                tp_id: null,
                tp_name: ''
            })
            thirdPartiesEmp.current = x;
        });
        getThirdpartiesType("Supplier", globalCmpId).then((x) => {
            x.unshift({
                tp_id: null,
                tp_name: '',
                tp_type: ""
            })
            thirdParties.current = x;
        });
        getCurrencies(globalCmpId).then((x) => {
            currencies.current = x;
            if (x.length > 0) {
                let cur1, cur2;
                for (let cur of x) {
                    if (cur.cur_priority === 1) {
                        cur1 = cur;
                        setFirstCurr(cur);
                    };
                    if (cur.cur_priority === 2) {
                        cur2 = cur;
                        setSecondCurr(cur);
                    }
                }
                getRatesToAndFrom(cur1.cur_id, cur2.cur_id).then((x) => setRatesToAndFrom(x));
            }
            setIsLoading(false);
        });
        return () => setData({});
    }, [])

    const getUpdatedPaymentItems = () => {
        getPayments(globalCmpId).then((x) => {
            setPayments(x);
            setIsLoading(false);
            setTempPay(x);
        });
    }

    useEffect(() => {
        if (data !== null && data !== undefined && data !== "" && data.toPage === "Payment") {
            setDate(data.pay_date);
            setTransactionType(data.pay_tt_code);
            setStatus(data.pay_status);
            setDescription(data.pay_description);
            setReferenceNbr(data.pay_referencenb);
            setNote(data.pay_note);
            setThirdParty(data.pay_tp_id);
            setValueDate(data.pay_valuedate);
            setThirdPartyEmp(data.pay_employee);
            setDocUrl(data.pay_docurl);
            setId(data.pay_id);
        }
    }, []);

    const getID = (e) => {
        setIsLoading(true);
        if (e.value !== null && e.value !== undefined && e.value !== "") {
            getPaymentItems(e.value).then((x) => {

                for (let pay of payments) {
                    if (e.value === pay.pay_id) {
                        setDate(pay.pay_date);
                        setTransactionType(pay.pay_tt_code);
                        setStatus(pay.pay_status);
                        setDescription(pay.pay_description);
                        setReferenceNbr(pay.pay_referencenb);
                        setNote(pay.pay_note);
                        setThirdParty(pay.pay_tp_id);
                        setValueDate(pay.pay_valuedate);
                        setThirdPartyEmp(pay.pay_employee);
                        setDocUrl(pay.pay_docurl);
                        setTrxReport(pay, x);
                        break;
                    }
                }
                setPaymentsItems(x)
            }, setIsLoading(false));
        }
        else {
            setPaymentsItems([]);
            setIsLoading(false);
        }
        setId(e.value);
    }

    const setTP = (e) => {
        if (e.value === null) {
            setTempPay(payments);
            onReset();
        } else if (e.value !== null && thirdParty === null) {
            setThirdParty(e.value);
            const pvId = paymentsTransTypes.find((tt) => tt.tt_type === 'Payment Voucher')?.tt_code;
            setTransactionType(pvId);

            const newPay = [];
            for (let trxnb of payments) {
                if (trxnb.pay_tp_id === e.value) {
                    newPay.push(trxnb);
                }
            }
            setTempPay(newPay);
        } else if (e.value !== null && thirdParty !== null) {
            onReset();
            setThirdParty(e.value);
            const pvId = paymentsTransTypes.find((tt) => tt.tt_type === 'Payment Voucher')?.tt_code;
            setTransactionType(pvId);

            const newPay = [];
            for (let trxnb of payments) {
                if (trxnb.pay_tp_id === e.value) {
                    newPay.push(trxnb);
                }
            }
            setTempPay(newPay);
        }
    }

    const displayTypeTrxnb = (option) => {
        if (option !== null) {
            for (let tt of globalTransTypes) {
                if (tt.tt_code === option.pay_tt_code) {
                    return tt.tt_newcode + "-" + option.pay_trxnb;
                }
            }
        }
        return "";
    }

    const pait_item_row = {
        pait_pay_id: "",
        pait_pmt_id: "",
        pait_cur_id: "",
        pait_amount: "",
        pait_amountf: "",
        pait_amounts: "",
        pait_bank: "",
        pait_cheque: "",
        pait_date: "",
        pait_bra_id: "",
        pait_prj_id: "",
        pait_note: "",
        pait_user_id: "",
        pait_div_id: "",
        pait_tax: "",
        pait_denom: "",
        pait_count: "",
    }

    const onReset = () => {
        setPaymentsItems([]);
        setDate(new Date());
        setTransactionType(null);
        setStatus("");
        setDescription("");
        setReferenceNbr("");
        setNote("");
        setThirdParty(null);
        setValueDate(null);
        setThirdPartyEmp(null);
        setDocUrl("");
        setId(null);
        datagridRef.current.instance.cancelEditData();
    }

    const printPriview = () => {
        if (id !== null) {
            setViewerPopup(true);
        } else {
            swal("Info", "Please select a Payment Number to print.", "info");
            return;
        }
    }

    const setTrxReport = (item, items) => {
        const dispReceipts = JSON.parse(JSON.stringify(items));

        const rows = dispReceipts.map((it, i) => {
            const cur = currency.current.find((cur) => cur.cur_id === it.pait_cur_id)?.cur_code;
            const payment = paymentMethod.current.find((ra) => ra.pmt_id === it.pait_pmt_id)?.pmt_name;
            return (
                `<tr>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ payment + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ cur + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ convertDateOnly(it.pait_date) + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ it.pait_note + `</td>
              <td style="border-top-color: black; border-top-style: solid;">`+ it.pait_amount + `</td>
            </tr>`
            )
        })

        const table = `
            <tr class="payment-items-table-header">
                <th style="border-right-color: black; border-right-style: solid;">Payment Method</th>
                <th style="border-right-color: black; border-right-style: solid;">Currency</th>
                <th style="border-right-color: black; border-right-style: solid;">Date</th>
                <th style="border-right-color: black; border-right-style: solid;">Note</th>
                <th>Amount</th>
            </tr>
            ${rows}
        `;

        fetch("/assets/reports/payment.html").then(result => {
            return result.text();
        }).then(page => {
            var mapObj = {
                companyaddressvalue: company[0]?.cmp_address === null || company[0]?.cmp_address === "" ? " " : company[0]?.cmp_address,
                companylogovalue: "/dataharbor.png",
                companyphonevalue: company[0]?.cmp_phonenumber === null || company[0]?.cmp_phonenumber === "" ? " " : company[0]?.cmp_phonenumber,
                paymentOderNumberData: payments[0]?.pay_trxnb,
                paymentDateData: convertDateOnly(payments[0]?.pay_date),
                employeeNameData: thirdPartiesEmp.current.find((ep) => ep.tp_id === payments[0]?.pay_employee)?.tp_name,
                referenceNumberData: payments[0]?.pay_referencenb,
                supplierNameData: thirdParties.current.find((sp) => sp.tp_id === payments[0]?.pay_tp_id)?.tp_name,
                supplierCodeData: thirdParties.current.find((sp) => sp.tp_id === payments[0]?.pay_tp_id)?.tp_type,
                descriptionTextData: payments[0]?.pay_description,
                statusTypeData: payments[0]?.pay_status,
                tablepurchasevalue: table,
            };
            const newPage = page.replace(/statusTypeData|descriptionTextData|supplierCodeData|supplierNameData|referenceNumberData|employeeNameData|paymentDateData|paymentOderNumberData|tablepurchasevalue|companyaddressvalue|companyphonevalue|companylogovalue/gi,
                (matched) => mapObj[matched]);
            setReport(newPage);
        });
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onEditiorPreparing = (e) => {

        if (e.dataField === "pait_pmt_id" && e.parentType === "dataRow") {
            e.editorOptions.min = null;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value === null) {
                    swal("Error", "Please put a Payment Method", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                for (let p of paymentMethod.current) {
                    if (p.pmt_id === args.value) {
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "pait_cur_id", p.pmt_cur_id)
                        break;
                    }
                }

            }
        }

        if (e.dataField === "pait_amount" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value must be greater than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                if (datagridRef.current.instance.cellValue(e.row.rowIndex, "pait_cur_id") === firstCurr.cur_id) {
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "pait_amountf", args.value);
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "pait_amounts", args.value * ratesToAndFrom[0]?.rate_rate);
                } else if (datagridRef.current.instance.cellValue(e.row.rowIndex, "pait_cur_id") === secondCurr.cur_id) {
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "pait_amountf", args.value / ratesToAndFrom[0]?.rate_rate);
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "pait_amounts", args.value);
                }
            }
        }

        if (e.dataField === "pait_amountf" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value must be greater than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                datagridRef.current.instance.cellValue(e.row.rowIndex, "pait_amount", args.value);
                datagridRef.current.instance.cellValue(e.row.rowIndex, "pait_amounts", args.value * ratesToAndFrom[0]?.rate_rate);

            }
        }

        if (e.dataField === "pait_amounts" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value must be greater than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                datagridRef.current.instance.cellValue(e.row.rowIndex, "pait_amount", args.value / ratesToAndFrom[0]?.rate_rate);
                datagridRef.current.instance.cellValue(e.row.rowIndex, "pait_amountf", args.value / ratesToAndFrom[0]?.rate_rate);
            }
        }
    }

    const deletePayment = (e) => {
        if (id !== null) {
            const data = [];
            data.push(id);
            delPayment(data);
        } else {
            swal("Info", "Please select a Payment Number to delete.", "info");
            return;
        }
    }

    const roundNumber = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    const savePayment = (e) => {
        if (thirdParty === null || thirdParty === undefined || thirdParty === "") {
            swal("Info", "Please select a Supplier.", "info")
            return;
        }

        let rows = datagridRef.current.instance.getVisibleRows();
        let pmtid = datagridRef.current.instance.cellValue(0, "pait_pmt_id");

        if (rows.length === 0) {
            swal("Error", "Please add at least 1 item!", "error")
            return;
        }

        if (pmtid === undefined) {
            swal("Error", "Please add at least 1 item!", "error")
            return;
        }

        if (id === null || id === undefined || id === "") {
            const params = {};

            params.pay_cmp_id = globalCmpId;
            params.pay_date = date;
            params.pay_tt_code = trancactionType;
            params.pay_trxnb = null;
            params.pay_status = status;
            params.pay_description = description;
            params.pay_referencenb = referenceNbr;
            params.pay_note = note;
            params.pay_tp_id = thirdParty;
            params.pay_user_id = globalUsrId;
            params.pay_valuedate = valueDate;
            params.pay_employee = thirdPartyEmp;
            params.pay_docurl = docUrl;

            const data = [];
            data.push(params);

            const iparams = [];
            for (let i of rows) {
                const param = {};
                param.pait_cur_id = i.data.pait_cur_id;
                param.pait_pmt_id = i.data.pait_pmt_id;
                param.pait_amount = i.data.pait_amount;
                param.pait_amountf = i.data.pait_amountf;
                param.pait_amounts = i.data.pait_amounts;
                param.pait_bank = i.data.pait_bank;
                param.pait_cheque = i.data.pait_cheque;
                param.pait_date = i.data.pait_date;
                param.pait_bra_id = i.data.pait_bra_id;
                param.pait_prj_id = i.data.pait_prj_id;
                param.pait_note = i.data.pait_note;
                param.pait_user_id = i.data.pait_user_id;
                param.pait_div_id = i.data.pait_div_id;
                param.pait_tax = i.data.pait_tax;
                param.pait_denom = i.data.pait_denom;
                param.pait_count = i.data.pait_count;
                iparams.push(param);
            }
            addPayment(data, iparams);
        } else {

            const params = {};

            params.pay_id = id;
            params.pay_cmp_id = globalCmpId;
            params.pay_date = date;
            params.pay_tt_code = trancactionType;
            params.pay_trxnb = payments.filter((option) => option.pay_id === id)[0].pay_trxnb;;
            params.pay_status = status;
            params.pay_description = description;
            params.pay_referencenb = referenceNbr;
            params.pay_note = note;
            params.pay_tp_id = thirdParty;
            params.pay_user_id = globalUsrId;
            params.pay_valuedate = valueDate;
            params.pay_employee = thirdPartyEmp;
            params.pay_docurl = docUrl;

            const data = [];
            data.push(params);
            updPayment(data);

            let rows = datagridRef.current.instance.getVisibleRows();
            const updparams = [];
            const delparams = [];
            const addparams = [];

            for (let row of rows) {
                if (row.removed) {
                    delparams.push(row.data.pait_id);
                    continue;
                }
                if (row.data.pait_pay_id) {
                    const param = {};
                    param.pait_id = row.data.pait_id;
                    param.pait_pay_id = row.data.pait_pay_id;
                    param.pait_pmt_id = row.data.pait_pmt_id;
                    param.pait_cur_id = row.data.pait_cur_id;
                    param.pait_amount = row.data.pait_amount;
                    param.pait_amountf = row.data.pait_amountf;
                    param.pait_amounts = row.data.pait_amounts;
                    param.pait_bank = row.data.pait_bank;
                    param.pait_cheque = row.data.pait_cheque;
                    param.pait_date = row.data.pait_date;
                    param.pait_bra_id = row.data.pait_bra_id;
                    param.pait_prj_id = row.data.pait_prj_id;
                    param.pait_note = row.data.pait_note;
                    param.pait_user_id = row.data.pait_user_id;
                    param.pait_div_id = row.data.pait_div_id;
                    param.pait_tax = row.data.pait_tax;
                    param.pait_denom = row.data.pait_denom;
                    param.pait_count = row.data.pait_count;
                    updparams.push(param);
                } else {
                    const param = {};
                    param.pait_cur_id = row.data.pait_cur_id;
                    param.pait_pmt_id = row.data.pait_pmt_id;
                    param.pait_amount = row.data.pait_amount;
                    param.pait_amountf = row.data.pait_amountf;
                    param.pait_amounts = row.data.pait_amounts;
                    param.pait_bank = row.data.pait_bank;
                    param.pait_cheque = row.data.pait_cheque;
                    param.pait_date = row.data.pait_date;
                    param.pait_bra_id = row.data.pait_bra_id;
                    param.pait_prj_id = row.data.pait_prj_id;
                    param.pait_note = row.data.pait_note;
                    param.pait_user_id = row.data.pait_user_id;
                    param.pait_div_id = row.data.pait_div_id;
                    param.pait_tax = row.data.pait_tax;
                    param.pait_denom = row.data.pait_denom;
                    param.pait_count = row.data.pait_count;
                    addparams.push(param);
                }
            };

            if (delparams.length > 0) {
                delPaymentItem(delparams);
            }
            if (addparams.length > 0) {
                addPaymentItem(addparams, id);
            }
            if (updparams.length > 0) {
                updPaymentItem(updparams);
            }
        }
    }

    const addPayment = (params, iparams) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}payments/add_payment`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    addPaymentItem(iparams, response.pay_id[0][0].pay_id);
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                getUpdatedPaymentItems();
            });
    }

    const addPaymentItem = (params, id) => {
        for (let it of params)
            it.pait_pay_id = id;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}paymentitems/add_paymentitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).catch((err) => console.log(err));
    }

    const updPayment = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}payments/upd_payment`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                getUpdatedPaymentItems();
            });
    };

    const updPaymentItem = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}paymentitems/upd_paymentitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    }

    const delPayment = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}payments/del_payment`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                getUpdatedPaymentItems();
            });
    };

    const delPaymentItem = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}paymentitems/del_paymentitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const formatOptions = {
        type: "fixedPoint",
        precision: 2
    };

    const formatInt = {
        type: "fixedPoint",
        precision: 0
    };

    const renderColumns = () => {
        const res = [];

        for (var key in pait_item_row) {
            if (pait_item_row.hasOwnProperty(key)) {
                if ("pait_pay_id" === key) {
                    res.push(
                        <Column
                            dataField="pait_pay_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("pait_pmt_id" === key) {
                    res.push(
                        <Column
                            dataField="pait_pmt_id"
                            key={key}
                            visible={true}
                            caption="Payment Method"
                        >
                            <Lookup dataSource={paymentMethod.current} displayExpr="pmt_name" valueExpr="pmt_id" />
                        </Column>
                    );
                } else if ("pait_cur_id" === key) {
                    res.push(
                        <Column
                            dataField="pait_cur_id"
                            key={key}
                            caption="Currency"
                            visible={true}
                        >
                            <Lookup dataSource={currency.current} displayExpr="cur_code" valueExpr="cur_id" />
                        </Column>
                    );
                } else if ("pait_amount" === key) {
                    res.push(
                        <Column
                            dataField="pait_amount"
                            key={key}
                            visible={true}
                            caption="Amount"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("pait_amountf" === key) {
                    res.push(
                        <Column
                            dataField="pait_amountf"
                            key={key}
                            caption="Amount F"
                            visible={true}
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("pait_amounts" === key) {
                    res.push(
                        <Column
                            dataField="pait_amounts"
                            key={key}
                            visible={true}
                            caption="Amount S"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("pait_bank" === key) {
                    res.push(
                        <Column
                            dataField="pait_bank"
                            key={key}
                            visible={true}
                            caption="Bank"
                            dataType="string"
                        ></Column>
                    );
                } else if ("pait_cheque" === key) {
                    res.push(
                        <Column
                            dataField="pait_cheque"
                            key={key}
                            visible={true}
                            caption="Cheque"
                            dataType="string"
                        ></Column>
                    );
                } else if ("pait_date" === key) {
                    res.push(
                        <Column
                            dataField="pait_date"
                            key={key}
                            visible={true}
                            caption="Date"
                            dataType="date"
                        ></Column>
                    );
                } else if ("pait_bra_id" === key) {
                    res.push(
                        <Column
                            dataField="pait_bra_id"
                            key={key}
                            visible={true}
                            caption="Branch"
                        >
                            <Lookup displayExpr="bra_name" valueExpr="bra_id" dataSource={branches.current} />
                        </Column>
                    );
                } else if ("pait_prj_id" === key) {
                    res.push(
                        <Column
                            dataField="pait_prj_id"
                            key={key}
                            visible={true}
                            caption="Project"
                        >
                            <Lookup displayExpr="prj_name" valueExpr="prj_id" dataSource={projects.current} />
                        </Column>
                    );
                } else if ("pait_note" === key) {
                    res.push(
                        <Column
                            dataField="pait_note"
                            key={key}
                            visible={true}
                            caption="Note"
                            dataType="string"
                        ></Column>
                    );
                } else if ("pait_user_id" === key) {
                    res.push(
                        <Column
                            dataField="pait_user_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("pait_div_id" === key) {
                    res.push(
                        <Column
                            dataField="pait_div_id"
                            key={key}
                            visible={true}
                            caption="Division"
                        >
                            <Lookup dataSource={division.current} valueExpr="div_id" displayExpr="div_name" />
                        </Column>
                    );
                } else if ("pait_tax" === key) {
                    res.push(
                        <Column
                            dataField="pait_tax"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("pait_denom" === key) {
                    res.push(
                        <Column
                            dataField="pait_denom"
                            key={key}
                            visible={true}
                            caption="Denomination"
                            dataType="number"
                            alignment="left"
                            format={formatInt}
                        ></Column>
                    );
                } else if ("pait_count" === key) {
                    res.push(
                        <Column
                            dataField="pait_count"
                            key={key}
                            visible={true}
                            caption="Count"
                            dataType="number"
                            alignment="left"
                            format={formatInt}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <div className="groups">
                    <div className="groups-btns">
                        <Button
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                            text="Save"
                            width={100}
                            onClick={savePayment}
                        />
                        <Button
                            text="Reset"
                            width={100}
                            onClick={onReset}
                        />
                        <Button
                            text="Print"
                            width={100}
                            onClick={printPriview}
                        />
                        <Button
                            text="Delete"
                            width={100}
                            onClick={deletePayment}
                        />
                    </div>
                </div>
                <div className="maingroup">
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Supplier</InputLabel>
                        <SelectBox
                            value={thirdParty}
                            displayExpr="tp_name"
                            valueExpr="tp_id"
                            placeholder="Supplier"
                            width={200}
                            onValueChanged={setTP}
                            dataSource={thirdParties.current}
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Transaction Type</InputLabel>
                        <SelectBox
                            placeholder="Transaction Type"
                            dataSource={paymentsTransTypes}
                            value={trancactionType}
                            displayExpr="tt_type"
                            valueExpr="tt_code"
                            onValueChanged={(e) => setTransactionType(e.value)}
                            width={200}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Payment Number</InputLabel>
                        <SelectBox
                            displayExpr={displayTypeTrxnb}
                            valueExpr="pay_id"
                            placeholder="Payment Number"
                            onValueChanged={getID}
                            dataSource={tempPay}
                            value={id}
                            width={200}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Status</InputLabel>
                        <TextBox
                            value={status}
                            placeholder="Status"
                            onValueChanged={(e) => setStatus(e.value)}
                            width={200}
                        />
                        <InputLabel sx={{ color: "white" }}>Refrence</InputLabel>
                        <TextBox
                            value={referenceNbr}
                            placeholder="Refrence"
                            onValueChanged={(e) => setReferenceNbr(e.value)}
                            width={200}
                        />
                        <InputLabel sx={{ color: "white" }}>Note</InputLabel>
                        <TextBox
                            value={note}
                            placeholder="Note"
                            onValueChanged={(e) => setNote(e.value)}
                            width={200}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Date</InputLabel>
                        <DateBox
                            value={date}
                            placeholder="Date"
                            onValueChanged={(e) => setDate(e.value)}
                            width={200}
                            type="datetime"
                        />
                        <InputLabel sx={{ color: "white" }}>Value Date</InputLabel>
                        <DateBox
                            value={valueDate}
                            placeholder="Value Date"
                            onValueChanged={(e) => setValueDate(e.value)}
                            width={200}
                            type="datetime"
                        />
                        <InputLabel sx={{ color: "white" }}>Doc Url</InputLabel>
                        <TextBox
                            value={docUrl}
                            placeholder="Doc Url"
                            onValueChanged={(e) => setDocUrl(e.value)}
                            width={200}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Employee</InputLabel>
                        <SelectBox
                            placeholder="Employee"
                            dataSource={thirdPartiesEmp.current}
                            value={thirdPartyEmp}
                            onValueChanged={(e) => setThirdPartyEmp(e.value)}
                            valueExpr="tp_id"
                            displayExpr="tp_name"
                            width={200}
                            showClearButton
                        />
                        <InputLabel sx={{ color: "white" }}>Description</InputLabel>
                        <TextArea
                            value={description}
                            placeholder="Description"
                            onValueChanged={(e) => setDescription(e.value)}
                            width={250}
                            height={114}
                            maxLength={300}
                        />
                    </div>
                </div>
                <div>
                    <Dialog
                        open={viewerPopup}
                        TransitionComponent={Transition}
                        keepMounted
                        fullWidth
                        maxWidth="md"
                        onClose={() => setViewerPopup(false)}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <DialogTitle>Payments</DialogTitle>
                            <Button className="button in_button2" onClick={handlePrint}>Print</Button>
                            <div className="me-4 cursor" onClick={() => setViewerPopup(false)}>
                                <ClearIcon />
                            </div>
                        </div>
                        <div ref={componentRef} dangerouslySetInnerHTML={{ __html: report }} />
                    </Dialog>
                </div>
                <div style={{ marginTop: 14 }}></div>
                <DataGrid
                    className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    onEditorPreparing={onEditiorPreparing}
                    ref={datagridRef}
                    dataSource={paymentsItems}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <Toolbar>
                        <Item location="after" visible={thirdParty !== null} name="addRowButton" />
                        <Item location="after" visible={false} name="saveButton" />
                        <Item location="after" visible={true} name="revertButton" />
                        <Item location="after" visible={true} name="searchPanel" />
                        <Item location="before" visible={true} name="groupPanel" />
                        <Item location="before" visible={true} name="columnChooserButton" />
                    </Toolbar>
                    <FilterBuilder defaultFields={pait_item_row} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        )
    }
    return <div className="table-container">{renderDataGrid()}</div>;
};

export default Payments;