import './Login.css'
import '@fontsource/roboto/500.css';
import Logo from '../../assets/images/dataharbor.png'
import { Button, FormControlLabel, InputLabel, TextField, InputAdornment, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import * as global from '../../utils/globals';
import { store, mainSliceActions } from "../../store";

function Login({ setMessage }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userId, setUserID] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [rememberMe, setRememberME] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedIn) {
            if (rememberMe)
                localStorage.setItem("user", JSON.stringify({ username: username, password: password }));

            store.dispatch(mainSliceActions.setUserId(userId));
            localStorage.setItem("page", JSON.stringify({ page: "/Dashboard" }));
            navigate("/Main");
        }
    }, [loggedIn])

    useEffect(() => {
        const token = localStorage.getItem("user");
        if (token) {
            const obj = JSON.parse(token);
            const data = [];
            data.push({ user_username: obj.username, user_password: obj.password })
            authUser(data);
        }
    }, [])


    function handleMainPage(e) {
        navigate("/");
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const login = (e) => {
        e.preventDefault();

        const data = [];
        data.push({ user_username: username, user_password: password })
        authUser(data);
    }

    const authUser = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(`${global.baseUrl}users/auth_user`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    if (response.success[0].message === 'User successfully logged in') {
                        setMessage({ message: "Login Success", type: "success" });
                        setLoggedIn(true);
                        setUserID(response.success[0].user_id);
                    } else {
                        setMessage({ message: response.success[0].message, type: "error" });
                    }
                }
                if (!response.error) {
                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                }
            });
    };

    return (
        <div className="Login">
            <div>
                <form onSubmit={login}>
                    <img className='image' src={Logo} onClick={handleMainPage}></img><p></p>
                    <div className='Login-bgline'>
                        <div className='Login-line1'>
                            <div className='Login-header'>
                                <TextField
                                    sx={{ marginTop: 2.5 }}
                                    label="Username"
                                    value={username}
                                    onChange={(event, newInputValue) => setUsername(event.target.value)}
                                    fullWidth />
                                <TextField
                                    sx={{ marginTop: 2.5 }}
                                    label="Password"
                                    value={password}
                                    onChange={(event, newInputValue) => setPassword(event.target.value)}
                                    fullWidth
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }} />
                                <p></p>
                                <div className='center'>
                                    <Button type='submit' sx={{ marginLeft: 5, marginRight: 5, width: '75%', '&:hover': { backgroundColor: "white", color: "#3a0d63", }, }}
                                        variant='contained'>Login</Button>
                                </div>
                                <FormControlLabel control={<Checkbox value={rememberMe} onChange={(e) => setRememberME(!rememberMe)} />} label="Remember me" />
                                <p></p>
                                <div className='center'>
                                    <InputLabel><u><i>Powered by Dataharbor&nbsp;</i></u></InputLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;