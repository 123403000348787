import "../../GlobalCSS.css";
import * as global from "../../utils/globals";
import swal from "sweetalert";
import { store } from "../../store";
import "./Inandout.css";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
    Toolbar,
    Item,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import { getBranches, getWarehouses, getDivisions, getStockInOut, getStockInOutItems, getItems } from "../../utils/helper";
import { useEffect, useRef, useState } from "react";
import { Button, DateBox, SelectBox, TextArea, TextBox } from "devextreme-react";
import { InputLabel } from "@mui/material";

const Inandout = ({ openDrawer, setIsLoading }) => {

    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalUsrId = store.getState().mainReducer.usr_id;
    const globalTransTypes = store.getState().mainReducer.transtypes;
    const inOutTransTypes = globalTransTypes.filter((tt) => tt.tt_type === 'Stock InOut' || tt.tt_type === "Stock InOut");

    const [id, setId] = useState(null);
    const [warehouseFrom, setWarehouseFrom] = useState(null);
    const [warehouseTo, setWarehouseTo] = useState(null);
    const [trancactionType, setTransactionType] = useState(null);
    const [description, setDescription] = useState("");
    const [note, setNote] = useState("");
    const [date, setDate] = useState(new Date());
    const [bra, setBra] = useState(null);
    const [stockInOut, setStockInOut] = useState([]);
    const [stockInOutItem, setStockInOutItem] = useState([]);
    const item = useRef([]);
    const warehouse = useRef([]);
    const branch = useRef([]);
    const division = useRef([]);
    const tempWarehouse = useRef([]);
    const datagridRef = useRef(null)

    useEffect(() => {
        setIsLoading(true);
        getUpdatedStockInOutItems();
        getWarehouses(globalCmpId).then((x) => {
            x.unshift({
                wa_id: null,
                wa_name: ''
            })
            warehouse.current = x;
            setWarehouseFrom(x[1].wa_id);
            newWarehouse(x[1].wa_id);
        });
        getBranches(globalCmpId).then((x) => {
            x.unshift({
                bra_id: null,
                bra_name: ''
            })
            branch.current = x;
            setBra(x[1].bra_id);
        });
        getDivisions(globalCmpId).then((x) => {
            x.unshift({
                div_id: null,
                div_name: ''
            })
            division.current = x;
        });
        getItems(globalCmpId).then((x) => {
            x.unshift({
                it_id: null,
                it_name: ''
            })
            item.current = x;
            setIsLoading(false);
        });
    }, [])

    const getUpdatedStockInOutItems = () => {
        getStockInOut(globalCmpId).then((x) => {
            setStockInOut(x);
            setIsLoading(false);
        });
    }

    const getID = (e) => {
        setIsLoading(true);
        if (e.value !== null && e.value !== undefined && e.value !== "") {
            getStockInOutItems(e.value).then((x) => setStockInOutItem(x), setIsLoading(false));

            for (let sio of stockInOut) {
                if (e.value === sio.sio_id) {
                    setWarehouseFrom(sio.sio_wa_id);
                    setWarehouseTo(sio.sio_wa_tp_id);
                    setTransactionType(sio.sio_tt_code);
                    setBra(sio.sio_bra_id);
                    setDate(sio.sio_date);
                    setNote(sio.sio_note);
                    setDescription(sio.sio_desc);
                    break;
                }
            }
        }
        else {
            setStockInOutItem([]);
            setIsLoading(false);
        }
        setId(e.value);
    }

    const newWarehouse = (e) => {
        if (e.value === null) {
            setWarehouseFrom(null);
            setWarehouseTo(null);
        }
        if (e.value) {
            setWarehouseFrom(e.value);
            const newTempWarehouse = warehouse.current.filter((id) => id.wa_id !== e.value);
            tempWarehouse.current = newTempWarehouse;
        } else {
            setWarehouseFrom(e);
            const newTempWarehouse = warehouse.current.filter((id) => id.wa_id !== e);
            tempWarehouse.current = newTempWarehouse;
        }
    }

    const displayTypeCode = (option) => {
        if (option !== null) {
            for (let io of globalTransTypes) {
                if (io.tt_code === option.tt_code) {
                    return io.tt_newcode + "-" + option.tt_type;
                }
            }
        }
        return "";
    }

    const displayTypeTrxnb = (option) => {
        if (option !== null) {
            for (let io of globalTransTypes) {
                if (io.tt_code === option.sio_tt_code) {
                    return io.tt_newcode + "-" + option.sio_trxnb;
                }
            }
        }
        return "";
    }

    const onReset = () => {
        setStockInOutItem([]);
        setDate(new Date());
        setTransactionType(null);
        setDescription("");
        setNote("");
        setBra(branch?.current[1].bra_id);
        setWarehouseFrom(warehouse?.current[1].wa_id);
        setWarehouseTo(null);
        newWarehouse(warehouse?.current[1].wa_id);
        setId(null);
        datagridRef.current.instance.cancelEditData();
    }

    const deleteStockInOut = () => {
        if (id !== null) {
            const data = [];
            data.push(id);
            delStockInOut(data);
        } else {
            swal("Info", "Please select an Transaction Number to delete.", "info");
            return;
        }
    }

    const sioit_rows = {
        sioit_id: "",
        sioit_sio_id: "",
        sioit_it_id: "",
        sioit_cost: "",
        sioit_qty: "",
        sioit_wa_tp_id: "",
        sioit_type: "",
        sioit_remqtywa: "",
        sioit_it_idinpack: "",
        sioit_qtyinpack: "",
        sioit_note: "",
        sioit_mcurratef: "",
        sioit_mcurrates: "",
        sioit_remqtywahio: "",
        sioit_timestamp: "",
        sioit_user_id: "",
        sioit_div_id: "",
    }

    const saveStockInOut = (e) => {
        if (trancactionType === null || trancactionType === undefined || trancactionType === "") {
            swal("Info", "Please select a Transaction Type.", "info")
            return;
        }

        if (warehouseFrom === null || warehouseFrom === undefined || warehouseFrom === "") {
            swal("Info", "Please select a Warehouse From.", "info")
            return;
        }

        if (warehouseTo === null || warehouseTo === undefined || warehouseTo === "") {
            swal("Info", "Please select a Warehouse To.", "info")
            return;
        }

        if (warehouseFrom === warehouseTo) {
            swal("Info", "Please select a Warehouse To.", "info")
            return;
        }

        let rows = datagridRef.current.instance.getVisibleRows();

        if (rows.length === 0) {
            swal("Error", "Please add at least 1 item!", "error")
            return;
        }

        if (id === null || id === undefined || id === "") {
            const params = {};

            params.sio_cmp_id = globalCmpId;
            params.sio_wa_id = warehouseFrom;
            params.sio_inout = "Out";
            params.sio_type = "Inter-Warehouse";
            params.sio_wa_tp_id = warehouseTo;
            params.sio_date = date;
            params.sio_tt_code = trancactionType;
            params.sio_trxnb = null;
            params.sio_desc = description;
            params.sio_prj_id = null;
            params.sio_bra_id = bra;
            params.sio_note = note;
            params.sio_user_id = globalUsrId;
            params.sio_valuedate = null;

            const data = [];
            data.push(params);

            const iparams = [];
            for (let items of rows) {
                const param = {};

                param.sioit_it_id = items.data.sioit_it_id;
                param.sioit_qty = items.data.sioit_qty;
                param.sioit_note = items.data.sioit_note;
                param.sioit_div_id = items.data.sioit_div_id;
                param.sioit_cost = null;
                param.sioit_wa_tp_id = null;
                param.sioit_type = "Inter-Warehouse";
                param.sioit_remqtywa = null;
                param.sioit_it_idinpack = null;
                param.sioit_qtyinpack = null;
                param.sioit_mcurratef = null;
                param.sioit_mcurrates = null;
                param.sioit_remqtywahio = null;
                param.sioit_timestamp = null;
                param.sioit_user_id = globalUsrId;

                iparams.push(param);
            };
            addStockInOut(data, iparams);
        } else {
            const params = {};

            params.sio_id = id;
            params.sio_cmp_id = globalCmpId;
            params.sio_wa_id = warehouseFrom;
            params.sio_inout = "Out";
            params.sio_type = "Inter-Warehouse";
            params.sio_wa_tp_id = warehouseTo;
            params.sio_date = date;
            params.sio_tt_code = trancactionType;
            params.sio_trxnb = stockInOut.filter((option) => option.sio_id === id)[0].sio_trxnb;
            params.sio_desc = description;
            params.sio_prj_id = null;
            params.sio_bra_id = bra;
            params.sio_note = note;
            params.sio_user_id = globalUsrId;
            params.sio_valuedate = null;

            const data = [];
            data.push(params);
            updStockInOut(data);

            let rows = datagridRef.current.instance.getVisibleRows();
            const updparams = [];
            const delparams = [];
            const addparams = [];

            for (let row of rows) {
                if (row.removed) {
                    delparams.push(row.data.sioit_id);
                    continue;
                }
                if (row.data.sioit_sio_id) {
                    const param = {};

                    param.sioit_id = row.data.sioit_id;
                    param.sioit_sio_id = row.data.sioit_sio_id;
                    param.sioit_it_id = row.data.sioit_it_id;
                    param.sioit_qty = row.data.sioit_qty;
                    param.sioit_note = row.data.sioit_note;
                    param.sioit_div_id = row.data.sioit_div_id;
                    param.sioit_cost = null;
                    param.sioit_wa_tp_id = null;
                    param.sioit_type = "Inter-Warehouse";
                    param.sioit_remqtywa = null;
                    param.sioit_it_idinpack = null;
                    param.sioit_qtyinpack = null;
                    param.sioit_mcurratef = null;
                    param.sioit_mcurrates = null;
                    param.sioit_remqtywahio = null;
                    param.sioit_timestamp = null;
                    param.sioit_user_id = globalUsrId;

                    updparams.push(param);
                } else {
                    const param = {};

                    param.sioit_it_id = row.data.sioit_it_id;
                    param.sioit_qty = row.data.sioit_qty;
                    param.sioit_note = row.data.sioit_note;
                    param.sioit_div_id = row.data.sioit_div_id;
                    param.sioit_cost = null;
                    param.sioit_wa_tp_id = null;
                    param.sioit_type = "Inter-Warehouse";
                    param.sioit_remqtywa = null;
                    param.sioit_it_idinpack = null;
                    param.sioit_qtyinpack = null;
                    param.sioit_mcurratef = null;
                    param.sioit_mcurrates = null;
                    param.sioit_remqtywahio = null;
                    param.sioit_timestamp = null;
                    param.sioit_user_id = globalUsrId;

                    addparams.push(param);
                }
            };

            if (delparams.length > 0) {
                delStockInOutItem(delparams);
            }
            if (addparams.length > 0) {
                addStockInOutItems(addparams, id);
            }
            if (updparams.length > 0) {
                updStockInOutItem(updparams);
            }

        }

    }

    const addStockInOut = (params, iparams) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockinout/add_stockinout`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    addStockInOutItems(iparams, response.sio_id[0][0].sio_id);
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedStockInOutItems();
            });
    }

    const addStockInOutItems = (params, id) => {
        for (let sio of params)
            sio.sioit_sio_id = id;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockinoutitems/add_stockinoutitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).catch((err) => console.log(err));
    }

    const updStockInOut = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockinout/upd_stockinout`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedStockInOutItems();
            });
    };

    const updStockInOutItem = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockinoutitems/upd_stockinoutitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    }

    const delStockInOut = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockinout/del_stockinout`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedStockInOutItems();
            });
    };

    const delStockInOutItem = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockinoutitems/del_stockinoutitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const renderColumns = () => {
        const res = [];

        for (var key in sioit_rows) {
            if (sioit_rows.hasOwnProperty(key)) {
                if ("sioit_id" === key) {
                    res.push(
                        <Column
                            dataField="sioit_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_sio_id" === key) {
                    res.push(
                        <Column
                            dataField="sioit_sio_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_it_id" === key) {
                    res.push(
                        <Column
                            dataField="sioit_it_id"
                            key={key}
                            visible={true}
                            caption="Item"
                        >
                            <Lookup dataSource={item.current} displayExpr="it_name" valueExpr="it_id" />
                        </Column>
                    );
                } else if ("sioit_cost" === key) {
                    res.push(
                        <Column
                            dataField="sioit_cost"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_wa_tp_id" === key) {
                    res.push(
                        <Column
                            dataField="sioit_wa_tp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_type" === key) {
                    res.push(
                        <Column
                            dataField="sioit_type"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_qty" === key) {
                    res.push(
                        <Column
                            dataField="sioit_qty"
                            key={key}
                            visible={true}
                            caption="Quantity"
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("sioit_remqtywa" === key) {
                    res.push(
                        <Column
                            dataField="sioit_remqtywa"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_it_idinpack" === key) {
                    res.push(
                        <Column
                            dataField="sioit_it_idinpack"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_qtyinpack" === key) {
                    res.push(
                        <Column
                            dataField="sioit_qtyinpack"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_note" === key) {
                    res.push(
                        <Column
                            dataField="sioit_note"
                            key={key}
                            visible={true}
                            caption="Note"
                        ></Column>
                    );
                } else if ("sioit_mcurratef" === key) {
                    res.push(
                        <Column
                            dataField="sioit_mcurratef"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_mcurrates" === key) {
                    res.push(
                        <Column
                            dataField="sioit_mcurrates"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_remqtywahio" === key) {
                    res.push(
                        <Column
                            dataField="sioit_remqtywahio"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_timestamp" === key) {
                    res.push(
                        <Column
                            dataField="sioit_timestamp"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_user_id" === key) {
                    res.push(
                        <Column
                            dataField="sioit_user_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sioit_div_id" === key) {
                    res.push(
                        <Column
                            dataField="sioit_div_id"
                            key={key}
                            visible={true}
                            caption="Division"
                        >
                            <Lookup valueExpr="div_id" displayExpr="div_name" dataSource={division.current} />
                        </Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <div className="groups">
                    <div className="groups-btns">
                        <Button
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                            text="Save"
                            onClick={saveStockInOut}
                            width={100}
                        />
                        <Button
                            text="Reset"
                            width={100}
                            onClick={onReset}
                        />
                        <Button
                            text="Delete"
                            width={100}
                            onClick={deleteStockInOut}
                        />
                        <Button
                            text="Print"
                            width={100}
                        />
                    </div>
                </div>
                <div className="maingroup">
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>From Warehouse</InputLabel>
                        <SelectBox
                            width={200}
                            placeholder="From Warehouse"
                            dataSource={warehouse.current}
                            valueExpr="wa_id"
                            displayExpr="wa_name"
                            value={warehouseFrom}
                            onValueChanged={newWarehouse}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />

                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Type</InputLabel>
                        <SelectBox
                            placeholder="Type"
                            value={trancactionType}
                            dataSource={inOutTransTypes}
                            valueExpr="tt_code"
                            displayExpr={displayTypeCode}
                            onValueChanged={(e) => setTransactionType(e.value)}
                            width={200}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Transaction Number</InputLabel>
                        <SelectBox
                            dataSource={stockInOut}
                            valueExpr="sio_id"
                            displayExpr={displayTypeTrxnb}
                            placeholder="Transaction Number"
                            width={200}
                            onValueChanged={getID}
                            value={id}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>To Warehouse</InputLabel>
                        <SelectBox
                            placeholder="To Warehouse"
                            dataSource={tempWarehouse.current}
                            valueExpr="wa_id"
                            displayExpr="wa_name"
                            value={warehouseTo}
                            onValueChanged={(e) => setWarehouseTo(e.value)}
                            width={200}
                            showClearButton
                        />
                        <InputLabel sx={{ color: "white" }}>Branch</InputLabel>
                        <SelectBox
                            value={bra}
                            dataSource={branch.current}
                            placeholder="Branch"
                            width={200}
                            valueExpr="bra_id"
                            displayExpr="bra_name"
                            onValueChanged={(e) => setBra(e.value)}
                        />
                        <InputLabel sx={{ color: "white" }}>Note</InputLabel>
                        <TextBox
                            width={200}
                            placeholder="Note"
                            value={note}
                            onValueChanged={(e) => setNote(e.value)}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Date</InputLabel>
                        <DateBox
                            value={date}
                            placeholder="Date"
                            width={200}
                            type="datetime"
                            onValueChanged={(e) => setDate(e.value)}
                        />
                        <InputLabel sx={{ color: "white" }}>Description</InputLabel>
                        <TextArea
                            value={description}
                            placeholder="Description"
                            width={250}
                            height={114}
                            maxLength={300}
                            onValueChanged={(e) => setDescription(e.value)}
                        />
                    </div>
                </div>
                <div className="datagridcss">
                    <DataGrid
                        className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        // onEditorPreparing={onEditiorPreparing}
                        ref={datagridRef}
                        dataSource={stockInOutItem}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        remoteOperations={false}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        columnMinWidth={70}
                        columnResizingMode={"widget"}
                    >
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 50, 100, 500]}
                            showInfo={true}
                            infoText="Page {0}. Total: {1} ({2} Items)"
                        />
                        <Toolbar>
                            <Item location="after" visible={trancactionType !== null} name="addRowButton" />
                            <Item location="after" visible={false} name="saveButton" />
                            <Item location="after" visible={true} name="revertButton" />
                            <Item location="after" visible={true} name="searchPanel" />
                            <Item location="before" visible={true} name="groupPanel" />
                            <Item location="before" visible={true} name="columnChooserButton" />
                        </Toolbar>
                        <FilterBuilder defaultFields={sioit_rows} />
                        <FilterRow visible={true} />
                        <Sorting visible={true} />
                        <GroupPanel visible={true} />
                        <SearchPanel visible={true} />
                        <Editing
                            allowAdding={true}
                            allowUpdating={true}
                            allowDeleting={true}
                            confirmDelete={true}
                            mode="batch"
                            selectTextOnEditStart={true}
                            useIcons={true}
                        />
                        <ColumnChooser enabled={false} />
                        <Scrolling columnRenderingMode="virtual" />
                        <KeyboardNavigation
                            editOnKeyPress={true}
                            enterKeyAction="moveFocus"
                            enterKeyDirection="column"
                        />
                        {renderColumns()}
                    </DataGrid>
                </div>
            </div>
        )
    }

    return <div>{renderDataGrid()}</div>;
};

export default Inandout;