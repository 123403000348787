import React, { useRef, useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    Lookup,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    RequiredRule,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css"
import { getItems, getCategories, getColors, getSizes, getBranches, getBrands, getDivisions, getUnits, getWarehouses, getCurrencies } from "../../utils/helper";
import { store } from "../../store";

const group = () => {
    return [
        { value: "Stock" },
        { value: "Mixed Product" },
        { value: "Finished Product" },
        { value: "Non Stock" },
        { value: "Set" },
        { value: "None" },
        { value: "Pack" },
    ];
};

const Item = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalFirstCur = store.getState().mainReducer.cur_id1;

    const categories = useRef([]);
    const colors = useRef([]);
    const sizes = useRef([]);
    const branches = useRef([]);
    const brands = useRef([]);
    const divisions = useRef([]);
    const units = useRef([]);
    const warehouses = useRef([]);
    const currencies = useRef([]);

    const [item, setItem] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getUpdatedItems();
        getCategories(globalCmpId).then((x) => {
            x.unshift({
                cat_id: null,
                cat_name: ''
            })
            categories.current = x;
        });
        getColors(globalCmpId).then((x) => {
            x.unshift({
                co_id: null,
                co_name: ''
            })
            colors.current = x;
        });
        getSizes(globalCmpId).then((x) => {
            x.unshift({
                sz_id: null,
                sz_name: ''
            })
            sizes.current = x;
        });
        getBranches(globalCmpId).then((x) => {
            x.unshift({
                bra_id: null,
                bra_name: ''
            })
            branches.current = x;
        });
        getBrands(globalCmpId).then((x) => {
            x.unshift({
                br_id: null,
                br_name: ''
            })
            brands.current = x;
        });
        getDivisions(globalCmpId).then((x) => {
            x.unshift({
                div_id: null,
                div_name: ''
            })
            divisions.current = x;
        });
        getUnits(globalCmpId).then((x) => {
            x.unshift({
                un_id: null,
                un_name: ''
            })
            units.current = x;
        });
        getWarehouses(globalCmpId).then((x) => {
            x.unshift({
                wa_id: null,
                wa_name: ''
            })
            warehouses.current = x;
        });
        getCurrencies(globalCmpId).then((x) => {
            x.unshift({
                cur_id: null,
                cur_code: ''
            })
            currencies.current = x;
            setIsLoading(false);
        });
    }, []);

    const getUpdatedItems = () => {
        getItems(globalCmpId).then((x) => {
            setItem(x);
            setIsLoading(false);
        });
    }

    const item_rows = {
        it_id: "",
        it_name: "",
        it_code: "",
        it_group: "",
        it_datecreated: "",
        it_cat_id: "",
        it_unitprice: "",
        it_tax: "",
        it_co_id: "",
        it_sz_id: "",
        it_cur_id: "",
        it_note: "",
        it_cmp_id: "",
        it_bra_id: "",
        it_br_id: "",
        it_wa_id: "",
        it_div_id: "",
        it_un_id: "",
        it_user_id: "",
        it_tax1: "",
        it_tax2: "",
        it_supplier: "",
        it_supplierprice: "",
        it_specialcode: "",
        it_imageurl: "",
        it_altname: "",
        it_minqty: "",
        it_maxqty: "",
        it_pos: "",
    };

    const addItem = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}items/add_item`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedItems();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updItem = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}items/upd_item`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedItems();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delItem = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}items/del_item`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedItems();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedItem = [];
            const updatedItem = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.it_id);
                } else if (row.type === "insert") {
                    addedItem.push({ ...row.data, it_cmp_id: globalCmpId });
                } else {
                    const obj = { ...row.key, ...row.data, it_cmp_id: globalCmpId };
                    updatedItem.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delItem(deletedIds);
            }
            if (addedItem.length > 0) {
                addItem(addedItem);
            }
            if (updatedItem.length > 0) {
                updItem(updatedItem);
            }
        }
    };

    const renderColumns = () => {
        const res = [];

        for (var key in item_rows) {
            if (item_rows.hasOwnProperty(key)) {
                if ("it_id" === key) {
                    res.push(
                        <Column
                            dataField="it_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("it_name" === key) {
                    res.push(
                        <Column
                            dataField="it_name"
                            caption="Name"
                            key={key}
                            visible={true}
                        >
                            <RequiredRule message="Item Name required!" />
                        </Column>
                    );
                } else if ("it_code" === key) {
                    res.push(
                        <Column
                            dataField="it_code"
                            caption="Code"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("it_group" === key) {
                    res.push(
                        <Column
                            dataField="it_group"
                            caption="Group"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={group()} valueExpr='value' displayExpr="value" />
                        </Column>
                    );
                } else if ("it_datecreated" === key) {
                    res.push(
                        <Column
                            dataField="it_datecreated"
                            caption="Date Created"
                            key={key}
                            visible={false}
                            allowEditing={false}
                        ></Column>
                    );
                } else if ("it_cat_id" === key) {
                    res.push(
                        <Column
                            dataField="it_cat_id"
                            caption="Category"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={categories.current} valueExpr='cat_id' displayExpr="cat_name" />
                        </Column>
                    );
                } else if ("it_unitprice" === key) {
                    res.push(
                        <Column
                            dataField="it_unitprice"
                            caption="Unit Price"
                            key={key}
                            visible={true}
                            dataType="number"
                            alignment="left"
                        >
                            <RequiredRule message="Item Price required!" />
                        </Column>
                    );
                } else if ("it_co_id" === key) {
                    res.push(
                        <Column
                            dataField="it_co_id"
                            caption="Color"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={colors.current} valueExpr='co_id' displayExpr="co_desc" />
                        </Column>
                    );
                } else if ("it_sz_id" === key) {
                    res.push(
                        <Column
                            dataField="it_sz_id"
                            caption="Size"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={sizes.current} valueExpr='sz_id' displayExpr="sz_code" />
                        </Column>
                    );
                } else if ("it_cur_id" === key) {
                    res.push(
                        <Column
                            dataField="it_cur_id"
                            caption="Currency"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={currencies.current} valueExpr='cur_id' displayExpr="cur_code" />
                        </Column>
                    );
                } else if ("it_note" === key) {
                    res.push(
                        <Column
                            dataField="it_note"
                            caption="Note"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("it_cmp_id" === key) {
                    res.push(
                        <Column
                            dataField="it_cmp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("it_bra_id" === key) {
                    res.push(
                        <Column
                            dataField="it_bra_id"
                            caption="Branch"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={branches.current} valueExpr='bra_id' displayExpr="bra_name" />
                        </Column>
                    );
                } else if ("it_br_id" === key) {
                    res.push(
                        <Column
                            dataField="it_br_id"
                            caption="Brand"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={brands.current} valueExpr='br_id' displayExpr="br_name" />
                        </Column>
                    );
                } else if ("it_wa_id" === key) {
                    res.push(
                        <Column
                            dataField="it_wa_id"
                            caption="Warehouse"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={warehouses.current} valueExpr='wa_id' displayExpr="wa_name" />
                        </Column>
                    );
                } else if ("it_div_id" === key) {
                    res.push(
                        <Column
                            dataField="it_div_id"
                            caption="Division"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={divisions.current} valueExpr='div_id' displayExpr="div_name" />
                        </Column>
                    );
                } else if ("it_un_id" === key) {
                    res.push(
                        <Column
                            dataField="it_un_id"
                            caption="Unit"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={units.current} valueExpr='un_id' displayExpr="un_code" />
                        </Column>
                    );
                } else if ("it_user_id" === key) {
                    res.push(
                        <Column
                            dataField="it_user_id"
                            caption="User"
                            key={key}
                            visible={true}
                            allowEditing={false}
                        ></Column>
                    );
                } else if ("it_tax" === key) {
                    res.push(
                        <Column
                            dataField="it_tax"
                            caption="Tax"
                            key={key}
                            visible={true}
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("it_tax1" === key) {
                    res.push(
                        <Column
                            dataField="it_tax1"
                            caption="Tax 1"
                            key={key}
                            visible={true}
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("it_tax2" === key) {
                    res.push(
                        <Column
                            dataField="it_tax2"
                            caption="Tax 2"
                            key={key}
                            visible={true}
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("it_supplier" === key) {
                    res.push(
                        <Column
                            dataField="it_supplier"
                            caption="Supplier"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("it_supplierprice" === key) {
                    res.push(
                        <Column
                            dataField="it_supplierprice"
                            caption="Supplier Price"
                            key={key}
                            visible={true}
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("it_specialcode" === key) {
                    res.push(
                        <Column
                            dataField="it_specialcode"
                            caption="Special code"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("it_imageurl" === key) {
                    res.push(
                        <Column
                            dataField="it_imageurl"
                            caption="Image"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("it_altname" === key) {
                    res.push(
                        <Column
                            dataField="it_altname"
                            caption="Alternative name"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("it_minqty" === key) {
                    res.push(
                        <Column
                            dataField="it_minqty"
                            caption="Min quantity"
                            key={key}
                            visible={true}
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("it_maxqty" === key) {
                    res.push(
                        <Column
                            dataField="it_maxqty"
                            caption="Max quantity"
                            key={key}
                            visible={true}
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("it_pos" === key) {
                    res.push(
                        <Column
                            dataField="it_pos"
                            caption="POS"
                            key={key}
                            visible={true}
                            dataType="boolean"
                        ></Column>
                    );
                }
            }
        }
        return res;
    };
    const onInitNewRow = (e) => {
        e.data.it_cur_id = globalFirstCur;
        e.data.it_wa_id = warehouses.current[1].wa_id;
        e.data.it_bra_id = branches.current[1].bra_id;
        e.data.it_tax = 0;
        e.data.it_tax1 = 0;
        e.data.it_tax2 = 0;
        e.data.it_div_id = divisions.current[1].div_id;
    }

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    dataSource={item}
                    showBorders={true}
                    onInitNewRow={onInitNewRow}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} items)"
                    />
                    <FilterBuilder defaultFields={item_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default Item;