import { useState, useCallback, useEffect } from 'react';
import {
    Category, ColorizeSharp, Home, Info, Inventory,
    LocationCity, Payment, Receipt, Settings, Warehouse, Logout, ColorLens, Scale, Straighten, BrandingWatermark, Storefront, ViewModule,
    AccountCircle, AdminPanelSettings, PersonAdd, Badge, BusinessCenter, Business, Mediation, AttachMoney, CurrencyExchange, GroupWork, PointOfSale, SupervisorAccount, RequestQuote, Description,
    ShoppingBag, CreditScore, Assessment, Summarize, ViewKanban, Payments, ReceiptLong, EventNote,
    ChangeCircle,
    Tune,
    Layers,
    AllInbox,
    FactCheck,
    FoodBank,
    EventSeat,
    TableBar,
    TableView,
    Money
} from '@mui/icons-material';
import './Drawer.css';
import {
    CssBaseline, IconButton, ListItemText, ListItemIcon, ListItemButton, Divider, List, Box, styled, useTheme,
    Toolbar, Typography, Button, Tooltip, Zoom, Fade, useMediaQuery
} from '@mui/material';
import { Icon } from "@mui/material";
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiDrawer from '@mui/material/Drawer';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ColorBox from 'devextreme-react/color-box';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import '../../GlobalCSS.css';
import * as global from '../../utils/globals';
import DateTime from '../DateTime/DateTime';
import swal from "sweetalert";
import "../../GlobalCSS.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { store } from '../../store';
// import parser from 'ua-parser-js';
// import mediaQuery from 'css-mediaquery';
// import { createTheme, ThemeProvider } from '@mui/material/styles';

const eventHandlingLabel = { 'aria-label': 'Event Handling' };

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawers = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const AppBarPhone = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeaderPhone = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function Drawer({ setPage, pageName, setOpenDrawer, setLogout }) {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalProfile = store.getState().mainReducer.pro_id;
    const globalUserType = store.getState().mainReducer.usr_type;

    const isMatch = useMediaQuery('(min-width: 600px)' && '(max-width: 900px)');

    const [online, setOnline] = useState(navigator.onLine);

    const [open, setOpen] = useState(false);

    const [sOpen, setsOpen] = useState(false);

    const [iOpen, setiOpen] = useState(false);

    const [aOpen, setaOpen] = useState(false);

    const [rOpen, setrOpen] = useState(false);

    const [pOpen, setpOpen] = useState(false);

    const [posOpen, setPosOpen] = useState(false);

    const [stockOpen, setStockOpen] = useState(false);

    const [reportOpen, setReportOpen] = useState(false);

    const [timelineOpen, setTimelineOpen] = useState(false);

    const theme = useTheme();

    const [themePopup, setThemePopup] = useState(false);

    const [color, setColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--main-bg-color'));

    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        setInterval(() => {
            setOnline(navigator.onLine);
        }, 1000);
    })

    const handleSettingsClick = () => {
        setsOpen(!sOpen);
    };

    const handleInvClick = () => {
        setiOpen(!iOpen);
    };

    const handleAdminClick = () => {
        setaOpen(!aOpen);
    };

    const handleReceiptClick = () => {
        setrOpen(!rOpen);
    }

    const handlePaymentClick = () => {
        setpOpen(!pOpen);
    }

    const handlePosClick = () => {
        setPosOpen(!posOpen);
    }

    const handleStockClick = () => {
        setStockOpen(!stockOpen);
    }

    const handleReportClick = () => {
        setReportOpen(!reportOpen);
    }

    const handleTimelineClick = () => {
        setTimelineOpen(!timelineOpen);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setOpenDrawer(false);
    };

    const handleColorChange = useCallback(({ value }) => {
        document.documentElement.style.setProperty('--main-bg-color', value);
        setColor(value);
    }, []);

    const openColorBox = () => {
        setThemePopup(true);
    }

    const saveColor = () => {
        const data = {
            pro_id: globalProfile,
            color: color,
            cmp_id: globalCmpId
        };
        updateProfileColor(data);
        setThemePopup(false);
    }

    const logout = () => {
        localStorage.removeItem("user");
        setLogout(true);
    }

    const updateProfileColor = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(`${global.baseUrl}profiles/save_profile_color`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("Success", response.success, "success")
                }
                if (!response.error) {
                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                }
            });
    }

    // function handleRender(req, res) {
    //     const deviceType = parser(req.headers['user-agent']).device.type || 'desktop';
    //     const ssrMatchMedia = (query) => ({
    //         matches: mediaQuery.match(query, {
    //             // The estimated CSS width of the browser.
    //             width: deviceType === 'mobile' ? '0px' : '1024px',
    //         }),
    //     });

    //     const theme = createTheme({
    //         components: {
    //             // Change the default options of useMediaQuery
    //             MuiUseMediaQuery: {
    //                 defaultProps: {
    //                     ssrMatchMedia,
    //                 },
    //             },
    //         },
    //     });

    //     const html = renderToString(
    //         <ThemeProvider theme={theme}>
    //             <></>
    //         </ThemeProvider>,
    //     );
    // }

    // !!! When Pages become to many use this !!!
    //    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // function createDrawerData(name, icon2, name2) {
    //     return { name, icon2, name2 };
    // }

    // const adminDrawer = [
    //     createDrawerData("/AddUser", <PersonAdd />, "Add User"),
    //     createDrawerData("/Currency", <AttachMoney />, "Currency"),
    //     createDrawerData("/Department", <Business />, "Department"),
    //     createDrawerData("/Employees", <SupervisorAccount />, "Employees"),
    //     createDrawerData("/EmployeeSalary", <RequestQuote />, "Employee Salary"),
    //     createDrawerData("/Position", <Badge />, "Position"),
    //     createDrawerData("/Rate", <CurrencyExchange />, "Rate"),
    //     createDrawerData("/Roles", <BusinessCenter />, "Roles"),
    //     createDrawerData("/Thirdparty", <Mediation />, "Third Party"),
    // ];

    // const inventoryDrawer = [
    //     createDrawerData(() => setPage("/Branches"), <LocationCity />, "Branches"),
    //     createDrawerData(() => setPage("/Brands"), <BrandingWatermark />, "Brands"),
    //     createDrawerData(() => setPage("/Categories"), <Category />, "Category"),
    //     createDrawerData(() => setPage("/Divisions"), <Storefront />, "Divisions"),
    //     createDrawerData(() => setPage("/Warehouse"), <Warehouse />, "Warehouse"),
    //     createDrawerData(() => setPage("/Item"), <ViewModule />, "Item"),
    //     createDrawerData(() => setPage("/ItemColor"), <ColorizeSharp />, "ItemColor"),
    //     createDrawerData(() => setPage("/Project"), <GroupWork />, "Project"),
    //     createDrawerData(() => setPage("/Sizes"), <Straighten />, "Size"),
    //     createDrawerData(() => setPage("/Units"), <Scale />, "Units"),
    // ];

    // const paymentDrawer = [
    //     createDrawerData(() => setPage("/Purchase"), <ShoppingBag />, "Purchase"),
    // ];

    // const receiptDrawer = [
    //     createDrawerData(() => setPage("/Invoice"), <Description />, "Invoice"),
    // ];

    // const settingsDrawer = [
    //     createDrawerData(() => setPage("/Profile"), <AccountCircle />, "Profile"),
    //     createDrawerData(openColorBox, <ColorLens />, "Theme"),
    // ];

    // function createDrawer(id, page, icon, setter, collapse, onClick, data) {
    //     return { id, page, icon, setter, collapse, onClick, data };
    // }

    // const tDrawer = [
    //     createDrawer(0, "Point of Sale", <PointOfSale />, null, false, () => navigate("/POS"), null),
    //     createDrawer(1, "Inventory", <Inventory />, iOpen, true, handleInvClick, inventoryDrawer),
    //     createDrawer(2, "Payment", <Payment />, pOpen, true, handlePaymentClick, paymentDrawer),
    //     createDrawer(3, "Receipt", <Receipt />, rOpen, true, handleReceiptClick, receiptDrawer),
    //     createDrawer(4, "Settings", <Settings />, sOpen, true, handleSettingsClick, settingsDrawer),
    //     createDrawer(5, "About", <Info />, null, false, null, null),
    // ];

    function createDrawerData(page, icon, name) {
        return { page, icon, name };
    }

    const adminDrawer = [
        createDrawerData("/AddUser", <PersonAdd />, "Add User"),
        createDrawerData("/Currency", <AttachMoney />, "Currency"),
        createDrawerData("/Department", <Business />, "Department"),
        createDrawerData("/Employees", <SupervisorAccount />, "Employees"),
        createDrawerData("/EmployeeSalary", <RequestQuote />, "Employee Salary"),
        createDrawerData("/Position", <Badge />, "Position"),
        createDrawerData("/Rate", <CurrencyExchange />, "Rate"),
        createDrawerData("/Roles", <BusinessCenter />, "Roles"),
        createDrawerData("/Thirdparty", <Mediation />, "Third Party"),
    ];

    const inventoryDrawer = [
        createDrawerData("/Branches", <LocationCity />, "Branches"),
        createDrawerData("/Brands", <BrandingWatermark />, "Brands"),
        createDrawerData("/Categories", <Category />, "Category"),
        createDrawerData("/Divisions", <Storefront />, "Divisions"),
        createDrawerData("/Warehouse", <Warehouse />, "Warehouse"),
        createDrawerData("/Item", <ViewModule />, "Item"),
        createDrawerData("/ItemColor", <ColorizeSharp />, "Item Color"),
        createDrawerData("/Project", <GroupWork />, "Project"),
        createDrawerData("/Sizes", <Straighten />, "Size"),
        createDrawerData("/Units", <Scale />, "Units"),
    ];

    const listForDrawer = () => (
        <>
            <Divider />
            <List>

                <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Dashboard"} arrow placement='right'>
                    <ListItemButton onClick={() => setPage("/Dashboard")}>
                        <ListItemIcon>
                            {<Home />}
                        </ListItemIcon>
                        <ListItemText primary={'Dashboard'} />
                    </ListItemButton>
                </Tooltip>

                {(globalUserType === global.owner_id || globalUserType === global.admin_id) &&
                    <div>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Admin"} arrow placement='right'>
                            <ListItemButton onClick={handleAdminClick} selected={aOpen} sx={{
                                '&.Mui-selected': {
                                    backgroundColor: '#b160fc'
                                }
                            }}>
                                <ListItemIcon>
                                    {<AdminPanelSettings />}
                                </ListItemIcon>
                                <ListItemText primary={'Admin'} />
                                {aOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </Tooltip>
                        <Collapse in={aOpen} timeout="auto" unmountOnExit>
                            <Divider />
                            {adminDrawer.map((row) => (
                                <List component="div" disablePadding key={row.name}>
                                    <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Fade} title={open ? null : (row.name)} arrow placement='right'>
                                        <ListItemButton sx={{ pl: 3 }} onClick={() => setPage(row.page)}>
                                            <ListItemIcon>
                                                {row.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={row.name} />
                                        </ListItemButton>
                                    </Tooltip>
                                </List>
                            ))}
                            <Divider />
                        </Collapse>
                    </div>
                }

                <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "POS"} arrow placement='right'>
                    <ListItemButton onClick={handlePosClick} selected={posOpen} sx={{
                        '&.Mui-selected': {
                            backgroundColor: '#b160fc'
                        }
                    }}>
                        <ListItemIcon>
                            {<FoodBank />}
                        </ListItemIcon>
                        <ListItemText primary={'POS'} />
                        {posOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </Tooltip>
                <Collapse in={posOpen} timeout="auto" unmountOnExit>
                    <Divider />
                    <List component="div" disablePadding>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Point of Sale"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => navigate("/POS")}>
                                <ListItemIcon>
                                    <PointOfSale />
                                </ListItemIcon>
                                <ListItemText primary="Point of Sale" />
                            </ListItemButton>
                        </Tooltip>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Sections"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/Sections")} >
                                <ListItemIcon>
                                    <TableView />
                                </ListItemIcon>
                                <ListItemText primary="Sections" />
                            </ListItemButton>
                        </Tooltip>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Tables"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/Tables")}>
                                <ListItemIcon>
                                    <TableBar />
                                </ListItemIcon>
                                <ListItemText primary="Tables" />
                            </ListItemButton>
                        </Tooltip>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Money Bills"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/MoneyBills")}>
                                <ListItemIcon>
                                    <Money />
                                </ListItemIcon>
                                <ListItemText primary="Money Bills" />
                            </ListItemButton>
                        </Tooltip>
                    </List>
                    <Divider />
                </Collapse>

                <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Inventory"} arrow placement='right'>
                    <ListItemButton onClick={handleInvClick} selected={iOpen} sx={{
                        '&.Mui-selected': {
                            backgroundColor: '#b160fc'
                        }
                    }}>
                        <ListItemIcon>
                            {<Inventory />}
                        </ListItemIcon>
                        <ListItemText primary={'Inventory'} />
                        {iOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </Tooltip>
                <Collapse in={iOpen} timeout="auto" unmountOnExit>
                    <Divider />
                    {inventoryDrawer.map((row) => (
                        <List component="div" disablePadding key={row.name}>
                            <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Fade} title={open ? null : (row.name)} arrow placement='right'>
                                <ListItemButton sx={{ pl: 3 }} onClick={() => setPage(row.page)}>
                                    <ListItemIcon>
                                        {row.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={row.name} />
                                </ListItemButton>
                            </Tooltip>
                        </List>
                    ))}
                    <Divider />
                </Collapse>

                <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Payment"} arrow placement='right'>
                    <ListItemButton onClick={handlePaymentClick} selected={pOpen} sx={{
                        '&.Mui-selected': {
                            backgroundColor: '#b160fc'
                        }
                    }}>
                        <ListItemIcon>
                            {<Payment />}
                        </ListItemIcon>
                        <ListItemText primary={'Payment'} />
                        {pOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </Tooltip>
                <Collapse in={pOpen} timeout="auto" unmountOnExit>
                    <Divider />
                    <List component="div" disablePadding>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Purchase"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/Purchase")}>
                                <ListItemIcon>
                                    <ShoppingBag />
                                </ListItemIcon>
                                <ListItemText primary="Purchase" />
                            </ListItemButton>
                        </Tooltip>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Payments"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/Payments")}>
                                <ListItemIcon>
                                    <Payments />
                                </ListItemIcon>
                                <ListItemText primary="Payments" />
                            </ListItemButton>
                        </Tooltip>
                    </List>
                    <Divider />
                </Collapse>

                <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Receipt"} arrow placement='right'>
                    <ListItemButton onClick={handleReceiptClick} selected={rOpen} sx={{
                        '&.Mui-selected': {
                            backgroundColor: '#b160fc'
                        }
                    }}>
                        <ListItemIcon>
                            {<Receipt />}
                        </ListItemIcon>
                        <ListItemText primary={'Receipt'} />
                        {rOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </Tooltip>
                <Collapse in={rOpen} timeout="auto" unmountOnExit>
                    <Divider />
                    <List component="div" disablePadding>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Invoice"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/Invoice")}>
                                <ListItemIcon>
                                    <Description />
                                </ListItemIcon>
                                <ListItemText primary="Invoice" />
                            </ListItemButton>
                        </Tooltip>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Receipts"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/Receipt")}>
                                <ListItemIcon>
                                    <ReceiptLong />
                                </ListItemIcon>
                                <ListItemText primary="Receipts" />
                            </ListItemButton>
                        </Tooltip>
                    </List>
                    <Divider />
                </Collapse>

                <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Calendar"} arrow placement='right'>
                    <ListItemButton onClick={() => setPage("/Calendar")}>
                        <ListItemIcon>
                            {<EventNote />}
                        </ListItemIcon>
                        <ListItemText primary={'Calendar'} />
                    </ListItemButton>
                </Tooltip>

                {/* <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Report"} arrow placement='right'>
                    <ListItemButton onClick={handleReportClick}>
                        <ListItemIcon>
                            {<Assessment />}
                        </ListItemIcon>
                        <ListItemText primary={'Report'} />
                        {reportOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </Tooltip>
                <Collapse in={reportOpen} timeout="auto" unmountOnExit>
                    <Divider />
                    <List component="div" disablePadding>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Designer"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/ReportDesigner")}>
                                <ListItemIcon>
                                    <Summarize />
                                </ListItemIcon>
                                <ListItemText primary="Designer" />
                            </ListItemButton>
                        </Tooltip>

                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Viewer"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/ReportViewer")}>
                                <ListItemIcon>
                                    <ViewKanban />
                                </ListItemIcon>
                                <ListItemText primary="Viewer" />
                            </ListItemButton>
                        </Tooltip>
                    </List>
                    <Divider />
                </Collapse> */}

                <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Stock"} arrow placement='right'>
                    <ListItemButton onClick={handleStockClick} selected={stockOpen} sx={{
                        '&.Mui-selected': {
                            backgroundColor: '#b160fc'
                        }
                    }}>
                        <ListItemIcon>
                            <AllInbox />
                        </ListItemIcon>
                        <ListItemText primary={'Stock'} />
                        {stockOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </Tooltip>
                <Collapse in={stockOpen} timeout="auto" unmountOnExit>
                    <Divider />
                    <List component="div" disablePadding>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Stock Adjustment"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/StockAdjustment")}>
                                <ListItemIcon>
                                    <Tune />
                                </ListItemIcon>
                                <ListItemText primary="Stock Adjustment" />
                            </ListItemButton>
                        </Tooltip>

                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Stock In Out"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/StockInOut")}>
                                <ListItemIcon>
                                    <ChangeCircle />
                                </ListItemIcon>
                                <ListItemText primary="Stock In Out" />
                            </ListItemButton>
                        </Tooltip>

                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Production Orders"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/ProductionOrders")}>
                                <ListItemIcon>
                                    <FactCheck />
                                </ListItemIcon>
                                <ListItemText primary="Production Orders" />
                            </ListItemButton>
                        </Tooltip>
                    </List>
                    <Divider />
                </Collapse>

                <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Settings"} arrow placement='right'>
                    <ListItemButton onClick={handleSettingsClick} selected={sOpen} sx={{
                        '&.Mui-selected': {
                            backgroundColor: '#b160fc'
                        }
                    }}>
                        <ListItemIcon>
                            {<Settings />}
                        </ListItemIcon>
                        <ListItemText primary={'Settings'} />
                        {sOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </Tooltip>
                <Collapse in={sOpen} timeout="auto" unmountOnExit>
                    <Divider />
                    <List component="div" disablePadding>
                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Profile"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/Profile")}>
                                <ListItemIcon>
                                    {<AccountCircle />}
                                </ListItemIcon>
                                <ListItemText primary='Profile' />
                            </ListItemButton>
                        </Tooltip>

                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Payment Method"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={() => setPage("/PaymentMethod")}>
                                <ListItemIcon>
                                    {<CreditScore />}
                                </ListItemIcon>
                                <ListItemText primary='Payment Method' />
                            </ListItemButton>
                        </Tooltip>

                        <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Theme"} arrow placement='right'>
                            <ListItemButton sx={{ pl: 3 }} onClick={openColorBox}>
                                <ListItemIcon>
                                    <ColorLens />
                                </ListItemIcon>
                                <ListItemText primary="Theme" />
                            </ListItemButton>
                        </Tooltip>
                    </List>
                    <Divider />
                </Collapse>

                <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "About"} arrow placement='right'>
                    <ListItemButton onClick={() => setPage("/About")}>
                        <ListItemIcon>
                            {<Info />}
                        </ListItemIcon>
                        <ListItemText primary={'About'} />
                    </ListItemButton>
                </Tooltip>
                <Divider />
                <Tooltip enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} title={open ? null : "Logout"} arrow placement='right'>
                    <ListItemButton onClick={logout}>
                        <ListItemIcon>
                            {<Logout />}
                        </ListItemIcon>
                        <ListItemText primary={'Logout'} />
                    </ListItemButton>
                </Tooltip>
                <div className="offline-online-container">
                    {online && <Icon className="fa fa-wifi"></Icon>}

                    {!online && <Icon className="fa fa-wifi-slash"></Icon>}
                </div>
            </List>
        </>
    )


    const drawer = () => (
        <Box sx={{ display: 'flex' }} >
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {pageName}
                    </Typography>
                    <DateTime />
                </Toolbar>
            </AppBar>
            <Drawers variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                {listForDrawer()}
            </Drawers>
            {/* <>
            </>
            {isMatch &&
                <>
                    <AppBarPhone position="fixed" open={open}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{ mr: 2, ...(open && { display: 'none' }) }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" noWrap component="div">
                                {pageName}
                            </Typography>
                        </Toolbar>
                    </AppBarPhone>
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={open}
                    >
                        <DrawerHeaderPhone>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </DrawerHeaderPhone>
                        {listForDrawer()}
                    </Drawer>

                </>
            } */}

            <div>
                <Popup
                    visible={themePopup}
                    onHiding={() => setThemePopup(false)}
                    dragEnabled={false}
                    showCloseButton={true}
                    showTitle={true}
                    width={300}
                    height={180}

                >
                    <Position
                        at="center"
                        my="center"
                    />
                    <ToolbarItem
                        text="Theme Color"
                        location="before">
                    </ToolbarItem>
                    <div className='color-box'>
                        <div className='dx-colorbox-palette'>
                            <ColorBox
                                value={color}
                                inputAttr={eventHandlingLabel}
                                onValueChanged={handleColorChange}
                            />
                        </div>
                        <Button
                            sx={{ marginTop: '16px', '&:hover': { backgroundColor: "white", color: getComputedStyle(document.documentElement).getPropertyValue('--main-bg-color'), }, }}
                            variant="contained"
                            onClick={saveColor}>Save</Button>
                    </div>
                </Popup>
            </div>
        </Box>
    );

    return (
        <div className={open === true ? 'shiftLeft' : 'shiftRight'}>
            {drawer()}
        </div>
    );

}

export default Drawer;