import React, { useRef, useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
    Item,
    Toolbar,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css"
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import {
    getInvoiceItems, getInvoices, getCurrencies, getItems,
    getWarehouses, getDivisions, getBranches, getProjects, convertDateOnly,
    getThirdpartiesType, getCompany
} from "../../utils/helper";
import { Button, DateBox, NumberBox, SelectBox, TextBox } from "devextreme-react";
import { Dialog, DialogTitle, InputLabel, Stack, Switch, Typography } from "@mui/material";
import "./Invoice.css"
import { store } from "../../store";
import ClearIcon from "@mui/icons-material/Clear";
import Slide from "@mui/material/Slide";
import { useReactToPrint } from "react-to-print";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const exportsSettings = {
    pdf: {
        title: "Invoice",
        author: "GrapeCity",
        subject: "Web Reporting",
        keywords: "reporting, sample",
        userPassword: "pwd",
        ownerPassword: "ownerPwd",
        printing: "none",
        copying: false,
        modifying: false,
        annotating: false,
        contentAccessibility: false,
        documentAssembly: false,
        pdfVersion: "1.7",
        autoPrint: true,
        filename: "Invoice.pdf",
    },
    html: {
        title: "Invoice",
        filename: "Invoice.html",
        autoPrint: true,
        multiPage: true,
        embedImages: "external",
        outputType: "html",
    },
};

// remove items from this array to hide unnecessary Exports Types
const availableExports = ["pdf", "html", "tabular-data"];

const Invoice = ({ openDrawer, data, setData, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalUsrId = store.getState().mainReducer.usr_id;
    const globalFirstCur = store.getState().mainReducer.cur_id1;
    const globalSecondCur = store.getState().mainReducer.cur_id2;
    const globalRates = store.getState().mainReducer.rates;
    const globalTransTypes = store.getState().mainReducer.transtypes;
    const invoiceTransTypes = globalTransTypes.filter((tt) => tt.tt_type === 'Sale Invoice' || tt.tt_type === 'Delivery Goods' || tt.tt_type === 'Profoma Invoice'
        || tt.tt_type === 'Return Sale' || tt.tt_type === 'Delivery Return');


    const [invoice, setInvoice] = useState([]);
    const [invoiceItem, setInvoiceItem] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [id, setId] = useState(null);
    const [discperc, setDiscperc] = useState(0);
    const [note, setNote] = useState("");
    const [discamt, setDiscAmt] = useState(0);
    const [curr, setCurr] = useState(globalFirstCur);
    const [TotalTax, setTotalTax] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [branch, setBranch] = useState(null);
    const [project, setProject] = useState(null);
    const [thirdParties, setThirdParties] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [switchPaid, setSwitchPaid] = useState(1);
    const [ware, setWare] = useState(null);
    const [popup, setPopup] = useState(false);
    const [change, setChange] = useState(null);
    const [viewerPopup, setViewerPopup] = useState(false);
    const [report, setReport] = useState();
    const [company, setCompany] = useState([]);
    const [tempInvoice, setTempInvoice] = useState([]);
    const [trancactionType, setTransactionType] = useState(null);
    const [switchDelivered, setSwitchDelivered] = useState(1);
    const [totalOne, setTotalOne] = useState(0);
    const datagridRef = useRef(null);
    const items = useRef([]);
    const warehouse = useRef([]);
    const division = useRef([]);
    const branches = useRef([]);
    const projects = useRef([]);
    const thirdParty = useRef([]);
    const total = subTotal - discamt + TotalTax;

    useEffect(() => {
        setIsLoading(true);
        getCurrencies(globalCmpId).then((x) => setCurrency(x));
        getCompany(globalCmpId).then((x) => setCompany(x));
        getItems(globalCmpId).then((x) => {
            x.unshift({
                it_id: null,
                it_name: '',
                it_cur_id: globalFirstCur,
                it_unitprice: null,
                it_tax: null,
                it_tax1: null,
                it_tax2: null,
                it_wa_id: null,
                it_div_id: null,
                it_note: ''
            })
            items.current = x;
        }
        );
        getDivisions(globalCmpId).then((x) => {
            x.unshift({
                div_id: null,
                div_name: ''
            })
            division.current = x;
        });
        getWarehouses(globalCmpId).then((x) => {
            x.unshift({
                wa_id: null,
                wa_name: ''
            })
            warehouse.current = x;
            setWare(x[1].wa_id);
        });
        getBranches(globalCmpId).then((x) => {
            x.unshift({
                bra_id: null,
                bra_name: ''
            })
            branches.current = x;
            setBranch(x[1].bra_id)
        });
        getProjects(globalCmpId).then((x) => {
            x.unshift({
                prj_id: null,
                prj_name: ''
            })
            projects.current = x;
            setProject(x[1].prj_id);
        });
        getThirdpartiesType("Client", globalCmpId).then((x) => {
            x.unshift({
                tp_id: null,
                tp_name: '',
                tp_address: ''
            })
            thirdParty.current = x;
        });
        getUpdatedInvoiceItem();
        return () => setData({});
    }, [])

    const getUpdatedInvoiceItem = () => {
        getInvoices(globalCmpId).then((x) => {
            setInvoice(x);
            setIsLoading(false);
            setTempInvoice(x);
        });
    }

    const currencyChange = (e) => {
        const newIt = [];
        setCurr(e.value);
        if (invoiceItem.length > 0) {
            for (let it of invoiceItem) {
                for (let kit of items.current) {
                    if (kit.it_id === it.iit_it_id) {
                        for (let exc of globalRates) {
                            var newPrice = kit.it_unitprice;

                            if (kit.it_cur_id === e.value) {
                                it.iit_discountamount = newPrice * it.iit_qty * it.iit_discountperc / 100;
                                it.iit_total = newPrice * it.iit_qty - it.iit_discountamount;
                                it.iit_it_price = newPrice;
                                break;
                            }
                            else if (kit.it_cur_id === exc.rate_cur_id2 && e.value === exc.rate_cur_id1) {
                                newPrice = Math.round(((kit.it_unitprice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                it.iit_discountamount = newPrice * it.iit_qty * it.iit_discountperc / 100;
                                it.iit_total = newPrice * it.iit_qty - it.iit_discountamount;
                                it.iit_it_price = newPrice;
                                break;
                            } else if (kit.it_cur_id === exc.rate_cur_id1 && e.value === exc.rate_cur_id2) {
                                newPrice = Math.round(((kit.it_unitprice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                it.iit_discountamount = newPrice * it.iit_qty * it.iit_discountperc / 100;
                                it.iit_total = newPrice * it.iit_qty - it.iit_discountamount;
                                it.iit_it_price = newPrice;
                                break;
                            }

                            if (newPrice === kit.it_unitprice) {
                                for (let exc of globalRates) {
                                    if (e.value === exc.rate_cur_id1 && kit.it_cur_id !== e.value) {
                                        newPrice = Math.round(((newPrice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                        it.iit_discountamount = newPrice * it.iit_qty * it.iit_discountperc / 100;
                                        it.iit_total = newPrice * it.iit_qty - it.iit_discountamount;
                                        continue;
                                    } else if (e.value === exc.rate_cur_id2 && kit.it_cur_id !== e.value) {
                                        newPrice = Math.round(((newPrice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                        it.iit_discountamount = newPrice * it.iit_qty * it.iit_discountperc / 100;
                                        it.iit_total = newPrice * it.iit_qty - it.iit_discountamount;
                                        continue;
                                    } else if (kit.it_cur_id === exc.rate_cur_id2 && kit.it_cur_id !== e.value) {
                                        newPrice = Math.round(((newPrice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                        it.iit_discountamount = newPrice * it.iit_qty * it.iit_discountperc / 100;
                                        it.iit_total = newPrice * it.iit_qty - it.iit_discountamount;
                                        continue;
                                    } else if (kit.it_cur_id === exc.rate_cur_id1 && kit.it_cur_id !== e.value) {
                                        newPrice = Math.round(((newPrice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                        it.iit_discountamount = newPrice * it.iit_qty * it.iit_discountperc / 100;
                                        it.iit_total = newPrice * it.iit_qty - it.iit_discountamount;
                                        continue;
                                    }
                                }
                            }

                            it.iit_it_price = newPrice;
                        }
                        newIt.push(it);
                        break;
                    }
                }
            }
            setInvoiceItem([...newIt]);
        }
    }

    useEffect(() => {
        if (data !== null && data !== undefined && data !== "" && data.toPage === "Invoice") {
            setDiscperc(data.inv_discountperc);
            setCurr(data.inv_cur_id);
            setDiscAmt(data.inv_discountamount);
            setSubTotal(data.inv_subtotal);
            setTotalTax(data.inv_totaltax);
            setNote(data.inv_note);
            setBranch(data.inv_bra_id);
            setProject(data.inv_prj_id);
            setWare(data.inv_wa_id);
            setThirdParties(data.inv_tp_id);
            setSwitchPaid(data.inv_paid);
            setChange(data.inv_change);
            setSwitchDelivered(data.inv_delivered);
            setTransactionType(data.inv_tt_code);
            setDueDate(data.inv_duedate);
            setId(data.inv_id);
        }
    }, []);

    const getID = (e) => {
        setIsLoading(true);
        if (e.value !== null && e.value !== undefined && e.value !== "") {
            getInvoiceItems(e.value).then((x) => {
                for (let row of x) {
                    row.iit_total = row.iit_it_price * row.iit_qty - row.iit_discountamount;
                }

                for (let inv of invoice) {
                    if (e.value === inv.inv_id) {
                        setDiscperc(inv.inv_discountperc);
                        setCurr(inv.inv_cur_id);
                        setDiscAmt(inv.inv_discountamount);
                        setTransactionType(inv.inv_tt_code);
                        setSubTotal(inv.inv_subtotal);
                        setTotalTax(inv.inv_totaltax);
                        setNote(inv.inv_note);
                        setBranch(inv.inv_bra_id);
                        setProject(inv.inv_prj_id);
                        setWare(inv.inv_wa_id);
                        setThirdParties(inv.inv_tp_id);
                        setSwitchPaid(inv.inv_paid);
                        setChange(inv.inv_change);
                        setSwitchDelivered(inv.inv_delivered);
                        setDueDate(inv.inv_duedate);
                        setTrxReport(inv, x);
                        break;
                    }
                }
                setInvoiceItem(x);
            }, setIsLoading(false));
        }
        else {
            setInvoiceItem([]);
            setIsLoading(false);
        }
        setId(e.value);
    }

    const openPopup = () => {
        setPopup(true);
    };

    const handleSwitchPaid = (e) => {
        if (e.target.checked) {
            setSwitchPaid(1);
        } else {
            setSwitchPaid(0);
        }
    }

    const handleSwitchDelivered = (e) => {
        if (e.target.checked) {
            setSwitchDelivered(1);
        } else {
            setSwitchDelivered(0);
        }
    }

    const setTP = (e) => {
        if (e.value === null) {
            setTempInvoice(invoice);
            onReset();
        } else if (e.value !== null && thirdParties === null) {
            setThirdParties(e.value);
            const invId = invoiceTransTypes.find((tt) => tt.tt_type === 'Sale Invoice')?.tt_code;
            setTransactionType(invId);
            const newInv = [];
            for (let trxnb of invoice) {
                if (trxnb.inv_tp_id === e.value) {
                    newInv.push(trxnb);
                }
            }
            setTempInvoice(newInv);
        } else if (e.value !== null && thirdParties !== null) {
            onReset();
            setThirdParties(e.value);
            const invId = invoiceTransTypes.find((tt) => tt.tt_type === 'Sale Invoice')?.tt_code;
            setTransactionType(invId);
            const newInv = [];
            for (let trxnb of invoice) {
                if (trxnb.inv_tp_id === e.value) {
                    newInv.push(trxnb);
                }
            }
            setTempInvoice(newInv);
        }
    }

    const onReset = () => {
        setInvoiceItem([]);
        setCurr(globalFirstCur);
        setId(null);
        setDiscperc(null);
        setCurr(globalFirstCur);
        setTotalOne(0);
        setDiscAmt(null);
        setSubTotal(null);
        setTotalTax(null);
        setNote('');
        setBranch(branches?.current[1].bra_id);
        setProject(projects?.current[1].prj_id);
        setWare(warehouse?.current[1].wa_id);
        setThirdParties(null);
        setTransactionType(null);
        setSwitchPaid(1);
        setChange(null);
        setSwitchDelivered(1);
        setDueDate(null);
        datagridRef.current.instance.cancelEditData();
    }

    const inv_item_row = {
        iit_inv_id: "",
        iit_it_id: "",
        iit_it_price: "",
        iit_qty: "",
        iit_discountperc: "",
        iit_discountamount: "",
        iit_vat: "",
        iit_wa_id: "",
        iit_note: "",
        iit_user_id: "",
        iit_div_id: "",
        iit_tax1: "",
        iit_tax2: "",
        iit_total: "",
    }

    const onInitNewRow = (e) => {
        e.data.iit_it_price = 0;
        e.data.iit_qty = 1;
        e.data.iit_discountperc = 0;
        e.data.iit_discountamount = 0;
        e.data.iit_vat = 0;
        e.data.iit_tax1 = 0;
        e.data.iit_tax2 = 0;
        e.data.iit_total = 0;
    }

    const printPriview = () => {
        if (id !== null) {
            setViewerPopup(true);
        } else {
            swal("Info", "Please select an Invoice Number to print.", "info");
            return;
        }
    }

    const setTrxReport = (item, itemss) => {
        const dispReceipts = JSON.parse(JSON.stringify(itemss));

        const rows = dispReceipts.map((it, i) => {
            const idname = items.current.find((id) => id.it_id === it.iit_it_id)?.it_name;
            return (
                `<tr>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ idname + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ it.iit_it_price + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ it.iit_qty + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ it.iit_discountamount + `</td>
              <td style="border-top-color: black; border-top-style: solid;">`+ it.iit_total + `</td>
            </tr>`
            )
        })

        const table = `
            <tr class="invoice-items-table-header">
                <th style="border-right-color: black; border-right-style: solid;">Name</th>
                <th style="border-right-color: black; border-right-style: solid;">Price</th>
                <th style="border-right-color: black; border-right-style: solid;">Quantity</th>
                <th style="border-right-color: black; border-right-style: solid;">Discount</th>
                <th>Total</th>
            </tr>
            ${rows}
        `;

        fetch("/assets/reports/invoice.html").then(result => {
            return result.text();
        }).then(page => {
            var mapObj = {
                companyaddressvalue: company[0]?.cmp_address === null || company[0]?.cmp_address === "" ? " " : company[0]?.cmp_address,
                companylogovalue: "/dataharbor.png",
                companyphonevalue: company[0]?.cmp_phonenumber === null || company[0]?.cmp_phonenumber === "" ? " " : company[0]?.cmp_phonenumber,
                clientNameData: thirdParty.current.find((np) => np.tp_id === invoice[0]?.inv_tp_id)?.tp_name,
                companyBranchData: branches.current.find((ba) => ba.bra_id === invoice[0]?.inv_bra_id)?.bra_name,
                companyInvoiceDueDate: convertDateOnly(invoice[0]?.inv_duedate),
                companyInvoiceNumber: invoice[0]?.inv_trxnb,
                invoiceOrderNumber: invoice[0]?.inv_trxnb,
                invoiceDateCreated: convertDateOnly(invoice[0]?.inv_createddate),
                billingTPAddress: thirdParty.current.find((np) => np.tp_id === invoice[0]?.inv_tp_id)?.tp_address,
                tablepurchasevalue: table,
                invoiceTotalPrice: "$" + item.inv_total,
                invoiceSubTotalPrice: "$" + item.inv_subtotal,
                invoiceTotalTaxPrice: "$" + item.inv_totaltax,
            };
            const newPage = page.replace(/billingTPAddress|invoiceDateCreated|invoiceOrderNumber|companyInvoiceNumber|companyInvoiceDueDate|companyBranchData|invoiceTotalTaxPrice|invoiceSubTotalPrice|invoiceTotalPrice|clientNameData|tablepurchasevalue|companyphonevalue|companylogovalue|companyaddressvalue/gi,
                (matched) => mapObj[matched]);
            setReport(newPage);
        });
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const displayTypeTrxnb = (option) => {
        if (option !== null) {
            for (let tt of globalTransTypes) {
                if (tt.tt_code === option.inv_tt_code) {
                    return tt.tt_newcode + "-" + option.inv_trxnb;
                }
            }
        }
        return "";
    }

    useEffect(() => {

        var gross = 0;
        var totalTax = 0;
        var totalTax1 = 0;
        var totalTax2 = 0;

        for (let row of invoiceItem) {
            if (row.iit_qty && row.iit_it_price) {
                gross = gross + (row.iit_qty * row.iit_it_price - row.iit_discountamount);
            }
            if (row.iit_vat && row.iit_total) {
                totalTax = totalTax + (row.iit_vat * row.iit_total / 100);
            }

            if (row.iit_tax1 && row.iit_total) {
                totalTax1 = totalTax1 + (row.iit_tax1 * row.iit_total / 100);
            }

            if (row.iit_tax2 && row.iit_total) {
                totalTax2 = totalTax2 + (row.iit_tax2 * row.iit_total / 100);
            }
        }
        setDiscAmt(gross * discperc / 100);
        setSubTotal(gross);
        setTotalTax(roundNumber((totalTax - totalTax * discperc / 100) + (totalTax1 - totalTax1 * discperc / 100) + (totalTax2 - totalTax2 * discperc / 100)));

        var st1 = gross - (gross * discperc / 100) + (roundNumber((totalTax - totalTax * discperc / 100) + (totalTax1 - totalTax1 * discperc / 100) + (totalTax2 - totalTax2 * discperc / 100)))

        for (let cur of globalRates) {
            if (curr === globalFirstCur) {
                if (curr === cur.rate_cur_id1 && globalSecondCur === cur.rate_cur_id2) {
                    setTotalOne(st1 * cur.rate_rate);
                    break;
                } else if (globalSecondCur === cur.rate_cur_id1 && curr === cur.rate_cur_id2) {
                    setTotalOne(st1 / cur.rate_rate);
                    break;
                }
            }
            else if (curr === cur.rate_cur_id1 && globalFirstCur === cur.rate_cur_id2) {
                setTotalOne(st1 * cur.rate_rate);
                break;
            } else if (globalFirstCur === cur.rate_cur_id1 && curr === cur.rate_cur_id2) {
                setTotalOne(st1 / cur.rate_rate);
                break;
            }
        }

    }, [invoiceItem])

    const onEditiorPreparing = (e) => {

        if (e.dataField === "iit_discountperc" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0 || args.value > 100) {
                    swal("Error", "Value must be between 0 and 100", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                let qty = datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_qty");
                let price = datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_it_price");

                // calculate discAmt
                datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_discountamount", price * qty * args.value / 100);
                // calculate row total
                datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_total",
                    qty * price - datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_discountamount")
                );

                datagridRef.current.instance.saveEditData();
                setInvoiceItem([...invoiceItem]);

            }
        }

        if (e.dataField === "iit_discountamount" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                let price = datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_it_price");
                let qty = datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_qty");
                if (args.value < 0 || args.value > (price * qty)) {
                    swal("Error", "Value must be between 0 and " + (price * qty), "error");
                    return;
                }
                defaultValueChangeHandler(args);

                // calculate discPerc
                datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_discountperc",
                    ((args.value / (price * qty)) * 100));

                // calculate row total
                datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_total",
                    qty * price - args.value);

                datagridRef.current.instance.saveEditData();
                setInvoiceItem([...invoiceItem]);
            }
        }

        if (e.dataField === "iit_it_price" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value cannot be less than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                // calculate discAmt
                datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_discountamount",
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_qty") * datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_discountperc") * args.value / 100
                );

                // calculate row total
                datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_total",
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_qty") * args.value - datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_discountamount")
                );

                datagridRef.current.instance.saveEditData();
                setInvoiceItem([...invoiceItem]);
            }
        }

        if (e.dataField === "iit_qty" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value cannot be less than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                let price = datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_it_price");

                // calculate discAmt
                datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_discountamount",
                    args.value * datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_discountperc") * price / 100
                );

                // calculate row total
                datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_total",
                    price * args.value - datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_discountamount")
                );

                datagridRef.current.instance.saveEditData();
                setInvoiceItem([...invoiceItem]);
            }
        }

        if (e.dataField === "iit_vat" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value cannot be less than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                datagridRef.current.instance.saveEditData();
                setInvoiceItem([...invoiceItem]);

            }
        }

        if (e.dataField === "iit_tax1" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value cannot be less than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                datagridRef.current.instance.saveEditData();
                setInvoiceItem([...invoiceItem]);
            }
        }

        if (e.dataField === "iit_tax2" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value cannot be less than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                datagridRef.current.instance.saveEditData();
                setInvoiceItem([...invoiceItem]);
            }
        }

        if (e.dataField === "iit_div_id" && e.parentType === "dataRow") {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                defaultValueChangeHandler(args);

                datagridRef.current.instance.saveEditData();
                setInvoiceItem([...invoiceItem]);
            }
        }

        if (e.dataField === "iit_wa_id" && e.parentType === "dataRow") {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                defaultValueChangeHandler(args);

                datagridRef.current.instance.saveEditData();
                setInvoiceItem([...invoiceItem]);
            }
        }

        if (e.dataField === "iit_note" && e.parentType === "dataRow") {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                defaultValueChangeHandler(args);

                datagridRef.current.instance.saveEditData();
                setInvoiceItem([...invoiceItem]);
            }
        }

        if (e.dataField === "iit_it_id" && e.parentType === "dataRow") {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value === null) {
                    swal("Error", "Please select an Item", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                for (let i of items.current) {
                    if (i.it_id === args.value) {
                        var newPrice = i.it_unitprice;
                        for (let exc of globalRates) {
                            if (i.it_cur_id === exc.rate_cur_id2 && curr === exc.rate_cur_id1) {
                                newPrice = Math.round(((i.it_unitprice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                break;
                            } else if (i.it_cur_id === exc.rate_cur_id1 && curr === exc.rate_cur_id2) {
                                newPrice = Math.round(((i.it_unitprice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                break;
                            }
                        }

                        if (newPrice === i.it_unitprice) {
                            for (let exc of globalRates) {
                                if (curr === exc.rate_cur_id1 && i.it_cur_id !== curr) {
                                    newPrice = Math.round(((newPrice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                    continue;
                                } else if (curr === exc.rate_cur_id2 && i.it_cur_id !== curr) {
                                    newPrice = Math.round(((newPrice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                    continue;
                                } else if (i.it_cur_id === exc.rate_cur_id2 && i.it_cur_id !== curr) {
                                    newPrice = Math.round(((newPrice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                    continue;
                                } else if (i.it_cur_id === exc.rate_cur_id1 && i.it_cur_id !== curr) {
                                    newPrice = Math.round(((newPrice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                    continue;
                                }
                            }
                        }

                        datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_it_price", newPrice);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_vat", i.it_tax);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_tax1", i.it_tax1);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_tax2", i.it_tax2);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_total", newPrice);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_note", i.it_note);
                        for (let w of warehouse.current) {
                            if (w.wa_id === i.it_wa_id) {
                                datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_wa_id", w.wa_id);
                                break;
                            }
                        }
                        for (let d of division.current) {
                            if (d.div_id === i.it_div_id) {
                                datagridRef.current.instance.cellValue(e.row.rowIndex, "iit_div_id", d.div_id);
                                break;
                            }
                        }
                    }
                }
                datagridRef.current.instance.saveEditData();
                setInvoiceItem([...invoiceItem]);
            }
        }


    }

    const roundNumber = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
    const amount = (e) => {
        if (e > 0 && e <= subTotal) {
            setDiscAmt(e);
            setDiscperc((e / subTotal) * 100);
        } else
            if (e <= 0) {
                setDiscAmt(0);
                setDiscperc(0);
            }
        setInvoiceItem([...invoiceItem]);
    }


    const percent = (e) => {
        if (e > 0 && e <= 100) {
            setDiscperc(e);
            setDiscAmt(e * subTotal / 100);
        } else
            if (e <= 0) {
                setDiscAmt(0);
                setDiscperc(0);
            }
        setInvoiceItem([...invoiceItem]);
    }

    const deleteInvoice = (e) => {
        if (id !== null) {
            const data = [];
            data.push(id);
            delInvoice(data);
        } else {
            swal("Info", "Please select an Invoice Number to delete.", "info");
            return;
        }
    }

    const saveInvoice = (e) => {
        if (thirdParties === null || thirdParties === undefined || thirdParties === "") {
            swal("Info", "Please select a Client.", "info")
            return;
        }

        let rows = datagridRef.current.instance.getVisibleRows();
        let itid = datagridRef.current.instance.cellValue(0, "iit_it_id");

        if (rows.length === 0) {
            swal("Error", "Please add at least 1 item!", "error")
            return;
        }

        if (itid === undefined) {
            swal("Error", "Please add at least 1 item!", "error")
            return;
        }


        if (id === null || id === undefined || id === "") {
            const params = {};

            params.inv_cmp_id = globalCmpId;
            params.inv_duedate = dueDate;
            params.inv_tt_code = trancactionType;
            params.inv_trxnb = null;
            params.inv_cur_id = curr;
            params.inv_discountperc = roundNumber(discperc);
            params.inv_discountamount = roundNumber(discamt);
            params.inv_wa_id = ware;
            params.inv_bra_id = branch;
            params.inv_prj_id = project;
            params.inv_note = note;
            params.inv_tp_id = thirdParties;
            params.inv_paid = switchPaid;
            params.inv_subtotal = roundNumber(subTotal);
            params.inv_totaltax = roundNumber(TotalTax);
            params.inv_total = roundNumber(total);
            params.inv_total1 = roundNumber(totalOne);
            params.inv_user_id = globalUsrId;
            params.inv_change = roundNumber(change);
            params.inv_docurl = "";
            params.inv_delivered = switchDelivered;

            const data = [];
            data.push(params);

            const iparams = [];
            for (let item of rows) {
                const param = {};
                param.iit_it_id = item.data.iit_it_id;
                param.iit_it_price = item.data.iit_it_price;
                param.iit_qty = item.data.iit_qty;
                param.iit_discountperc = item.data.iit_discountperc;
                param.iit_discountamount = item.data.iit_discountamount;
                param.iit_vat = item.data.iit_tax;
                param.iit_wa_id = item.data.iit_wa_id;
                param.iit_note = item.data.iit_note;
                param.iit_user_id = globalUsrId;
                param.iit_div_id = item.data.iit_div_id;
                param.iit_tax1 = item.data.iit_tax1;
                param.iit_tax2 = item.data.iit_tax2;
                iparams.push(param);
            };
            addInvoice(data, iparams);
        } else {

            let rowdel = datagridRef.current.instance.getVisibleRows();
            var delGross = 0;
            var delTotalTax = 0;
            var delTotalTax1 = 0;
            var delTotalTax2 = 0;

            for (let row of rowdel) {
                if (row.removed) {
                    if (row.data.iit_qty && row.data.iit_it_price) {
                        delGross = delGross + (row.data.iit_qty * row.data.iit_it_price - row.data.iit_discountamount);
                    }

                    if (row.iit_vat && row.iit_total) {
                        delTotalTax = delTotalTax + (row.iit_vat * row.iit_total / 100);
                    }

                    if (row.iit_tax1 && row.iit_total) {
                        delTotalTax1 = delTotalTax1 + (row.iit_tax1 * row.iit_total / 100);
                    }

                    if (row.iit_tax2 && row.iit_total) {
                        delTotalTax2 = delTotalTax2 + (row.iit_tax2 * row.iit_total / 100);
                    }
                }
            }

            const params = {};
            params.inv_id = id;
            params.inv_cmp_id = globalCmpId;
            params.inv_duedate = dueDate;
            params.inv_tt_code = trancactionType;
            params.inv_trxnb = invoice.filter((option) => option.inv_id === id)[0].inv_trxnb;
            params.inv_cur_id = curr;
            params.inv_discountperc = roundNumber(discperc);
            params.inv_discountamount = roundNumber(discamt - (delGross * discperc / 100));
            params.inv_wa_id = ware;
            params.inv_bra_id = branch;
            params.inv_prj_id = project;
            params.inv_note = note;
            params.inv_tp_id = thirdParties;
            params.inv_paid = switchPaid;
            params.inv_subtotal = roundNumber(subTotal - delGross);
            params.inv_totaltax = roundNumber(TotalTax - delTotalTax - delTotalTax1 - delTotalTax2);
            params.inv_total = roundNumber(total - delGross - delTotalTax - delTotalTax1 - delTotalTax2 + (delGross * discperc / 100));
            params.inv_total1 = roundNumber(totalOne - delGross - delTotalTax - delTotalTax1 - delTotalTax2 + (delGross * discperc / 100));
            params.inv_user_id = globalUsrId;
            params.inv_change = roundNumber(change);
            params.inv_docurl = "";
            params.inv_delivered = switchDelivered;

            const data = [];
            data.push(params);
            updInvoice(data);

            let rows = datagridRef.current.instance.getVisibleRows();
            const updparams = [];
            const delparams = [];
            const addparams = [];
            for (let row of rows) {
                if (row.removed) {
                    delparams.push(row.data.iit_id);
                    continue;
                }
                if (row.data.iit_inv_id) {
                    const param = {};
                    param.iit_id = row.data.iit_id;
                    param.iit_inv_id = row.data.iit_inv_id;
                    param.iit_it_id = row.data.iit_it_id;
                    param.iit_it_price = row.data.iit_it_price;
                    param.iit_qty = row.data.iit_qty;
                    param.iit_discountperc = row.data.iit_discountperc;
                    param.iit_discountamount = row.data.iit_discountamount;
                    param.iit_vat = row.data.iit_tax;
                    param.iit_wa_id = row.data.iit_wa_id;
                    param.iit_note = row.data.iit_note;
                    param.iit_user_id = globalUsrId;
                    param.iit_div_id = row.data.iit_div_id;
                    param.iit_tax1 = row.data.iit_tax1;
                    param.iit_tax2 = row.data.iit_tax2;
                    updparams.push(param);
                } else {
                    const param = {};
                    param.iit_it_id = row.data.iit_it_id;
                    param.iit_it_price = row.data.iit_it_price;
                    param.iit_qty = row.data.iit_qty;
                    param.iit_discountperc = row.data.iit_discountperc;
                    param.iit_discountamount = row.data.iit_discountamount;
                    param.iit_vat = row.data.iit_tax;
                    param.iit_wa_id = row.data.iit_wa_id;
                    param.iit_note = row.data.iit_note;
                    param.iit_user_id = globalUsrId;
                    param.iit_div_id = row.data.iit_div_id;
                    param.iit_tax1 = row.data.iit_tax1;
                    param.iit_tax2 = row.data.iit_tax2;
                    addparams.push(param);
                }
            };

            if (delparams.length > 0) {
                delInvoiceItem(delparams);
            }
            if (addparams.length > 0) {
                addInvoiceItem(addparams, id);
            }
            if (updparams.length > 0) {
                updInvoiceItems(updparams);
            }
        }
    }

    const addInvoice = (params, iparams) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}invoices/add_invoice`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    addInvoiceItem(iparams, response.inv_id[0][0].inv_id);
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                getUpdatedInvoiceItem();
                setIsLoading(false);
            });
    }

    const addInvoiceItem = (params, id) => {
        for (let it of params)
            it.iit_inv_id = id;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}invoiceitems/add_invoiceitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).catch((err) => console.log(err));
    }

    const updInvoice = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}invoices/upd_invoice`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                getUpdatedInvoiceItem();
                setIsLoading(false);
            });
    };

    const updInvoiceItems = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}invoiceitems/upd_invoiceitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    }

    const delInvoice = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}invoices/del_invoice`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                getUpdatedInvoiceItem();
                setIsLoading(false);
            });
    };

    const delInvoiceItem = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}invoiceitems/del_invoiceitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const formatOptions = {
        type: "fixedPoint",
        precision: 2
    };

    const renderColumns = () => {
        const res = [];

        for (var key in inv_item_row) {
            if (inv_item_row.hasOwnProperty(key)) {
                if ("iit_inv_id" === key) {
                    res.push(
                        <Column
                            dataField="iit_inv_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("iit_it_id" === key) {
                    res.push(
                        <Column
                            dataField="iit_it_id"
                            key={key}
                            visible={true}
                            caption="Item"
                        >
                            <Lookup dataSource={items.current} displayExpr="it_name" valueExpr="it_id" />
                        </Column>
                    );
                } else if ("iit_it_price" === key) {
                    res.push(
                        <Column
                            dataField="iit_it_price"
                            key={key}
                            caption="Price"
                            visible={true}
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("iit_qty" === key) {
                    res.push(
                        <Column
                            dataField="iit_qty"
                            key={key}
                            visible={true}
                            caption="Quantity"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("iit_discountperc" === key) {
                    res.push(
                        <Column
                            dataField="iit_discountperc"
                            key={key}
                            caption="Disc %"
                            visible={true}
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("iit_discountamount" === key) {
                    res.push(
                        <Column
                            dataField="iit_discountamount"
                            key={key}
                            visible={true}
                            caption="Disc Amount"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("iit_vat" === key) {
                    res.push(
                        <Column
                            dataField="iit_vat"
                            key={key}
                            visible={true}
                            caption="Vat"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("iit_wa_id" === key) {
                    res.push(
                        <Column
                            dataField="iit_wa_id"
                            key={key}
                            visible={true}
                            caption="Warehouse"
                        >
                            <Lookup displayExpr="wa_name" valueExpr="wa_id" dataSource={warehouse.current} />
                        </Column>
                    );
                } else if ("iit_note" === key) {
                    res.push(
                        <Column
                            dataField="iit_note"
                            key={key}
                            visible={true}
                            caption="Note"
                            dataType="string"
                        ></Column>
                    );
                } else if ("iit_user_id" === key) {
                    res.push(
                        <Column
                            dataField="iit_user_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("iit_div_id" === key) {
                    res.push(
                        <Column
                            dataField="iit_div_id"
                            key={key}
                            visible={true}
                            caption="Division"
                        >
                            <Lookup displayExpr="div_name" valueExpr="div_id" dataSource={division.current} />
                        </Column>
                    );
                } else if ("iit_tax1" === key) {
                    res.push(
                        <Column
                            dataField="iit_tax1"
                            key={key}
                            visible={true}
                            caption="Tax 1"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("iit_tax2" === key) {
                    res.push(
                        <Column
                            dataField="iit_tax2"
                            key={key}
                            visible={true}
                            caption="Tax 2"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("iit_total" === key) {
                    res.push(
                        <Column
                            dataField="iit_total"
                            key={key}
                            visible={true}
                            caption="Total"
                            allowEditing={false}
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <div className="groups">
                    <div className="groups-btns">
                        <Button
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                            text="Advanced Options"
                            onClick={openPopup}
                        />
                        <Button
                            text="Save"
                            width={100}
                            onClick={saveInvoice}
                        />
                        <Button
                            text="Reset"
                            width={100}
                            onClick={onReset}
                        />
                        <Button
                            text="Print"
                            width={100}
                            onClick={printPriview}
                        />
                        <Button
                            text="Delete"
                            width={100}
                            onClick={deleteInvoice}
                        />
                    </div>
                </div>
                <div className="maingroup">
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Client</InputLabel>
                        <SelectBox
                            dataSource={thirdParty.current}
                            displayExpr="tp_name"
                            valueExpr="tp_id"
                            placeholder="Client"
                            value={thirdParties}
                            onValueChanged={setTP}
                            width={200}
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'} >Transaction Type</InputLabel>
                        <SelectBox
                            dataSource={invoiceTransTypes}
                            displayExpr="tt_type"
                            valueExpr="tt_code"
                            placeholder="Transaction Type"
                            onValueChanged={(e) => setTransactionType(e.value)}
                            value={trancactionType}
                            width={200}
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Invoice Number</InputLabel>
                        <SelectBox
                            dataSource={tempInvoice}
                            displayExpr={displayTypeTrxnb}
                            valueExpr="inv_id"
                            placeholder="Invoice"
                            onValueChanged={getID}
                            value={id}
                            width={200}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Discount %</InputLabel>
                        <NumberBox
                            showClearButton
                            showSpinButtons={false}
                            min={0}
                            max={100}
                            placeholder="Discount %"
                            onValueChange={percent}
                            value={roundNumber(discperc)}
                            width={200}
                        />
                        <InputLabel sx={{ color: "white" }}>Discount Amount</InputLabel>
                        <NumberBox
                            showClearButton
                            showSpinButtons={false}
                            min={0}
                            placeholder="Discount Amount"
                            onValueChange={amount}
                            value={roundNumber(discamt)}
                            width={200}
                        />
                        <InputLabel sx={{ color: "white" }}>Total Tax</InputLabel>
                        <NumberBox
                            showSpinButtons={false}
                            min={0}
                            placeholder="Total Tax"
                            value={TotalTax}
                            width={200}
                            readOnly
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Sub Total</InputLabel>
                        <NumberBox
                            showSpinButtons={false}
                            min={0}
                            placeholder="Sub Total"
                            value={roundNumber(subTotal)}
                            width={200}
                            readOnly
                        />
                        <InputLabel sx={{ color: "white" }}>Total</InputLabel>
                        <NumberBox
                            showSpinButtons={false}
                            min={0}
                            placeholder="Total"
                            value={roundNumber(total)}
                            width={200}
                            readOnly
                        />
                        <InputLabel sx={{ color: "white" }}>Total 1</InputLabel>
                        <NumberBox
                            showSpinButtons={false}
                            min={0}
                            placeholder="Total 1"
                            value={roundNumber(totalOne)}
                            width={200}
                            readOnly
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Note</InputLabel>
                        <TextBox
                            value={note}
                            placeholder="Note"
                            onValueChanged={(e) => setNote(e.value)}
                            width={200}
                        />
                    </div>
                </div>
                <div>
                    <Popup
                        visible={popup}
                        onHiding={() => setPopup(false)}
                        dragEnabled={false}
                        showCloseButton={true}
                        showTitle={true}
                        width="auto"
                        height="auto"
                    >
                        <Position
                            at="center"
                            my="center"
                        />
                        <ToolbarItem
                            text="Advanced Options"
                            location="before">
                        </ToolbarItem>
                        <div className="groups-popup">
                            <InputLabel sx={{ color: "black" }}>Warehouse</InputLabel>
                            <SelectBox
                                dataSource={warehouse.current}
                                displayExpr="wa_name"
                                valueExpr="wa_id"
                                placeholder="Warehouse"
                                onValueChanged={(e) => setWare(e.value)}
                                value={ware}
                                width={200}
                            />
                            <InputLabel sx={{ color: "black" }}>Project</InputLabel>
                            <SelectBox
                                dataSource={projects.current}
                                displayExpr="prj_name"
                                valueExpr="prj_id"
                                placeholder="Project"
                                onValueChanged={(e) => setProject(e.value)}
                                value={project}
                                width={200}
                            />
                            <InputLabel sx={{ color: "black" }}>Branch</InputLabel>
                            <SelectBox
                                dataSource={branches.current}
                                displayExpr="bra_name"
                                valueExpr="bra_id"
                                placeholder="Branches"
                                onValueChanged={(e) => setBranch(e.value)}
                                value={branch}
                                width={200}
                            />
                        </div>
                        <div className="groups-popup">
                            <InputLabel sx={{ color: "black" }}>Currency</InputLabel>
                            <SelectBox
                                dataSource={currency}
                                displayExpr="cur_code"
                                valueExpr="cur_id"
                                placeholder="Currency"
                                onValueChanged={currencyChange}
                                defaultValue={globalFirstCur}
                                value={curr}
                                width={200}
                            />
                            <InputLabel sx={{ color: "black" }} >Change</InputLabel>
                            <NumberBox
                                showClearButton
                                showSpinButtons={false}
                                min={0}
                                placeholder="Change"
                                onValueChanged={(e) => setChange(e.value)}
                                value={change}
                                width={200}
                            />
                            <InputLabel sx={{ color: "black" }}>Due Date</InputLabel>
                            <DateBox
                                value={dueDate}
                                placeholder="Due Date"
                                onValueChanged={(e) => setDueDate(e.value)}
                                width={200}
                                type="datetime"
                            />
                        </div>
                        <div className="groups">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Not Paid</Typography>
                                <Switch defaultChecked value={switchPaid} onChange={(e) => handleSwitchPaid(e)} />
                                <Typography>Paid</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Not Delivered</Typography>
                                <Switch defaultChecked value={switchDelivered} onChange={(e) => handleSwitchDelivered(e)} />
                                <Typography>Delivered</Typography>
                            </Stack>
                        </div>
                    </Popup>
                </div>
                <div>
                    <Dialog
                        open={viewerPopup}
                        TransitionComponent={Transition}
                        keepMounted
                        fullWidth
                        maxWidth="md"
                        onClose={() => setViewerPopup(false)}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <DialogTitle>Invoice</DialogTitle>
                            <Button className="button in_button2" onClick={handlePrint}>Print</Button>
                            <div className="me-4 cursor" onClick={() => setViewerPopup(false)}>
                                <ClearIcon />
                            </div>
                        </div>
                        <div ref={componentRef} dangerouslySetInnerHTML={{ __html: report }} />
                    </Dialog>
                </div>
                <div style={{ marginTop: 14 }}></div>
                <DataGrid
                    ref={datagridRef}
                    onInitNewRow={onInitNewRow}
                    className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    onEditorPreparing={onEditiorPreparing}
                    dataSource={invoiceItem}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <Toolbar>
                        <Item location="after" visible={thirdParties !== null} name="addRowButton" />
                        <Item location="after" visible={false} name="saveButton" />
                        <Item location="after" visible={true} name="revertButton" />
                        <Item location="after" visible={true} name="searchPanel" />
                        <Item location="before" visible={true} name="groupPanel" />
                        <Item location="before" visible={true} name="columnChooserButton" />
                    </Toolbar>
                    <FilterBuilder defaultFields={inv_item_row} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default Invoice;