import './Profile.css'
import '../../GlobalCSS.css'
import { TextField, Button, Stack, RadioGroup, FormControlLabel, Radio, InputLabel, Checkbox, Autocomplete, useMediaQuery } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Fragment, useState, useEffect } from 'react';
import swal from "sweetalert";
import * as global from '../../utils/globals';
import { store } from "../../store";
import { getRoles, getPosition, getDepartment, getProfile } from "../../utils/helper";
var languages = require('language-list')();

const Profile = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalProfile = store.getState().mainReducer.pro_id;
    const globalUserType = store.getState().mainReducer.usr_type;
    const isAdmin = (globalUserType === global.owner_id || globalUserType === global.admin_id);

    const [gRole, setGRole] = useState([]);
    const [gPosition, setGPosition] = useState([]);
    const [gDepartment, setGDepartment] = useState([]);
    const minW = useMediaQuery('(min-width:600px)');
    const maxW = useMediaQuery('(max-width:900px)');

    //Get data from useEffect
    useEffect(() => {
        setIsLoading(true);
        getRoles(globalCmpId).then((r) => {
            setGRole(r);
            getDepartment(globalCmpId).then((d) => {
                setGDepartment(d);
                getPosition(globalCmpId).then((p) => {
                    setGPosition(p);
                    getProfile(globalProfile, globalCmpId).then((x) => {
                        let pos = null;
                        let dep = null;
                        let rol = null;

                        for (let role of r) {
                            if (role.ro_id === x[0].pro_role) {
                                rol = role;
                                break;
                            }
                        }

                        for (let position of p) {
                            if (position.po_id === x[0].pro_position) {
                                pos = position;
                                break;
                            }
                        }

                        for (let department of d) {
                            if (department.dep_id === x[0].pro_department) {
                                dep = department;
                                break;
                            }
                        }

                        const table = [];
                        const langTable = x[0].pro_language.split(',');
                        for (let lang of langTable) {
                            var code = languages.getLanguageCode(lang);
                            let language = { code: code, language: lang }
                            table.push(language);
                        }
                        setFormData({
                            pro_id: x[0].pro_id,
                            pro_firstname: x[0].pro_firstname,
                            pro_lastname: x[0].pro_lastname,
                            pro_cmp_id: x[0].pro_cmp_id,
                            pro_date_created: x[0].pro_date_created,
                            pro_gender: x[0].pro_gender.trim(),
                            pro_email: x[0].pro_email,
                            pro_address: x[0].pro_address,
                            pro_phonenumber: x[0].pro_phonenumber,
                            pro_role: rol,
                            pro_position: pos,
                            pro_work_location: x[0].pro_work_location,
                            pro_language: null,
                            pro_department: dep,
                            pro_color: x[0].pro_color,
                            language: table,
                        });
                    });
                });
            });
            setIsLoading(false);
        });
    }, []);

    const [formData, setFormData] = useState({
        pro_id: "",
        pro_firstname: "",
        pro_lastname: "",
        pro_cmp_id: "",
        pro_date_created: "",
        pro_gender: "",
        pro_email: "",
        pro_address: "",
        pro_phonenumber: "",
        pro_role: null,
        pro_position: null,
        pro_work_location: "",
        pro_language: null,
        pro_department: null,
        pro_color: "",
        language: [],
    });

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleRoleChange = (e, value) => {
        setFormData({
            ...formData,
            pro_role: value
        });
    }

    const handlePositionChange = (e, value) => {
        setFormData({
            ...formData,
            pro_position: value
        });
    }

    const handleDepartmentChange = (e, value) => {
        setFormData({
            ...formData,
            pro_department: value
        });
    }

    const handleLanguageChange = (e, value) => {
        setFormData({
            ...formData,
            language: value
        });
    }

    const updateProfile = (e) => {
        e.preventDefault();

        const proData = {};
        proData.pro_id = formData.pro_id;
        proData.pro_firstname = formData.pro_firstname;
        proData.pro_lastname = formData.pro_lastname;
        proData.pro_cmp_id = formData.pro_cmp_id;
        proData.pro_email = formData.pro_email;
        proData.pro_gender = formData.pro_gender;
        proData.pro_address = formData.pro_address;
        proData.pro_phonenumber = formData.pro_phonenumber;
        proData.pro_work_location = formData.pro_work_location;
        proData.pro_color = formData.pro_color;
        proData.pro_date_created = formData.pro_date_created;
        proData.pro_department = formData.pro_department.dep_id;
        proData.pro_position = formData.pro_position.po_id;
        proData.pro_role = formData.pro_role.ro_id;

        var strLang = "";
        for (let lan of formData.language) {
            if (lan === formData.language[formData.language.length - 1])
                strLang = strLang + lan.language;
            else strLang = strLang + lan.language + ",";
        }
        proData.pro_language = strLang;

        const data = [];
        data.push(proData);
        updProfile(data);
    }

    const updProfile = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}profiles/upd_profile`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const shifting = (minW, maxW, openDrawer) => {

        let matches = false;

        if (minW && maxW) {
            matches = true;
        }

        if (matches && openDrawer) {
            return 'shiftORight';
        }
        if (matches && !openDrawer) {
            return 'shiftOLeft'
        }
        if (openDrawer) {
            return 'shiftRight';
        }
        return 'shiftLeft'
    }

    return (
        <Fragment>
            <div className='main'>
                <form onSubmit={updateProfile} className={shifting(minW, maxW, openDrawer)}>
                    <div className='outer-border'>
                        <div className='inner-border'>

                            <Stack spacing={2} direction="row" sx={{ mb: 2, mt: 4, ml: 4, mr: 4 }}>
                                <TextField
                                    type="text"
                                    name='pro_firstname'
                                    value={formData.pro_firstname}
                                    variant='outlined'
                                    color='secondary'
                                    label="First Name"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    type="text"
                                    name='pro_lastname'
                                    value={formData.pro_lastname}
                                    variant='outlined'
                                    color='secondary'
                                    label="Last Name"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Stack>
                            <Stack spacing={2} direction="row" sx={{ mb: 2, ml: 4, mr: 4 }}>
                                <TextField
                                    type="number"
                                    name='pro_phonenumber'
                                    value={formData.pro_phonenumber}
                                    variant='outlined'
                                    color='secondary'
                                    label="Phone Number"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    type="text"
                                    name='pro_address'
                                    value={formData.pro_address}
                                    variant='outlined'
                                    color='secondary'
                                    label="Address"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Stack>
                            <Stack spacing={2} direction="row" sx={{ mb: 2, ml: 4, mr: 4 }}>
                                <Autocomplete
                                    options={gRole}
                                    disableCloseOnSelect
                                    value={formData.pro_role || null}
                                    onChange={handleRoleChange}
                                    disabled={!isAdmin}
                                    getOptionLabel={(option) => option.ro_name || option}
                                    isOptionEqualToValue={(option, value) => option.ro_id === value.ro_id}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.ro_name}
                                        </li>
                                    )}
                                    style={{ width: 200 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Roles" name='role' required />
                                    )}
                                />
                                <Autocomplete
                                    options={gPosition}
                                    disableCloseOnSelect
                                    value={formData.pro_position || null}
                                    onChange={handlePositionChange}
                                    disabled={!isAdmin}
                                    getOptionLabel={(option) => option.po_name || option}
                                    isOptionEqualToValue={(option, value) => option.pos_id === value.pos_id}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.po_name}
                                        </li>
                                    )}
                                    style={{ width: 200 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Position" name='position' required />
                                    )}
                                />

                                <Autocomplete
                                    options={gDepartment}
                                    disableCloseOnSelect
                                    value={formData.pro_department || null}
                                    onChange={handleDepartmentChange}
                                    disabled={!isAdmin}
                                    getOptionLabel={(option) => option.dep_name || option}
                                    isOptionEqualToValue={(option, value) => option.dep_id === value.dep_id}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.dep_name}
                                        </li>
                                    )}
                                    style={{ width: 200 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Department" name='department' required />
                                    )}
                                />

                            </Stack>
                            <Autocomplete
                                multiple
                                options={languages.getData()}
                                disableCloseOnSelect
                                value={formData.language || null}
                                onChange={handleLanguageChange}
                                getOptionLabel={(option) => option.language}
                                isOptionEqualToValue={(option, value) => option.code === value.code && option.language === value.language}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.language}
                                    </li>
                                )}
                                sx={{ mb: 4, ml: 4, mr: 4 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Language" name='pro_language' />
                                )}
                            />
                            <Stack spacing={2} direction="row" sx={{ mb: 2, ml: 4, mr: 4 }}>
                                <InputLabel>Work Location</InputLabel>
                                <RadioGroup
                                    name="pro_work_location"
                                    defaultValue="office"
                                    onChange={handleChange}
                                    value={formData.pro_work_location}
                                >
                                    <FormControlLabel value="office" control={<Radio />} label="Office" />
                                    <FormControlLabel value="house" control={<Radio />} label="House" />
                                    <FormControlLabel value="other" control={<Radio />} label="Other" />
                                </RadioGroup>
                                <InputLabel>Gender</InputLabel>
                                <RadioGroup
                                    name="pro_gender"
                                    defaultValue="male"
                                    onChange={handleChange}
                                    value={formData.pro_gender}
                                >
                                    <FormControlLabel value="male" control={<Radio size='small' />} label="Male" />
                                    <FormControlLabel value="female" control={<Radio size='small' />} label="Female" />
                                </RadioGroup>

                            </Stack>
                            <Button type='submit' sx={{ ml: 74, mb: 2, '&:hover': { backgroundColor: "white", color: "#3a0d63" } }} variant="contained">Save</Button>
                        </div>
                    </div>
                </form>
            </div >
        </Fragment >
    );
}

export default Profile;