import * as global from "../../utils/globals";
import swal from "sweetalert";
import { store } from "../../store";
import "../../GlobalCSS.css";
import "./Adjustment.css";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
    RequiredRule,
    Toolbar,
    Item,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import { Button, DateBox, SelectBox, TextArea } from "devextreme-react";
import { InputLabel } from "@mui/material";
import { getDivisions, getWarehouses, getBranches, getProjects, getStockAdjustment, getStockAdjustmentItems, getItems } from "../../utils/helper";
import { useEffect, useRef, useState } from "react";

const Adjustment = ({ openDrawer, setIsLoading }) => {

    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalUsrId = store.getState().mainReducer.usr_id;
    const globalTransTypes = store.getState().mainReducer.transtypes;
    const adjustmentTransTypes = globalTransTypes.filter((tt) => tt.tt_type === 'Stock Adjustment' || tt.tt_type === "Stock Adjustment");

    const [id, setId] = useState(null);
    const [transactionType, setTransactionType] = useState(null);
    const [description, setDescription] = useState("");
    const [date, setDate] = useState(new Date());
    const [valueDate, setValueDate] = useState(null);
    const [source, setSource] = useState(null);
    const [ware, setWare] = useState(null);
    const [bra, setBra] = useState(null);
    const [pro, setPro] = useState(null);
    const [stockAdjustment, setStockAdjustment] = useState([]);
    const [stockAdjustmentItems, setStockAdjustmentItems] = useState([]);
    const warehouse = useRef([]);
    const division = useRef([]);
    const branch = useRef([]);
    const project = useRef([]);
    const item = useRef([]);
    const datagridRef = useRef(null);

    useEffect(() => {
        setIsLoading(true);
        getUpdatedStockAdjustmentItems();
        getDivisions(globalCmpId).then((x) => {
            x.unshift({
                div_id: null,
                div_name: ''
            })
            division.current = x;
        });
        getWarehouses(globalCmpId).then((x) => {
            x.unshift({
                wa_id: null,
                wa_name: ''
            })
            warehouse.current = x;
            setWare(x[1].wa_id);
        });
        getBranches(globalCmpId).then((x) => {
            x.unshift({
                bra_id: null,
                bra_name: ''
            })
            branch.current = x;
            setBra(x[1].bra_id);
        });
        getProjects(globalCmpId).then((x) => {
            x.unshift({
                prj_id: null,
                prj_name: ''
            })
            project.current = x;
            setPro(x[1].prj_id);
        });
        getItems(globalCmpId).then((x) => {
            x.unshift({
                it_id: null,
                it_name: ''
            })
            item.current = x;
            setIsLoading(false);
        });
    }, []);

    const getUpdatedStockAdjustmentItems = () => {
        getStockAdjustment(globalCmpId).then((x) => {
            setStockAdjustment(x);
            setIsLoading(false);
        });
    }

    const getID = (e) => {
        setIsLoading(true);
        if (e.value !== null && e.value !== undefined && e.value !== "") {
            getStockAdjustmentItems(e.value).then((x) =>
                setStockAdjustmentItems(x),
                setIsLoading(false)
            );

            for (let sta of stockAdjustment) {
                if (e.value === sta.sta_id) {
                    setDate(sta.sta_date);
                    setTransactionType(sta.sta_tt_code);
                    setDescription(sta.sta_desc);
                    setValueDate(sta.sta_valuedate);
                    setSource(sta.sta_source);
                    setPro(sta.sta_prj_id);
                    setBra(sta.sta_bra_id);
                    setWare(sta.sta_wa_id);
                    break;
                }
            }
        }
        else {
            setStockAdjustmentItems([]);
            setIsLoading(false);
        }
        setId(e.value);
    }

    const onReset = () => {
        setStockAdjustmentItems([]);
        setDate(new Date());
        setTransactionType(null);
        setDescription("");
        setValueDate(null);
        setSource(null);
        setBra(branch?.current[1].bra_id);
        setWare(warehouse?.current[1].wa_id);
        setPro(project?.current[1].prj_id);
        setId(null);
        datagridRef.current.instance.cancelEditData();
    }

    const deleteStockAdjustment = () => {
        if (id !== null) {
            const data = [];
            data.push(id);
            delStock(data);
        } else {
            swal("Info", "Please select an Transaction Number to delete.", "info");
            return;
        }
    }

    const displayTypeCode = (option) => {
        if (option !== null) {
            for (let tc of globalTransTypes) {
                if (tc.tt_code === option.tt_code) {
                    return tc.tt_newcode + "-" + option.tt_type;
                }
            }
        }
        return "";
    }

    const displayTypeTrxnb = (option) => {
        if (option !== null) {
            for (let tt of globalTransTypes) {
                if (tt.tt_code === option.sta_tt_code) {
                    return tt.tt_newcode + "-" + option.sta_trxnb;
                }
            }
        }
        return "";
    }

    const sait_rows = {
        sait_id: "",
        sait_sta_id: "",
        sait_it_id: "",
        sait_qty: "",
        sait_purpose: "",
        sait_wa_id: "",
        sait_pur_id: "",
        sait_it_idinpack: "",
        sait_qtyinpack: "",
        sait_cost: "",
        sait_mcurratef: "",
        sait_mcurrates: "",
        sait_remqty: "",
        sait_remqtywa: "",
        sait_timestamp: "",
        sait_user_id: "",
        sait_div_id: "",
        sait_date: "",
    }

    const saveStock = (e) => {
        if (transactionType === null || transactionType === undefined || transactionType === "") {
            swal("Info", "Please select a Transaction Type.", "info")
            return;
        }

        let rows = datagridRef.current.instance.getVisibleRows();

        if (rows.length === 0) {
            swal("Error", "Please add at least 1 item!", "error")
            return;
        }

        if (id === null || id === undefined || id === "") {
            const params = {};

            params.sta_cmp_id = globalCmpId;
            params.sta_date = date;
            params.sta_tt_code = transactionType;
            params.sta_trxnb = null;
            params.sta_desc = description;
            params.sta_prj_id = pro;
            params.sta_bra_id = bra;
            params.sta_wa_id = ware;
            params.sta_user_id = globalUsrId;
            params.sta_valuedate = valueDate;
            params.sta_source = source;

            const data = [];
            data.push(params);

            const iparams = [];
            for (let items of rows) {
                if (items.data.sait_qty === null || items.data.sait_qty === undefined || items.data.sait_qty === "") {
                    swal("Info", "Please put a Quantity for the item.", "info")
                    return;
                }

                if (items.data.sait_wa_id === null || items.data.sait_wa_id === undefined || items.data.sait_wa_id === "") {
                    swal("Info", "Please put a Warehouse for the item.", "info")
                    return;
                }

                const param = {};

                param.sait_it_id = items.data.sait_it_id;
                param.sait_purpose = items.data.sait_purpose;
                param.sait_wa_id = items.data.sait_wa_id;
                param.sait_qty = items.data.sait_qty;
                param.sait_pur_id = null;
                param.sait_it_idinpack = null;
                param.sait_qtyinpack = null;
                param.sait_cost = null;
                param.sait_mcurratef = null;
                param.sait_mcurrates = null;
                param.sait_remqty = null;
                param.sait_remqtywa = null;
                param.sait_timestamp = null;
                param.sait_user_id = globalUsrId;
                param.sait_div_id = items.data.sait_div_id;
                param.sait_date = null;

                iparams.push(param);
            };
            addStock(data, iparams);
        } else {
            const params = {};

            params.sta_id = id;
            params.sta_cmp_id = globalCmpId;
            params.sta_date = date;
            params.sta_tt_code = transactionType;
            params.sta_trxnb = stockAdjustment.filter((option) => option.sta_id === id)[0].sta_trxnb;
            params.sta_desc = description;
            params.sta_prj_id = pro;
            params.sta_bra_id = bra;
            params.sta_wa_id = ware;
            params.sta_user_id = globalUsrId;
            params.sta_valuedate = valueDate;
            params.sta_source = source;

            const data = [];
            data.push(params);
            updStock(data);

            let rows = datagridRef.current.instance.getVisibleRows();
            const updparams = [];
            const delparams = [];
            const addparams = [];

            for (let row of rows) {
                if (row.removed) {
                    delparams.push(row.data.sait_id);
                    continue;
                }
                if (row.data.sait_sta_id) {
                    const param = {};

                    param.sait_id = row.data.sait_id;
                    param.sait_sta_id = row.data.sait_sta_id;
                    param.sait_it_id = row.data.sait_it_id;
                    param.sait_purpose = row.data.sait_purpose;
                    param.sait_wa_id = row.data.sait_wa_id;
                    param.sait_qty = row.data.sait_qty;
                    param.sait_pur_id = null;
                    param.sait_it_idinpack = null;
                    param.sait_qtyinpack = null;
                    param.sait_cost = null;
                    param.sait_mcurratef = null;
                    param.sait_mcurrates = null;
                    param.sait_remqty = null;
                    param.sait_remqtywa = null;
                    param.sait_timestamp = null;
                    param.sait_user_id = globalUsrId;
                    param.sait_div_id = row.data.sait_div_id;
                    param.sait_date = null;

                    updparams.push(param);
                } else {
                    const param = {};

                    param.sait_it_id = row.data.sait_it_id;
                    param.sait_purpose = row.data.sait_purpose;
                    param.sait_wa_id = row.data.sait_wa_id;
                    param.sait_qty = row.data.sait_qty;
                    param.sait_pur_id = null;
                    param.sait_it_idinpack = null;
                    param.sait_qtyinpack = null;
                    param.sait_cost = null;
                    param.sait_mcurratef = null;
                    param.sait_mcurrates = null;
                    param.sait_remqty = null;
                    param.sait_remqtywa = null;
                    param.sait_timestamp = null;
                    param.sait_user_id = globalUsrId;
                    param.sait_div_id = row.data.sait_div_id;
                    param.sait_date = null;

                    addparams.push(param);
                }
            };

            if (delparams.length > 0) {
                delStockItem(delparams);
            }
            if (addparams.length > 0) {
                addStockItems(addparams, id);
            }
            if (updparams.length > 0) {
                updStockItem(updparams);
            }

        }

    }

    const addStock = (params, iparams) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockadjustment/add_stockadjustment`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    addStockItems(iparams, response.sta_id[0][0].sta_id);
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedStockAdjustmentItems();
            });
    }

    const addStockItems = (params, id) => {
        for (let sta of params)
            sta.sait_sta_id = id;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockadjustmentitems/add_stockadjustmentitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).catch((err) => console.log(err));
    }

    const updStock = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockadjustment/upd_stockadjustment`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedStockAdjustmentItems();
            });
    };

    const updStockItem = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockadjustmentitems/upd_stockadjustmentitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    }

    const delStock = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockadjustment/del_stockadjustments`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedStockAdjustmentItems();
            });
    };

    const delStockItem = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}stockadjustmentitems/del_stockadjustmentitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const renderColumns = () => {
        const res = [];

        for (var key in sait_rows) {
            if (sait_rows.hasOwnProperty(key)) {
                if ("sait_id" === key) {
                    res.push(
                        <Column
                            dataField="sait_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_sta_id" === key) {
                    res.push(
                        <Column
                            dataField="sait_sta_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_it_id" === key) {
                    res.push(
                        <Column
                            dataField="sait_it_id"
                            key={key}
                            visible={true}
                            caption="Item"
                        >
                            <Lookup dataSource={item.current} displayExpr="it_name" valueExpr="it_id" />
                        </Column>
                    );
                } else if ("sait_qty" === key) {
                    res.push(
                        <Column
                            dataField="sait_qty"
                            key={key}
                            visible={true}
                            caption="Quantity"
                            dataType="number"
                            alignment='left'
                        >
                            <RequiredRule message="Quantity required!" />
                        </Column>
                    );
                } else if ("sait_purpose" === key) {
                    res.push(
                        <Column
                            dataField="sait_purpose"
                            key={key}
                            visible={true}
                            caption="Purpose"
                            dataType="string"
                        ></Column>
                    );
                } else if ("sait_wa_id" === key) {
                    res.push(
                        <Column
                            dataField="sait_wa_id"
                            key={key}
                            visible={true}
                            caption="Warehouse"
                        >
                            <Lookup dataSource={warehouse.current} displayExpr="wa_name" valueExpr="wa_id" />
                            <RequiredRule message="Warehous required!" />
                        </Column>
                    );
                } else if ("sait_pur_id" === key) {
                    res.push(
                        <Column
                            dataField="sait_pur_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_it_idinpack" === key) {
                    res.push(
                        <Column
                            dataField="sait_it_idinpack"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_qtyinpack" === key) {
                    res.push(
                        <Column
                            dataField="sait_qtyinpack"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_cost" === key) {
                    res.push(
                        <Column
                            dataField="sait_cost"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_mcurratef" === key) {
                    res.push(
                        <Column
                            dataField="sait_mcurratef"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_mcurrates" === key) {
                    res.push(
                        <Column
                            dataField="sait_mcurrates"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_remqty" === key) {
                    res.push(
                        <Column
                            dataField="sait_remqty"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_remqtywa" === key) {
                    res.push(
                        <Column
                            dataField="sait_remqtywa"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_timestamp" === key) {
                    res.push(
                        <Column
                            dataField="sait_timestamp"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_user_id" === key) {
                    res.push(
                        <Column
                            dataField="sait_user_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("sait_div_id" === key) {
                    res.push(
                        <Column
                            dataField="sait_div_id"
                            key={key}
                            visible={true}
                            caption="Division"
                        >
                            <Lookup dataSource={division.current} displayExpr="div_name" valueExpr="div_id" />
                        </Column>
                    );
                } else if ("sait_date" === key) {
                    res.push(
                        <Column
                            dataField="sait_date"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <div className="groups">
                    <div className="groups-btns">
                        <Button
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                            text="Save"
                            width={100}
                            onClick={saveStock}
                        />
                        <Button
                            text="Reset"
                            width={100}
                            onClick={onReset}
                        />
                        <Button
                            text="Delete"
                            width={100}
                            onClick={deleteStockAdjustment}
                        />
                        <Button
                            text="Print"
                            width={100}
                        />
                    </div>
                </div>
                <div className="maingroup">
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Date</InputLabel>
                        <DateBox
                            value={date}
                            placeholder="Date"
                            onValueChanged={(e) => setDate(e.value)}
                            width={200}
                            type="datetime"
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Type</InputLabel>
                        <SelectBox
                            placeholder="Type"
                            value={transactionType}
                            dataSource={adjustmentTransTypes}
                            onValueChanged={(e) => setTransactionType(e.value)}
                            displayExpr={displayTypeCode}
                            valueExpr="tt_code"
                            width={200}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Transaction Number</InputLabel>
                        <SelectBox
                            dataSource={stockAdjustment}
                            displayExpr={displayTypeTrxnb}
                            valueExpr="sta_id"
                            placeholder="Transaction Number"
                            width={200}
                            onValueChanged={getID}
                            value={id}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Value Date</InputLabel>
                        <DateBox
                            value={valueDate}
                            placeholder="Value Date"
                            onValueChanged={(e) => setValueDate(e.value)}
                            width={200}
                            type="datetime"
                        />
                        <InputLabel sx={{ color: "white" }}>Project</InputLabel>
                        <SelectBox
                            placeholder="Project"
                            value={pro}
                            dataSource={project.current}
                            onValueChanged={(e) => setPro(e.value)}
                            width={200}
                            valueExpr="prj_id"
                            displayExpr="prj_name"
                        />
                        <InputLabel sx={{ color: "white" }}>Branch</InputLabel>
                        <SelectBox
                            placeholder="Branch"
                            value={bra}
                            dataSource={branch.current}
                            onValueChanged={(e) => setBra(e.value)}
                            width={200}
                            valueExpr="bra_id"
                            displayExpr="bra_name"
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Warehouse</InputLabel>
                        <SelectBox
                            placeholder="Warehouse"
                            value={ware}
                            dataSource={warehouse.current}
                            onValueChanged={(e) => setWare(e.value)}
                            width={200}
                            valueExpr="wa_id"
                            displayExpr="wa_name"
                        />
                        <InputLabel sx={{ color: "white" }}>Description</InputLabel>
                        <TextArea
                            value={description}
                            placeholder="Description"
                            onValueChanged={(e) => setDescription(e.value)}
                            width={250}
                            height={114}
                            maxLength={300}
                        />
                    </div>
                </div>
                <div className="datagridcss">
                    <DataGrid
                        className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        // onEditorPreparing={onEditiorPreparing}
                        ref={datagridRef}
                        dataSource={stockAdjustmentItems}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        remoteOperations={false}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        columnMinWidth={70}
                        columnResizingMode={"widget"}
                    >
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 50, 100, 500]}
                            showInfo={true}
                            infoText="Page {0}. Total: {1} ({2} Items)"
                        />
                        <Toolbar>
                            <Item location="after" visible={transactionType !== null} name="addRowButton" />
                            <Item location="after" visible={false} name="saveButton" />
                            <Item location="after" visible={true} name="revertButton" />
                            <Item location="after" visible={true} name="searchPanel" />
                            <Item location="before" visible={true} name="groupPanel" />
                            <Item location="before" visible={true} name="columnChooserButton" />
                        </Toolbar>
                        <FilterBuilder defaultFields={sait_rows} />
                        <FilterRow visible={true} />
                        <Sorting visible={true} />
                        <GroupPanel visible={true} />
                        <SearchPanel visible={true} />
                        <Editing
                            allowAdding={true}
                            allowUpdating={true}
                            allowDeleting={true}
                            confirmDelete={true}
                            mode="batch"
                            selectTextOnEditStart={true}
                            useIcons={true}
                        />
                        <ColumnChooser enabled={false} />
                        <Scrolling columnRenderingMode="virtual" />
                        <KeyboardNavigation
                            editOnKeyPress={true}
                            enterKeyAction="moveFocus"
                            enterKeyDirection="column"
                        />
                        {renderColumns()}
                    </DataGrid>
                </div>
            </div>
        )
    }

    return <div className="table-container">{renderDataGrid()}</div>;
};

export default Adjustment;