import './About.css';
import '../../GlobalCSS.css';
import * as global from '../../utils/globals';
import Logo from '../../assets/images/dataharbor.png'

const About = ({ openDrawer, setPage }) => {
    return (
        <div className='main-about'>
            <div className='centerdiv'>
                <div className='imagediv'>
                    <img className='image' src={Logo} onClick={() => setPage("/Dashboard")}></img><p></p>
                </div>
                <div className='versiondiv'>
                    <p>Version 1.00</p>
                </div>
                <div className='textdiv'>
                    <div className='aboutdiv'>
                        <p className='title'><b>What is an ERP?</b></p>
                        <p>
                            Enterprise resource planning (ERP) is the integrated management of main business processes,
                            often in real time and mediated by software and technology.
                            ERP is usually referred to as a category of business management software—typically a suite of integrated applications—that an organization can use to collect,
                            store, manage and interpret data from many business activities. ERP systems can be local-based or cloud-based.
                            Cloud-based applications have grown in recent years due to the increased
                            efficiencies arising from information being readily available from any location with Internet access
                        </p>
                    </div>
                    <div className='aboutdiv'>
                        <p className='title'><b>What is the goal of Dataharbor?</b></p>
                        <p>
                            Many enterprise resource planning (ERP) software have become too complicated to use. The goal of Dataharbor is to provide a simple system design
                            for new or current companies, emporiums, and communities. With easy to enter information, straightforward setup, and fast data retrieval,
                            it makes this ERP operation a timesaver. Having a considerable number of services and tools at the disposal of the user,
                            the team also provides custom orders to either the ongoing utility that Dataharbor has implemented, or unique pages intended for your liking, and majority
                            of surveys.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;