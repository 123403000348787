import "./TimelineSheet.css";
import "../../GlobalCSS.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import multiMonthPlugin from '@fullcalendar/multimonth'
import { useEffect, useState } from "react";
import { getPayments, convertDateOnly, getPurchases, getReceipts, getInvoices } from "../../utils/helper";
import { store } from "../../store";
import { Button } from "devextreme-react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import swal from "sweetalert";
import * as global from '../../utils/globals';

const TimelineSheet = ({ openDrawer, setData, setPage, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const [events, setEvents] = useState([]);
    const [payments, setPayments] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [receipts, setReceipts] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [name, setName] = useState("");
    const [id, setId] = useState(null);
    const [popup, setPopup] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({
        title: "",
        trxnb: "",
        status: "",
        note: "",
        refnb: "",
        delivered: "",
        paid: "",
    });

    useEffect(() => {
        setIsLoading(true);
        getPayments(globalCmpId).then((pay) => {
            setPayments(pay);
            getInvoices(globalCmpId).then((inv) => {
                setInvoices(inv);
                getPurchases(globalCmpId).then((pur) => {
                    setPurchases(pur);
                    getReceipts(globalCmpId).then((rec) => {
                        setReceipts(rec);

                        const newEvents = [];
                        for (let pa of pay) {
                            newEvents.push({
                                id: pa.pay_id,
                                title: pa.pay_trxnb,
                                url: "Payment",
                                date: convertDateOnly(pa.pay_date),
                                extendedProps: [{ status: pa.pay_status, note: pa.pay_note, refnb: pa.pay_referencenb }]
                            })
                        }

                        for (let i of inv) {
                            newEvents.push({
                                id: i.inv_id,
                                title: i.inv_trxnb,
                                url: "Invoice",
                                date: convertDateOnly(i.inv_duedate),
                                extendedProps: [{ delivered: i.inv_delivered, note: i.inv_note, paid: i.inv_paid }]
                            })
                        }

                        for (let pu of pur) {
                            newEvents.push({
                                id: pu.pur_id,
                                title: pu.pur_trxnb,
                                url: "Purchases",
                                date: convertDateOnly(pu.pur_duedate),
                                extendedProps: [{ delivered: pu.pur_delivered, note: pu.pur_note, paid: pu.pur_paid }]
                            })
                        }

                        for (let re of rec) {
                            newEvents.push({
                                id: re.rec_id,
                                title: re.rec_trxnb,
                                url: "Receipts",
                                date: convertDateOnly(re.rec_date),
                                extendedProps: [{ status: re.rec_status, note: re.rec_note, refnb: re.rec_referencenb }]
                            })
                        }
                        setEvents(newEvents);
                    });
                });
            });
            setIsLoading(false);
        });
    }, []);

    const deleted = (e) => {
        const data = [];
        data.push(id);
        for (let p of payments) {
            if (id === p.pay_id) {
                delPayment(data);
                return;
            }
        }
        for (let i of invoices) {
            if (id === i.inv_id) {
                delInvoice(data);
                return;
            }
        }
        for (let pur of purchases) {
            if (id === pur.pur_id) {
                delPurchase(data);
                return;
            }
        }
        for (let r of receipts) {
            if (id === r.rec_id) {
                delReceipt(data);
                return;
            }
        }
    }

    const delInvoice = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}invoices/del_invoice`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    setPopup(false);
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                var newData = events;
                for (let id of listIds)
                    newData = newData.filter((inv) => inv.id !== id);
                setEvents(newData);
            })
    };

    const delPurchase = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}purchases/del_purchase`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    setPopup(false);
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                var newData = events;
                for (let id of listIds)
                    newData = newData.filter((pur) => pur.id !== id);
                setEvents(newData);
            })
    };

    const delPayment = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}payments/del_payment`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    setPopup(false);
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                var newData = events;
                for (let id of listIds)
                    newData = newData.filter((pay) => pay.id !== id);
                setEvents(newData);
            })
    };

    const delReceipt = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}receipts/del_receipt`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    setPopup(false);
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                var newData = events;
                for (let id of listIds)
                    newData = newData.filter((inv) => inv.id !== id);
                setEvents(newData);
            })
    };

    const onEventClick = (e) => {
        setId(e.event.id);
        setName(e.event.url);
        if (e.event.url === "Payment") {
            for (let p = 0; p < payments.length; p++) {
                if (payments[p].pay_id === e.event.id) {
                    payments[p].toPage = "Payment";
                    setData(payments[p]);
                    break;
                }
            }
        } else if (e.event.url === "Receipts") {
            for (let r = 0; r < receipts.length; r++) {
                if (receipts[r].rec_id === e.event.id) {
                    receipts[r].toPage = "Receipt";
                    setData(receipts[r]);
                    break;
                }
            }
        } else if (e.event.url === "Purchases") {
            for (let pu = 0; pu < purchases.length; pu++) {
                if (purchases[pu].pur_id === e.event.id) {
                    purchases[pu].toPage = "Purchase";
                    setData(purchases[pu]);
                    break;
                }
            }
        } else if (e.event.url === "Invoice") {
            for (let i = 0; i < invoices.length; i++) {
                if (invoices[i].inv_id === e.event.id) {
                    invoices[i].toPage = "Invoice";
                    setData(invoices[i]);
                    break;
                }
            }
        }
        if (e.event.url === "Payment" || e.event.url === "Receipts") {
            setSelectedEvent({
                title: e.event.url,
                trxnb: e.event.title,
                status: e.event.extendedProps[0].status,
                note: e.event.extendedProps[0].note,
                refnb: e.event.extendedProps[0].refnb
            })
        } else {
            setSelectedEvent({
                title: e.event.url,
                trxnb: e.event.title,
                delivered: e.event.extendedProps[0].delivered,
                note: e.event.extendedProps[0].note,
                paid: e.event.extendedProps[0].paid
            })
        }
        e.jsEvent.preventDefault();
        setPopup(true);
    }

    const onOpen = (e) => {
        if (name === "Payment") {
            setPage("/Payments")
        } else if (name === "Receipts") {
            setPage("/Receipt")
        } else if (name === "Purchases") {
            setPage("/Purchase")
        } else if (name === "Invoice") {
            setPage("/Invoice")
        }
    }

    function handleDateSelect(selectInfo) {
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }

    function renderEventContent(e) {
        return (
            <div title={e.event.extendedProps[0].note}>
                <>{e.event.url + ": "}</>
                <>{e.event.title}</>
            </div>
        )

    }

    return (
        <div className="outerdiv">
            <div className="timeline_grid">
                <div className={openDrawer ? 'shiftRight' : 'shiftLeft'}>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        events={events}
                        eventClick={onEventClick}
                        // select={handleDateSelect}
                        eventContent={renderEventContent}
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                    />
                </div>
                <div>
                    <Popup
                        visible={popup}
                        onHiding={() => setPopup(false)}
                        dragEnabled={false}
                        showCloseButton={true}
                        showTitle={true}
                        width={300}
                        height="auto"
                    >
                        <Position
                            at="center"
                            my="center"
                        />
                        <ToolbarItem
                            text={selectedEvent.title}
                            location="before">
                        </ToolbarItem>
                        {(selectedEvent.title === "Payment" || selectedEvent.title === "Receipts") &&
                            <div className="popup_div1">
                                <div><p><b>Number</b>:&nbsp;{selectedEvent.trxnb}</p></div>
                                <div><p><b>Reference Number</b>:&nbsp;{selectedEvent.refnb}</p></div>
                                <div><p><b>Status</b>:&nbsp;{selectedEvent.status}</p></div>
                                <div><p><b>Note</b>:&nbsp;{selectedEvent.note}</p></div>
                                <div className="popup_div2">
                                    <Button onClick={onOpen} width={100}>Open</Button>
                                    <Button onClick={deleted} width={100}>Delete</Button>
                                </div>
                            </div>

                        }
                        {(selectedEvent.title === "Purchases" || selectedEvent.title === "Invoice") &&
                            <div className="popup_div1">
                                <div><p><b>Number</b>:&nbsp;{selectedEvent.trxnb}</p></div>
                                <div><p><b>Reference Number</b>:&nbsp;{selectedEvent.refnb}</p></div>
                                <div><p><b>Delivered</b>:&nbsp;{selectedEvent.delivered}</p></div>
                                <div><p><b>Paid</b>:&nbsp;{selectedEvent.paid}</p></div>
                                <div className="popup_div2">
                                    <Button onClick={onOpen} width={100}>Open</Button>
                                    <Button onClick={deleted} width={100}>Delete</Button>
                                </div>
                            </div>
                        }
                    </Popup>
                </div>
            </div>
        </div>
    );
};

export default TimelineSheet; 
