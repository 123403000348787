import '../../GlobalCSS.css'
import './Thirdparty.css'
import { useState, useEffect, useRef } from 'react';
import swal from "sweetalert";
import * as global from '../../utils/globals';
import { getThirdparties, getBranches, getWarehouses, getDivisions, getCurrencies, getBrands, getProjects } from '../../utils/helper';
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import { store } from '../../store';

const Thirdparty = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const branches = useRef([]);
    const warehouses = useRef([]);
    const divisions = useRef([]);
    const currencies = useRef([]);
    const brands = useRef([]);
    const projects = useRef([]);
    const [thirdParty, setThirdParty] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getUpdatedTP();
        getBranches(globalCmpId).then((x) => {
            x.unshift({
                bra_id: null,
                bra_name: ''
            })
            branches.current = x;
        });
        getBrands(globalCmpId).then((x) => {
            x.unshift({
                br_id: null,
                br_name: ''
            })
            brands.current = x;
        });
        getDivisions(globalCmpId).then((x) => {
            x.unshift({
                div_id: null,
                div_name: ''
            })
            divisions.current = x;
        });
        getWarehouses(globalCmpId).then((x) => {
            x.unshift({
                wa_id: null,
                wa_name: ''
            })
            warehouses.current = x
        });
        getCurrencies(globalCmpId).then((x) => {
            x.unshift({
                cur_id: null,
                cur_name: ''
            })
            currencies.current = x
        });
        getProjects(globalCmpId).then((x) => {
            x.unshift({
                prj_id: null,
                prj_name: ''
            })
            projects.current = x
            setIsLoading(false);
        });
    }, [])

    const getUpdatedTP = () => {
        getThirdparties(globalCmpId).then((x) => {
            setThirdParty(x);
            setIsLoading(false);
        });
    }

    const tp_rows = {
        tp_id: "",
        tp_name: "",
        tp_bra_id: "",
        tp_wa_id: "",
        tp_timestamp: "",
        tp_div_id: "",
        tp_prj_id: "",
        tp_address: "",
        tp_website: "",
        tp_email: "",
        tp_date_created: "",
        tp_note: "",
        tp_cur_id: "",
        tp_br_id: "",
        tp_gender: "",
        tp_cmp_id: "",
        tp_tp_id: "",
        tp_contact: "",
        tp_phone: "",
        tp_type: "",
        tp_userstamp: "",
    }

    const genderSource = [{
        ID: "male",
        Name: "Male"
    }, {
        ID: "female",
        Name: "Female"
    }, {
        ID: "other",
        Name: "Other"
    }]

    const addThirdparty = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}thirdparties/add_thirdparty`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedTP();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updThirdparty = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}thirdparties/upd_thirdparty`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedTP();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delThirdparty = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}thirdparties/del_thirdparty`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedTP();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedThirdparty = [];
            const updatedThirdparty = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.tp_id);
                } else if (row.type === "insert") {
                    addedThirdparty.push({ ...row.data, tp_cmp_id: globalCmpId });
                } else {
                    const obj = { ...row.key, ...row.data, tp_cmp_id: globalCmpId };
                    updatedThirdparty.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delThirdparty(deletedIds);
            }
            if (addedThirdparty.length > 0) {
                addThirdparty(addedThirdparty);
            }
            if (updatedThirdparty.length > 0) {
                updThirdparty(updatedThirdparty);
            }
        }
    };

    const renderColumns = () => {
        const res = [];

        for (var key in tp_rows) {
            if (tp_rows.hasOwnProperty(key)) {
                if ("tp_id" === key) {
                    res.push(
                        <Column
                            dataField="tp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tp_name" === key) {
                    res.push(
                        <Column
                            dataField="tp_name"
                            caption="Name"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("tp_cmp_id" === key) {
                    res.push(
                        <Column
                            dataField="tp_cmp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tp_bra_id" === key) {
                    res.push(
                        <Column
                            dataField="tp_bra_id"
                            caption='Branch'
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={branches.current} valueExpr='bra_id' displayExpr="bra_name" />
                        </Column>
                    );
                } else if ("tp_wa_id" === key) {
                    res.push(
                        <Column
                            dataField="tp_wa_id"
                            key={key}
                            visible={true}
                            caption="Warehouse"
                        >
                            <Lookup dataSource={warehouses.current} valueExpr='wa_id' displayExpr="wa_name" />
                        </Column>
                    );
                } else if ("tp_div_id" === key) {
                    res.push(
                        <Column
                            dataField="tp_div_id"
                            key={key}
                            visible={true}
                            caption="Division"
                        >
                            <Lookup dataSource={divisions.current} valueExpr='div_id' displayExpr="div_name" />
                        </Column>
                    );
                } else if ("tp_prj_id" === key) {
                    res.push(
                        <Column
                            dataField="tp_prj_id"
                            caption='Project'
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={projects.current} valueExpr='prj_id' displayExpr="prj_name" />
                        </Column>
                    );
                } else if ("tp_cur_id" === key) {
                    res.push(
                        <Column
                            dataField="tp_cur_id"
                            key={key}
                            visible={true}
                            caption='Currency'
                        >
                            <Lookup dataSource={currencies.current} valueExpr='cur_id' displayExpr="cur_code" />
                        </Column>
                    );
                } else if ("tp_br_id" === key) {
                    res.push(
                        <Column
                            dataField="tp_br_id"
                            key={key}
                            visible={true}
                            caption='Brand'
                        >
                            <Lookup dataSource={brands.current} valueExpr='br_id' displayExpr="br_name" />
                        </Column>
                    );
                } else if ("tp_tp_id" === key) {
                    res.push(
                        <Column
                            dataField="tp_tp_id"
                            key={key}
                            visible={true}
                            caption='Third Party'
                        >
                            <Lookup dataSource={thirdParty} valueExpr='tp_id' displayExpr="tp_name" />
                        </Column>
                    );
                } else if ("tp_date_create" === key) {
                    res.push(
                        <Column
                            dataField="tp_date_created"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tp_timestamp" === key) {
                    res.push(
                        <Column
                            dataField="tp_timestamp"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("tp_address" === key) {
                    res.push(
                        <Column
                            dataField="tp_address"
                            caption="Address"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("tp_website" === key) {
                    res.push(
                        <Column
                            dataField="tp_website"
                            caption="Website"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("tp_email" === key) {
                    res.push(
                        <Column
                            dataField="tp_email"
                            caption="Email"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("tp_note" === key) {
                    res.push(
                        <Column
                            dataField="tp_note"
                            caption="Note"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("tp_gender" === key) {
                    res.push(
                        <Column
                            dataField="tp_gender"
                            caption="Gender"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={genderSource} valueExpr="ID" displayExpr="Name" />
                        </Column>
                    );
                } else if ("tp_contact" === key) {
                    res.push(
                        <Column
                            dataField="tp_contact"
                            caption="Contact"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("tp_phone" === key) {
                    res.push(
                        <Column
                            dataField="tp_phone"
                            caption="Phone"
                            key={key}
                            visible={true}
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("tp_type" === key) {
                    res.push(
                        <Column
                            dataField="tp_type"
                            caption="Type"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={global.thirdPartyType} />
                        </Column>
                    );
                } else if ("tp_userstamp" === key) {
                    res.push(
                        <Column
                            dataField="tp_userstamp"
                            caption="User"
                            key={key}
                            editorOptions={{ readOnly: true }}
                            visible={true}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    dataSource={thirdParty}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <FilterBuilder defaultFields={tp_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default Thirdparty;