import { useState, useEffect, useRef } from 'react';
import './Employees.css';
import '../../GlobalCSS.css';
import * as global from '../../utils/globals';
import swal from "sweetalert";
import { getRoles, getPosition, getDepartment, getCurrencies, getThirdparties, getEmployees, getCategories } from "../../utils/helper";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
    EmailRule,
    RequiredRule,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import { store } from '../../store';

const Employees = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const department = useRef([]);
    const position = useRef([]);
    const role = useRef([]);
    const currencies = useRef([]);
    const thirdparties = useRef([]);
    const category = useRef([]);
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getDepartment(globalCmpId).then((x) => {
            x.unshift({
                dep_id: null,
                dep_name: ''
            })
            department.current = x;
        });
        getPosition(globalCmpId).then((x) => {
            x.unshift({
                po_id: null,
                po_name: ''
            })
            position.current = x;
        });
        getRoles(globalCmpId).then((x) => {
            x.unshift({
                ro_id: null,
                ro_name: ''
            })
            role.current = x;
        });
        getThirdparties(globalCmpId).then((x) => {
            x.unshift({
                tp_id: null,
                tp_name: ''
            })
            thirdparties.current = x;
        });
        getCurrencies(globalCmpId).then((x) => {
            x.unshift({
                cur_id: null,
                cur_name: ''
            })
            currencies.current = x;
        });
        getCategories(globalCmpId).then((x) => {
            x.unshift({
                cat_id: null,
                cat_name: ''
            })
            category.current = x;
        });
        getUpdatedEmp();
    }, [])

    const getUpdatedEmp = () => {
        getEmployees(globalCmpId).then((x) => {
            setEmployees(x);
            setIsLoading(false);
        });
    }

    const emp_rows = {
        emp_id: "",
        emp_firstname: "",
        emp_lastname: "",
        emp_email: "",
        emp_salary: "",
        emp_dep_id: "",
        emp_po_id: "",
        emp_ro_id: "",
        emp_address: "",
        emp_phonenumber: "",
        emp_cur_id: "",
        emp_tp_id: "",
        emp_cmp_id: "",
        emp_datecreated: "",
        emp_cat_id: "",
        emp_numb: "",
    }

    const addEmployee = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}employees/add_employee`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedEmp();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updEmployee = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}employees/upd_employee`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedEmp();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delEmployee = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}employees/del_employee`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedEmp();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedEmployee = [];
            const updatedEmployee = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.emp_id);
                } else if (row.type === "insert") {
                    addedEmployee.push({ ...row.data, emp_cmp_id: globalCmpId });
                } else {
                    const obj = { ...row.key, ...row.data, emp_cmp_id: globalCmpId };
                    updatedEmployee.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delEmployee(deletedIds);
            }
            if (addedEmployee.length > 0) {
                addEmployee(addedEmployee);
            }
            if (updatedEmployee.length > 0) {
                updEmployee(updatedEmployee);
            }
        }
    };

    const renderColumns = () => {
        const res = [];

        for (var key in emp_rows) {
            if (emp_rows.hasOwnProperty(key)) {
                if ("emp_id" === key) {
                    res.push(
                        <Column
                            dataField="emp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("emp_firstname" === key) {
                    res.push(
                        <Column
                            dataField="emp_firstname"
                            caption="First Name"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("emp_lastname" === key) {
                    res.push(
                        <Column
                            dataField="emp_lastname"
                            caption="Last Name"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("emp_cmp_id" === key) {
                    res.push(
                        <Column
                            dataField="emp_cmp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("emp_email" === key) {
                    res.push(
                        <Column
                            dataField="emp_email"
                            caption='Email'
                            key={key}
                            visible={true}
                        >
                            <EmailRule message='Email type required!'></EmailRule>
                        </Column>
                    );
                } else if ("emp_salary" === key) {
                    res.push(
                        <Column
                            dataField="emp_salary"
                            key={key}
                            visible={true}
                            caption="Salary"
                            editorOptions={{ readOnly: true }}
                        ></Column>
                    );
                } else if ("emp_dep_id" === key) {
                    res.push(
                        <Column
                            dataField="emp_dep_id"
                            key={key}
                            visible={true}
                            caption="Department"
                        >
                            <RequiredRule message='Department required' />
                            <Lookup dataSource={department.current} valueExpr='dep_id' displayExpr="dep_name" />
                        </Column>
                    );
                } else if ("emp_po_id" === key) {
                    res.push(
                        <Column
                            dataField="emp_po_id"
                            key={key}
                            visible={true}
                            caption='Position'
                        >
                            <Lookup dataSource={position.current} valueExpr='po_id' displayExpr="po_name" />
                        </Column>
                    );
                } else if ("emp_ro_id" === key) {
                    res.push(
                        <Column
                            dataField="emp_ro_id"
                            key={key}
                            visible={true}
                            caption='Role'
                        >
                            <Lookup dataSource={role.current} valueExpr='ro_id' displayExpr="ro_name" />
                        </Column>
                    );
                } else if ("emp_address" === key) {
                    res.push(
                        <Column
                            dataField="emp_address"
                            key={key}
                            visible={true}
                            caption='Address'
                        ></Column>
                    );
                } else if ("emp_phonenumber" === key) {
                    res.push(
                        <Column
                            dataField="emp_phonenumber"
                            key={key}
                            visible={true}
                            caption='Phone Number'
                            dataType='number'
                            alignment='left'
                        ></Column>
                    );
                } else if ("emp_cur_id" === key) {
                    res.push(
                        <Column
                            dataField="emp_cur_id"
                            key={key}
                            visible={true}
                            caption='Currency'
                        >
                            <Lookup dataSource={currencies.current} valueExpr='cur_id' displayExpr="cur_code" />
                        </Column>
                    );
                } else if ("emp_tp_id" === key) {
                    res.push(
                        <Column
                            dataField="emp_tp_id"
                            key={key}
                            visible={true}
                            caption='Third Party'
                        >
                            <Lookup dataSource={thirdparties.current} valueExpr='tp_id' displayExpr="tp_name" />
                        </Column>
                    );
                } else if ("emp_datecreated" === key) {
                    res.push(
                        <Column
                            dataField="emp_datecreated"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("emp_cat_id" === key) {
                    res.push(
                        <Column
                            dataField="emp_cat_id"
                            key={key}
                            visible={true}
                            caption="Category"
                        >
                            <RequiredRule message='Category required' />
                            <Lookup dataSource={category.current} valueExpr='cat_id' displayExpr="cat_name" />
                        </Column>
                    );
                } else if ("emp_numb" === key) {
                    res.push(
                        <Column
                            dataField="emp_numb"
                            key={key}
                            visible={true}
                            caption="Employee Number"
                            editorOptions={{ readOnly: true }}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    dataSource={employees}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <FilterBuilder defaultFields={emp_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default Employees;