import './Main.css'
import Drawer from '../Drawer/Drawer';
import Dashboard from '../Dashboard/Dashboard';
import { useEffect, useState } from 'react';
import WareHouse from '../Warehouse/Warehouse';
import Categories from '../Categories/Categories';
import Branches from '../Branches/Branches';
import Brands from '../Brands/Brands';
import Divisions from '../Divisions/Divisions';
import Item from '../Item/Item';
import ItemColor from '../ItemColor/ItemColor';
import Sizes from '../Sizes/Sizes';
import Units from '../Units/Units';
import { useNavigate } from 'react-router-dom';
import AddUser from '../AddUser/AddUser';
import Profile from '../Profile/Profile';
import { getProfile } from '../../utils/helper';
import Department from '../Department/Department';
import Roles from '../Roles/Roles';
import Position from '../Position/Position';
import { getUser, getMainRates, getCashClient, getMainCurrencies, getTransactionTypes } from '../../utils/helper';
import Thirdparty from '../Thirdparty/ThirdParty';
import Currency from '../Currency/Currency';
import Rate from '../Rate/Rate';
import Project from '../Projects/Project';
import Employees from '../Employees/Employees';
import EmployeeSalary from '../EmployeeSalary/EmployeeSalary';
import Invoice from '../Invoice/Invoice';
import Purchase from '../Purchase/Purchase';
import { store, mainSliceActions } from "../../store";
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import ReportDesigner from '../ReportDesigner/ReportDesigner';
import TimelineSheet from '../TimelineSheet/TimelineSheet';
import ReportViewer from '../ReportViewer/ReportViewer';
import Payments from '../Payments/Payments';
import Receipt from '../Receipt/Receipt';
import About from '../About/About';
import Adjustment from '../StockAdjustment/Adjustment';
import Inandout from '../StockInAndOut/Inandout';
import LoadPanel from 'devextreme-react/load-panel';
import ProductionOrders from '../ProductionOrders/ProductionOrders';
import Sections from '../Sections/Sections';
import Tables from '../Tables/Tables';
import MoneyBills from '../MoneyBills/MoneyBills';

function Main() {
    const globalUserId = store.getState().mainReducer.usr_id;
    const navigate = useNavigate();
    const [page, setPage] = useState(JSON.parse(localStorage.getItem("page")).page || "/Dashboard");
    const [openDrawer, setOpenDrawer] = useState(false);
    const [data, setData] = useState({});
    const [logout, setLogout] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getUser(globalUserId).then((x) => {
            store.dispatch(mainSliceActions.setCmpId(x[0].user_cmp_id));
            store.dispatch(mainSliceActions.setProfileId(x[0].user_profile));
            store.dispatch(mainSliceActions.setUserType(x[0].user_type));
            getProfile(x[0].user_profile, x[0].user_cmp_id).then((pro) => {
                document.documentElement.style.setProperty('--main-bg-color', pro[0].pro_color);
            })
            getMainRates(x[0].user_cmp_id).then((rat) => {
                const rates = [];
                for (let r of rat) {
                    if (r.rate_id !== null) rates.push(r);
                }
                store.dispatch(mainSliceActions.setRates(rates));
            })
            getCashClient(x[0].user_cmp_id).then((tp) => {
                if (tp.length > 0)
                    store.dispatch(mainSliceActions.setDefaultCashClient(tp[0]));
            })
            getMainCurrencies(x[0].user_cmp_id).then((cur) => {
                if (cur.length > 0) {
                    for (let c of cur) {
                        if (c.cur_priority === 1)
                            store.dispatch(mainSliceActions.setFirstCur(c.cur_id));
                        else store.dispatch(mainSliceActions.setSecondCur(c.cur_id));
                    }
                }
            })
            getTransactionTypes(x[0].user_cmp_id).then((types) => {
                if (types.length > 0) {
                    store.dispatch(mainSliceActions.setTransTypes(types));
                }
            })
        });
    }, [])

    useEffect(() => {
        if (logout)
            navigate("/");
    }, [logout])

    useEffect(() => {
        localStorage.setItem("page", JSON.stringify({ page: page }));
    }, [page])

    return (
        <div className='body'>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={isLoading}
                showIndicator={true}
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Drawer setPage={setPage} pageName={page.substring(1)} setOpenDrawer={setOpenDrawer} setLogout={setLogout} setIsLoading={setIsLoading} />
            {page === "/Dashboard" && <Dashboard openDrawer={openDrawer} setPage={setPage} setIsLoading={setIsLoading} />}
            {page === "/Warehouse" && <WareHouse openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Categories" && <Categories openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Branches" && <Branches openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Brands" && <Brands openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Divisions" && <Divisions openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Item" && <Item openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/ItemColor" && <ItemColor openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Sizes" && <Sizes openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Units" && <Units openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/AddUser" && <AddUser openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Profile" && <Profile openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Department" && <Department openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Roles" && <Roles openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Position" && <Position openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Thirdparty" && <Thirdparty openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Currency" && <Currency openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Rate" && <Rate openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Project" && <Project openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Employees" && <Employees openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/EmployeeSalary" && <EmployeeSalary openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Invoice" && <Invoice openDrawer={openDrawer} data={data} setData={setData} setIsLoading={setIsLoading} />}
            {page === "/Purchase" && <Purchase openDrawer={openDrawer} data={data} setData={setData} setIsLoading={setIsLoading} />}
            {page === "/PaymentMethod" && <PaymentMethod openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/ReportDesigner" && <ReportDesigner openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/ReportViewer" && <ReportViewer openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Calendar" && <TimelineSheet openDrawer={openDrawer} setData={setData} setPage={setPage} setIsLoading={setIsLoading} />}
            {page === "/Payments" && <Payments openDrawer={openDrawer} data={data} setData={setData} setIsLoading={setIsLoading} />}
            {page === "/Receipt" && <Receipt openDrawer={openDrawer} data={data} setData={setData} setIsLoading={setIsLoading} />}
            {page === "/About" && <About openDrawer={openDrawer} setPage={setPage} setIsLoading={setIsLoading} />}
            {page === "/StockAdjustment" && <Adjustment openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/StockInOut" && <Inandout openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/ProductionOrders" && <ProductionOrders openDrawer={openDrawer} data={data} setData={setData} setIsLoading={setIsLoading} />}
            {page === "/Sections" && <Sections openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/Tables" && <Tables openDrawer={openDrawer} setIsLoading={setIsLoading} />}
            {page === "/MoneyBills" && <MoneyBills openDrawer={openDrawer} setIsLoading={setIsLoading} />}
        </div>
    );
}

export default Main;