import React, { useRef, useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
    Toolbar,
    Item,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css"
import {
    getReceiptItems, getReceipts, getCurrencies, getPaymentMethods,
    getRatesToAndFrom, getBranches, getDivisions, getProjects,
    getThirdpartiesType, getCompany, convertDateOnly, getUser
} from "../../utils/helper";
import { Button, DateBox, SelectBox, TextArea, TextBox } from "devextreme-react";
import { Dialog, DialogTitle, InputLabel } from "@mui/material";
import "./Receipt.css"
import { store } from "../../store";
import ClearIcon from "@mui/icons-material/Clear";
import Slide from "@mui/material/Slide";
import { useReactToPrint } from "react-to-print";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const exportsSettings = {
    pdf: {
        title: "Invoice",
        author: "GrapeCity",
        subject: "Web Reporting",
        keywords: "reporting, sample",
        userPassword: "pwd",
        ownerPassword: "ownerPwd",
        printing: "none",
        copying: false,
        modifying: false,
        annotating: false,
        contentAccessibility: false,
        documentAssembly: false,
        pdfVersion: "1.7",
        autoPrint: true,
        filename: "Invoice.pdf",
    },
    html: {
        title: "Invoice",
        filename: "Invoice.html",
        autoPrint: true,
        multiPage: true,
        embedImages: "external",
        outputType: "html",
    },
};

// remove items from this array to hide unnecessary Exports Types
const availableExports = ["pdf", "html", "tabular-data"];

const Receipt = ({ openDrawer, data, setData, setIsLoading }) => {

    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalUsrId = store.getState().mainReducer.usr_id;
    const globalTransTypes = store.getState().mainReducer.transtypes;
    const receiptTransTypes = globalTransTypes.filter((tt) => tt.tt_type === 'Receipt Voucher' || tt.tt_type === 'Credit Note Client' || tt.tt_type === 'Debit Note Client');

    const [receipts, setReceipts] = useState([]);
    const [receiptsItems, setReceiptsItems] = useState([]);
    const [date, setDate] = useState(new Date());
    const [trancactionType, setTransactionType] = useState("");
    const [id, setId] = useState(null);
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [referenceNbr, setReferenceNbr] = useState("");
    const [note, setNote] = useState("");
    const [thirdPartyEmp, setThirdPartyEmp] = useState(null);
    const [thirdParty, setThirdParty] = useState(null);
    const [valueDate, setValueDate] = useState(null);
    const [docUrl, setDocUrl] = useState("");
    const [ratesToAndFrom, setRatesToAndFrom] = useState([]);
    const [viewerPopup, setViewerPopup] = useState(false);
    const [report, setReport] = useState();
    const [company, setCompany] = useState([]);
    const [firstCurr, setFirstCurr] = useState();
    const [secondCurr, setSecondCurr] = useState();
    const [tempRec, setTempRec] = useState([]);
    const paymentMethod = useRef([]);
    const username = useRef([]);
    const thirdPartiesEmp = useRef([]);
    const thirdparty = useRef([]);
    const division = useRef([]);
    const branches = useRef([]);
    const projects = useRef([]);
    const currencies = useRef([]);
    const datagridRef = useRef(null);
    const currency = useRef([]);

    useEffect(() => {
        setIsLoading(true);
        getCompany(globalCmpId).then((x) => setCompany(x));
        getUser(globalUsrId).then((x) => {
            x.unshift({
                user_id: null,
                user_username: "",
            })
            username.current = x;
        });
        getCurrencies(globalCmpId).then((x) => {
            x.unshift({
                cur_id: null,
                cur_code: ""
            })
            currency.current = x;
        });
        getPaymentMethods(globalCmpId).then((x) => {
            x.unshift({
                pmt_id: null,
                pmt_name: "",
                pmt_cur_id: null,
            })
            paymentMethod.current = x;
        });
        getBranches(globalCmpId).then((x) => {
            x.unshift({
                bra_id: null,
                bra_name: ''
            })
            branches.current = x;
        });
        getProjects(globalCmpId).then((x) => {
            x.unshift({
                prj_id: null,
                prj_name: ''
            })
            projects.current = x;
        });
        getDivisions(globalCmpId).then((x) => {
            x.unshift({
                div_id: null,
                div_name: ''
            })
            division.current = x;
        });
        getThirdpartiesType("Employee", globalCmpId).then((x) => {
            x.unshift({
                tp_id: null,
                tp_name: ''
            })
            thirdPartiesEmp.current = x;
        });
        getThirdpartiesType("Client", globalCmpId).then((x) => {
            x.unshift({
                tp_id: null,
                tp_name: ''
            })
            thirdparty.current = x;
        });
        getCurrencies(globalCmpId).then((x) => {
            currencies.current = x;
            if (x.length > 0) {
                let cur1, cur2;
                for (let cur of x) {
                    if (cur.cur_priority === 1) {
                        cur1 = cur;
                        setFirstCurr(cur);
                    };
                    if (cur.cur_priority === 2) {
                        cur2 = cur;
                        setSecondCurr(cur);
                    }
                }
                getRatesToAndFrom(cur1.cur_id, cur2.cur_id).then((x) => setRatesToAndFrom(x));
            }
        });
        getUpdatedReceiptItems();

        return () => setData({});
    }, [])

    const getUpdatedReceiptItems = () => {
        getReceipts(globalCmpId).then((x) => {
            setReceipts(x);
            setIsLoading(false);
            setTempRec(x);
        });
    }

    useEffect(() => {
        if (data !== null && data !== undefined && data !== "" && data.toPage === "Receipt") {
            setDate(data.rec_date);
            setTransactionType(data.rec_tt_code);
            setStatus(data.rec_status);
            setDescription(data.rec_description);
            setReferenceNbr(data.rec_referencenb);
            setNote(data.rec_note);
            setThirdParty(data.rec_tp_id);
            setValueDate(data.rec_valuedate);
            setThirdPartyEmp(data.rec_employee);
            setDocUrl(data.rec_docurl);
            setId(data.rec_id);
        }
    }, []);

    const getID = (e) => {
        setIsLoading(true);
        if (e.value !== null && e.value !== undefined && e.value !== "") {
            getReceiptItems(e.value).then((x) => {

                for (let rec of receipts) {
                    if (e.value === rec.rec_id) {
                        setDate(rec.rec_date);
                        setTransactionType(rec.rec_tt_code);
                        setStatus(rec.rec_status);
                        setDescription(rec.rec_description);
                        setReferenceNbr(rec.rec_referencenb);
                        setNote(rec.rec_note);
                        setThirdParty(rec.rec_tp_id);
                        setValueDate(rec.rec_valuedate);
                        setThirdPartyEmp(rec.rec_employee);
                        setDocUrl(rec.rec_docurl);
                        setTrxReport(rec, x);
                        break;
                    }
                }
                setReceiptsItems(x)
            }, setIsLoading(false));
        }
        else {
            setReceiptsItems([]);
            setIsLoading(false);
        }
        setId(e.value);
    }

    const setTP = (e) => {
        if (e.value === null) {
            setTempRec(receipts);
            onReset();
        } else if (e.value !== null && thirdParty === null) {
            setThirdParty(e.value);
            const recId = receiptTransTypes.find((tt) => tt.tt_type === 'Receipt Voucher')?.tt_code;
            setTransactionType(recId);

            const newRec = [];
            for (let trxnb of receipts) {
                if (trxnb.rec_tp_id === e.value) {
                    newRec.push(trxnb);
                }
            }
            setTempRec(newRec);
        } else if (e.value !== null && thirdParty !== null) {
            onReset();
            setThirdParty(e.value);
            const recId = receiptTransTypes.find((tt) => tt.tt_type === 'Receipt Voucher')?.tt_code;
            setTransactionType(recId);

            const newRec = [];
            for (let trxnb of receipts) {
                if (trxnb.rec_tp_id === e.value) {
                    newRec.push(trxnb);
                }
            }
            setTempRec(newRec);
        }
    }

    const displayTypeTrxnb = (option) => {
        if (option !== null) {
            for (let tt of globalTransTypes) {
                if (tt.tt_code === option.rec_tt_code) {
                    return tt.tt_newcode + "-" + option.rec_trxnb;
                }
            }
        }
        return "";
    }

    const reit_item_row = {
        reit_rec_id: "",
        reit_pmt_id: "",
        reit_cur_id: "",
        reit_amount: "",
        reit_amountf: "",
        reit_amounts: "",
        reit_bank: "",
        reit_cheque: "",
        reit_date: "",
        reit_bra_id: "",
        reit_prj_id: "",
        reit_note: "",
        reit_user_id: "",
        reit_div_id: "",
        reit_tax: "",
        reit_denom: "",
        reit_count: "",
    }

    const onReset = () => {
        setReceiptsItems([]);
        setDate(new Date());
        setTransactionType(null);
        setStatus("");
        setDescription("");
        setReferenceNbr("");
        setNote("");
        setThirdParty(null);
        setValueDate(null);
        setThirdPartyEmp(null);
        setDocUrl("");
        setId(null);
        datagridRef.current.instance.cancelEditData();
    }

    const printPriview = () => {
        if (id !== null) {
            setViewerPopup(true);
        } else {
            swal("Info", "Please select an Invoice Number to print.", "info");
            return;
        }
    }

    const setTrxReport = (item, items) => {
        const dispReceipts = JSON.parse(JSON.stringify(items));

        const rows = dispReceipts.map((it, i) => {
            const cur = currency.current.find((cur) => cur.cur_id === it.reit_cur_id)?.cur_code;
            const payment = paymentMethod.current.find((ra) => ra.pmt_id === it.reit_pmt_id)?.pmt_name;
            const tableNote = it.reit_note === null || it.reit_note === undefined ? "" : it.reit_note;
            return (
                `<tr>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ payment + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ cur + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ convertDateOnly(it.reit_date) + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ tableNote + `</td>
              <td style="border-top-color: black; border-top-style: solid;">`+ it.reit_amount + `</td>
            </tr>`
            )
        })

        const table = `
            <tr class="receipt-items-table-header">
                <th style="border-right-color: black; border-right-style: solid;">Payment Method</th>
                <th style="border-right-color: black; border-right-style: solid;">Currency</th>
                <th style="border-right-color: black; border-right-style: solid;">Date</th>
                <th style="border-right-color: black; border-right-style: solid;">Note</th>
                <th>Amount</th>
            </tr>
            ${rows}
        `;

        fetch("/assets/reports/receipt.html").then(result => {
            return result.text();
        }).then(page => {
            var mapObj = {
                companyaddressvalue: company[0]?.cmp_address === null || company[0]?.cmp_address === "" ? " " : company[0]?.cmp_address,
                companylogovalue: "/dataharbor.png",
                companyphonevalue: company[0]?.cmp_phonenumber === null || company[0]?.cmp_phonenumber === "" ? " " : company[0]?.cmp_phonenumber,
                receiptOrderNumber: receipts[0]?.rec_trxnb,
                receiptDate: convertDateOnly(receipts[0]?.rec_date),
                receiptEmployeeData: thirdPartiesEmp.current.find((rt) => rt.tp_id === receipts[0]?.rec_employee)?.tp_name,
                receiptUserData: username.current[1].user_name,
                receiptThirdPartyData: thirdparty.current.find((rc) => rc.tp_id === receipts[0]?.rec_tp_id)?.tp_name,
                receiptStatusData: receipts[0]?.rec_status === null || receipts[0]?.rec_status === undefined ? "" : receipts[0]?.rec_status,
                receiptNoteData: receipts[0]?.rec_note === null || receipts[0]?.rec_note === undefined ? "" : receipts[0]?.rec_note,
                receiptTypeData: receiptTransTypes.find((rt) => rt.tt_code === receipts[0]?.rec_tt_code)?.tt_type,
                receiptDescriptionData: receipts[0]?.rec_description === null || receipts[0]?.rec_description === undefined ? "" : receipts[0]?.rec_description,
                receiptRefrenceNumberData: receipts[0]?.rec_referencenb === null || receipts[0]?.rec_referencenb === undefined ? "" : receipts[0]?.rec_referencenb,
                tablepurchasevalue: table,
            };
            const newPage = page.replace(/receiptRefrenceNumberData|receiptDescriptionData|receiptTypeData|receiptNoteData|receiptStatusData|receiptThirdPartyData|receiptUserData|receiptEmployeeData|receiptDate|receiptOrderNumber|tablepurchasevalue|companyaddressvalue|companylogovalue|companyphonevalue/gi,
                (matched) => mapObj[matched]);
            setReport(newPage);
        });
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onEditiorPreparing = (e) => {

        if (e.dataField === "reit_pmt_id" && e.parentType === "dataRow") {
            e.editorOptions.min = null;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value === null) {
                    swal("Error", "Please put a Payment Method", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                for (let p of paymentMethod.current) {
                    if (p.pmt_id === args.value) {
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "reit_cur_id", p.pmt_cur_id)
                    }
                }

            }
        }

        if (e.dataField === "reit_amount" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value must be greater than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                if (datagridRef.current.instance.cellValue(e.row.rowIndex, "reit_cur_id") === firstCurr.cur_id) {
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "reit_amountf", args.value);
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "reit_amounts", args.value * ratesToAndFrom[0]?.rate_rate);
                } else if (datagridRef.current.instance.cellValue(e.row.rowIndex, "reit_cur_id") === secondCurr.cur_id) {
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "reit_amountf", args.value / ratesToAndFrom[0]?.rate_rate);
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "reit_amounts", args.value);
                }
            }
        }

        if (e.dataField === "reit_amountf" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value must be greater than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                datagridRef.current.instance.cellValue(e.row.rowIndex, "reit_amount", args.value);
                datagridRef.current.instance.cellValue(e.row.rowIndex, "reit_amounts", args.value * ratesToAndFrom[0]?.rate_rate);

            }
        }

        if (e.dataField === "reit_amounts" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value must be greater than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                datagridRef.current.instance.cellValue(e.row.rowIndex, "reit_amount", args.value / ratesToAndFrom[0]?.rate_rate);
                datagridRef.current.instance.cellValue(e.row.rowIndex, "reit_amountf", args.value / ratesToAndFrom[0]?.rate_rate);
            }
        }
    }

    const deleteReceipt = (e) => {
        if (id !== null) {
            const data = [];
            data.push(id);
            delReceipt(data);
        } else {
            swal("Info", "Please select an Receipt Number to delete.", "info");
            return;
        }
    }

    const roundNumber = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    const saveReceipt = (e) => {
        if (thirdParty === null || thirdParty === undefined || thirdParty === "") {
            swal("Info", "Please select a Client.", "info")
            return;
        }

        let rows = datagridRef.current.instance.getVisibleRows();
        let reitpmtid = datagridRef.current.instance.cellValue(0, "reit_pmt_id");

        if (rows.length === 0) {
            swal("Error", "Please add at least 1 item!", "error")
            return;
        }

        if (reitpmtid === undefined) {
            swal("Error", "Please add at least 1 item!", "error")
            return;
        }

        if (id === null || id === undefined || id === "") {
            const params = {};

            params.rec_cmp_id = globalCmpId;
            params.rec_date = date;
            params.rec_tt_code = trancactionType;
            params.rec_trxnb = null;
            params.rec_status = status;
            params.rec_description = description;
            params.rec_referencenb = referenceNbr;
            params.rec_note = note;
            params.rec_tp_id = thirdParty;
            params.rec_user_id = globalUsrId;
            params.rec_valuedate = valueDate;
            params.rec_employee = thirdPartyEmp;
            params.rec_docurl = docUrl;

            const data = [];
            data.push(params);

            const iparams = [];
            for (let i of rows) {
                const param = {};
                param.reit_cur_id = i.data.reit_cur_id;
                param.reit_pmt_id = i.data.reit_pmt_id;
                param.reit_amount = i.data.reit_amount;
                param.reit_amountf = i.data.reit_amountf;
                param.reit_amounts = i.data.reit_amounts;
                param.reit_bank = i.data.reit_bank;
                param.reit_cheque = i.data.reit_cheque;
                param.reit_date = i.data.reit_date;
                param.reit_bra_id = i.data.reit_bra_id;
                param.reit_prj_id = i.data.reit_prj_id;
                param.reit_note = i.data.reit_note;
                param.reit_user_id = i.data.reit_user_id;
                param.reit_div_id = i.data.reit_div_id;
                param.reit_tax = i.data.reit_tax;
                param.reit_denom = i.data.reit_denom;
                param.reit_count = i.data.reit_count;
                iparams.push(param);
            }
            addReceipt(data, iparams);
        } else {

            const params = {};

            params.rec_id = id;
            params.rec_cmp_id = globalCmpId;
            params.rec_date = date;
            params.rec_tt_code = trancactionType;
            params.rec_trxnb = receipts.filter((option) => option.rec_id === id)[0].rec_trxnb;
            params.rec_status = status;
            params.rec_description = description;
            params.rec_referencenb = referenceNbr;
            params.rec_note = note;
            params.rec_tp_id = thirdParty;
            params.rec_user_id = globalUsrId;
            params.rec_valuedate = valueDate;
            params.rec_employee = thirdPartyEmp;
            params.rec_docurl = docUrl;

            const data = [];
            data.push(params);
            updReceipt(data);

            let rows = datagridRef.current.instance.getVisibleRows();
            const updparams = [];
            const delparams = [];
            const addparams = [];

            for (let row of rows) {
                if (row.removed) {
                    delparams.push(row.data.reit_id);
                    continue;
                }
                if (row.data.reit_rec_id) {
                    const param = {};
                    param.reit_id = row.data.reit_id;
                    param.reit_rec_id = row.data.reit_rec_id;
                    param.reit_pmt_id = row.data.reit_pmt_id;
                    param.reit_cur_id = row.data.reit_cur_id;
                    param.reit_amount = row.data.reit_amount;
                    param.reit_amountf = row.data.reit_amountf;
                    param.reit_amounts = row.data.reit_amounts;
                    param.reit_bank = row.data.reit_bank;
                    param.reit_cheque = row.data.reit_cheque;
                    param.reit_date = row.data.reit_date;
                    param.reit_bra_id = row.data.reit_bra_id;
                    param.reit_prj_id = row.data.reit_prj_id;
                    param.reit_note = row.data.reit_note;
                    param.reit_user_id = row.data.reit_user_id;
                    param.reit_div_id = row.data.reit_div_id;
                    param.reit_tax = row.data.reit_tax;
                    param.reit_denom = row.data.reit_denom;
                    param.reit_count = row.data.reit_count;
                    updparams.push(param);
                } else {
                    const param = {};
                    param.reit_cur_id = row.data.reit_cur_id;
                    param.reit_pmt_id = row.data.reit_pmt_id;
                    param.reit_amount = row.data.reit_amount;
                    param.reit_amountf = row.data.reit_amountf;
                    param.reit_amounts = row.data.reit_amounts;
                    param.reit_bank = row.data.reit_bank;
                    param.reit_cheque = row.data.reit_cheque;
                    param.reit_date = row.data.reit_date;
                    param.reit_bra_id = row.data.reit_bra_id;
                    param.reit_prj_id = row.data.reit_prj_id;
                    param.reit_note = row.data.reit_note;
                    param.reit_user_id = row.data.reit_user_id;
                    param.reit_div_id = row.data.reit_div_id;
                    param.reit_tax = row.data.reit_tax;
                    param.reit_denom = row.data.reit_denom;
                    param.reit_count = row.data.reit_count;
                    addparams.push(param);
                }
            };

            if (delparams.length > 0) {
                delReceiptItem(delparams);
            }
            if (addparams.length > 0) {
                addReceiptItem(addparams, id);
            }
            if (updparams.length > 0) {
                updReceiptItem(updparams);
            }
        }
    }

    const addReceipt = (params, iparams) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}receipts/add_receipt`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    addReceiptItem(iparams, response.rec_id[0][0].rec_id);
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedReceiptItems();
            });
    }

    const addReceiptItem = (params, id) => {
        for (let it of params)
            it.reit_rec_id = id;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}receiptitems/add_receiptitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).catch((err) => console.log(err));
    }

    const updReceipt = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}receipts/upd_receipt`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedReceiptItems();
            });
    };

    const updReceiptItem = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}receiptitems/upd_receiptitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    }

    const delReceipt = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}receipts/del_receipt`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedReceiptItems();
            });
    };

    const delReceiptItem = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}receiptitems/del_receiptitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const formatOptions = {
        type: "fixedPoint",
        precision: 2
    };

    const formatInt = {
        type: "fixedPoint",
        precision: 0
    };

    const renderColumns = () => {
        const res = [];

        for (var key in reit_item_row) {
            if (reit_item_row.hasOwnProperty(key)) {
                if ("reit_rec_id" === key) {
                    res.push(
                        <Column
                            dataField="reit_rec_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("reit_pmt_id" === key) {
                    res.push(
                        <Column
                            dataField="reit_pmt_id"
                            key={key}
                            visible={true}
                            caption="Payment Method"
                        >
                            <Lookup dataSource={paymentMethod.current} displayExpr="pmt_name" valueExpr="pmt_id" />
                        </Column>
                    );
                } else if ("reit_cur_id" === key) {
                    res.push(
                        <Column
                            dataField="reit_cur_id"
                            key={key}
                            caption="Currency"
                            visible={true}
                        >
                            <Lookup dataSource={currency.current} displayExpr="cur_code" valueExpr="cur_id" />
                        </Column>
                    );
                } else if ("reit_amount" === key) {
                    res.push(
                        <Column
                            dataField="reit_amount"
                            key={key}
                            visible={true}
                            caption="Amount"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("reit_amountf" === key) {
                    res.push(
                        <Column
                            dataField="reit_amountf"
                            key={key}
                            caption="Amount F"
                            visible={true}
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("reit_amounts" === key) {
                    res.push(
                        <Column
                            dataField="reit_amounts"
                            key={key}
                            visible={true}
                            caption="Amount S"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("reit_bank" === key) {
                    res.push(
                        <Column
                            dataField="reit_bank"
                            key={key}
                            visible={true}
                            caption="Bank"
                            dataType="string"
                        ></Column>
                    );
                } else if ("reit_cheque" === key) {
                    res.push(
                        <Column
                            dataField="reit_cheque"
                            key={key}
                            visible={true}
                            caption="Cheque"
                            dataType="string"
                        ></Column>
                    );
                } else if ("reit_date" === key) {
                    res.push(
                        <Column
                            dataField="reit_date"
                            key={key}
                            visible={true}
                            caption="Date"
                            dataType="date"
                        ></Column>
                    );
                } else if ("reit_bra_id" === key) {
                    res.push(
                        <Column
                            dataField="reit_bra_id"
                            key={key}
                            visible={true}
                            caption="Branch"
                        >
                            <Lookup displayExpr="bra_name" valueExpr="bra_id" dataSource={branches.current} />
                        </Column>
                    );
                } else if ("reit_prj_id" === key) {
                    res.push(
                        <Column
                            dataField="reit_prj_id"
                            key={key}
                            visible={true}
                            caption="Project"
                        >
                            <Lookup displayExpr="prj_name" valueExpr="prj_id" dataSource={projects.current} />
                        </Column>
                    );
                } else if ("reit_note" === key) {
                    res.push(
                        <Column
                            dataField="reit_note"
                            key={key}
                            visible={true}
                            caption="Note"
                            dataType="string"
                        ></Column>
                    );
                } else if ("reit_user_id" === key) {
                    res.push(
                        <Column
                            dataField="reit_user_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("reit_div_id" === key) {
                    res.push(
                        <Column
                            dataField="reit_div_id"
                            key={key}
                            visible={true}
                            caption="Division"
                        >
                            <Lookup dataSource={division.current} valueExpr="div_id" displayExpr="div_name" />
                        </Column>
                    );
                } else if ("reit_tax" === key) {
                    res.push(
                        <Column
                            dataField="reit_tax"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("reit_denom" === key) {
                    res.push(
                        <Column
                            dataField="reit_denom"
                            key={key}
                            visible={true}
                            caption="Denomination"
                            dataType="number"
                            alignment="left"
                            format={formatInt}
                        ></Column>
                    );
                } else if ("reit_count" === key) {
                    res.push(
                        <Column
                            dataField="reit_count"
                            key={key}
                            visible={true}
                            caption="Count"
                            dataType="number"
                            alignment="left"
                            format={formatInt}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <div className="groups">
                    <div className="groups-btns">
                        <Button
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                            text="Save"
                            width={100}
                            onClick={saveReceipt}
                        />
                        <Button
                            text="Reset"
                            width={100}
                            onClick={onReset}
                        />
                        <Button
                            text="Print"
                            width={100}
                            onClick={printPriview}
                        />
                        <Button
                            text="Delete"
                            width={100}
                            onClick={deleteReceipt}
                        />
                    </div>
                </div>
                <div className="maingroup">
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Client</InputLabel>
                        <SelectBox
                            value={thirdParty}
                            displayExpr="tp_name"
                            valueExpr="tp_id"
                            placeholder="Client"
                            width={200}
                            onValueChanged={setTP}
                            dataSource={thirdparty.current}
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Transaction Type</InputLabel>
                        <SelectBox
                            dataSource={receiptTransTypes}
                            displayExpr="tt_type"
                            valueExpr="tt_code"
                            onValueChanged={(e) => setTransactionType(e.value)}
                            placeholder="Transaction Type"
                            value={trancactionType}
                            width={200}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Receipt Number</InputLabel>
                        <SelectBox
                            displayExpr={displayTypeTrxnb}
                            valueExpr="rec_id"
                            placeholder="Receipt Number"
                            onValueChanged={getID}
                            dataSource={tempRec}
                            value={id}
                            width={200}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Status</InputLabel>
                        <TextBox
                            value={status}
                            placeholder="Status"
                            onValueChanged={(e) => setStatus(e.value)}
                            width={200}
                        />
                        <InputLabel sx={{ color: "white" }}>Refrence</InputLabel>
                        <TextBox
                            value={referenceNbr}
                            placeholder="Refrence"
                            onValueChanged={(e) => setReferenceNbr(e.value)}
                            width={200}
                        />
                        <InputLabel sx={{ color: "white" }}>Note</InputLabel>
                        <TextBox
                            value={note}
                            placeholder="Note"
                            onValueChanged={(e) => setNote(e.value)}
                            width={200}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Date</InputLabel>
                        <DateBox
                            value={date}
                            placeholder="Date"
                            onValueChanged={(e) => setDate(e.value)}
                            width={200}
                            type="datetime"
                        />
                        <InputLabel sx={{ color: "white" }}>Value Date</InputLabel>
                        <DateBox
                            value={valueDate}
                            onValueChanged={(e) => setValueDate(e.value)}
                            placeholder="Value Date"
                            width={200}
                            type="datetime"
                        />
                        <InputLabel sx={{ color: "white" }}>Doc Url</InputLabel>
                        <TextBox
                            value={docUrl}
                            placeholder="Doc Url"
                            onValueChanged={(e) => setDocUrl(e.value)}
                            width={200}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Employee</InputLabel>
                        <SelectBox
                            placeholder="Employee"
                            dataSource={thirdPartiesEmp.current}
                            value={thirdPartyEmp}
                            onValueChanged={(e) => setThirdPartyEmp(e.value)}
                            valueExpr="tp_id"
                            displayExpr="tp_name"
                            width={200}
                            showClearButton
                        />
                        <InputLabel sx={{ color: "white" }}>Description</InputLabel>
                        <TextArea
                            value={description}
                            placeholder="Description"
                            onValueChanged={(e) => setDescription(e.value)}
                            width={250}
                            height={114}
                            maxLength={300}
                        />
                    </div>
                </div>
                <div>
                    <Dialog
                        open={viewerPopup}
                        TransitionComponent={Transition}
                        keepMounted
                        fullWidth
                        maxWidth="md"
                        onClose={() => setViewerPopup(false)}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <DialogTitle>Receipt</DialogTitle>
                            <Button className="button in_button2" onClick={handlePrint}>Print</Button>
                            <div className="me-4 cursor" onClick={() => setViewerPopup(false)}>
                                <ClearIcon />
                            </div>
                        </div>
                        <div ref={componentRef} dangerouslySetInnerHTML={{ __html: report }} />
                    </Dialog>
                </div>
                <div style={{ marginTop: 14 }}></div>
                <DataGrid
                    className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    onEditorPreparing={onEditiorPreparing}
                    ref={datagridRef}
                    dataSource={receiptsItems}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <Toolbar>
                        <Item location="after" visible={thirdParty !== null} name="addRowButton" />
                        <Item location="after" visible={false} name="saveButton" />
                        <Item location="after" visible={true} name="revertButton" />
                        <Item location="after" visible={true} name="searchPanel" />
                        <Item location="before" visible={true} name="groupPanel" />
                        <Item location="before" visible={true} name="columnChooserButton" />
                    </Toolbar>
                    <FilterBuilder defaultFields={reit_item_row} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        )
    }
    return <div className="table-container">{renderDataGrid()}</div>;
};

export default Receipt;