import "./ReportViewer.css";
import "../../GlobalCSS.css";
import { Viewer } from "@grapecity/activereports-react";

const ReportViewer = ({ openDrawer }) => {

    return (
        <div id="viewer-host" className={openDrawer ? 'shiftReportRight' : 'shiftReportLeft'}>
            <Viewer report={{ Uri: '/assets/reports/invoice.rdlx-json' }} />
        </div>
    );
};

export default ReportViewer; 