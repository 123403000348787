import '../../GlobalCSS.css'
import './Rate.css'
import { useState, useEffect } from 'react';
import swal from "sweetalert";
import * as global from '../../utils/globals';
import { getCurrencies, getRates } from '../../utils/helper';
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
} from "devextreme-react/data-grid";
import SelectBox from 'devextreme-react/select-box';
import DataGrid from "devextreme-react/data-grid";
import { store } from '../../store';

const Rate = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const [rates, setRates] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [tempCurrency, setTempCurrency] = useState([]);
    const [id, setID] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        getUpdatedCur();
    }, [])

    const getUpdatedCur = () => {
        getCurrencies(globalCmpId).then((x) => {
            setCurrency(x);
            setIsLoading(false);
        });
    }

    const getID = (e) => {
        setIsLoading(true);
        const newCurrency = currency.filter((id) => id.cur_id !== e.value)
        setTempCurrency(newCurrency);
        getRates(e.value).then((x) => setRates(x), setIsLoading(false));
        setID(e.value)
    }

    const rate_rows = {
        rate_id: "",
        rate_cur_id1: "",
        rate_cur_id2: "",
        rate_rate: "",
        rate_date: "",
    }

    const addRate = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}rates/add_rate`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedCur();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updRate = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}rates/upd_rate`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedCur();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delRate = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}rates/del_rate`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedCur();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedRate = [];
            const updatedRate = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.rate_id);
                } else if (row.type === "insert") {
                    addedRate.push({ ...row.data, rate_cur_id1: id });
                } else {
                    const obj = { ...row.key, ...row.data, rate_cur_id1: id };
                    updatedRate.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delRate(deletedIds);
            }
            if (addedRate.length > 0) {
                addRate(addedRate);
            }
            if (updatedRate.length > 0) {
                updRate(updatedRate);
            }
        }
    };


    const renderColumns = () => {
        const res = [];

        for (var key in rate_rows) {
            if (rate_rows.hasOwnProperty(key)) {
                if ("rate_id" === key) {
                    res.push(
                        <Column
                            dataField="rate_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("rate_cur_id1" === key) {
                    res.push(
                        <Column
                            dataField="rate_cur_id1"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("rate_cur_id2" === key) {
                    res.push(
                        <Column
                            dataField="rate_cur_id2"
                            caption="Rate Currency"
                            key={key}
                            visible={true}
                        >
                            <Lookup dataSource={tempCurrency} valueExpr='cur_id' displayExpr="cur_code" />
                        </Column>
                    );
                } else if ("rate_rate" === key) {
                    res.push(
                        <Column
                            dataField="rate_rate"
                            caption="Rate"
                            dataType="number"
                            alignment="left"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("rate_date" === key) {
                    res.push(
                        <Column
                            dataField="rate_date"
                            caption="Date"
                            key={key}
                            visible={true}
                            dataType='datetime'
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <SelectBox
                    dataSource={currency}
                    displayExpr="cur_code"
                    valueExpr="cur_id"
                    placeholder="Main Currency"
                    onValueChanged={getID}
                    value={id}
                    width={200}
                    className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                />
                <div style={{ marginTop: 14 }}></div>
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    dataSource={rates}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <FilterBuilder defaultFields={rate_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default Rate;