import React, { useRef, useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
    Toolbar,
    Item,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css"
import {
    getProductionOrderItems, getProductionOrders,
    getBranches, getProjects, getItems, getDivisions,
    getThirdpartiesType, getWarehouses
} from "../../utils/helper";
import { Button, DateBox, SelectBox, TextBox } from "devextreme-react";
import { InputLabel } from "@mui/material";
import "./ProductionOrders.css"
import { store } from "../../store";

const ProductionOrders = ({ openDrawer, data, setData, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalUsrId = store.getState().mainReducer.usr_id;
    const globalTransTypes = store.getState().mainReducer.transtypes;
    const ProductionOrdersTransTypes = globalTransTypes.filter((tt) => tt.tt_type === 'Production Order' || tt.tt_type === 'Production Order');

    const [productionOrders, setProductionOrders] = useState([]);
    const [productionOrderItems, setProductionOrderItems] = useState([]);
    const [productionDate, setProductionDate] = useState(new Date());
    const [productionOrderNumber, setProductionOrderNumber] = useState("");
    const [trancactionType, setTransactionType] = useState(null);
    const [id, setId] = useState(null);
    const [note, setNote] = useState("");
    const [valeuDate, setValueDate] = useState(null);
    const [ware, setWare] = useState(null);
    const [bra, setBra] = useState(null);
    const [proj, setProj] = useState(null);

    const warehouse = useRef([]);
    const division = useRef([]);
    const branch = useRef([]);
    const project = useRef([]);
    const thirdParties = useRef([]);
    const item = useRef([]);
    const datagridRef = useRef(null);

    useEffect(() => {
        setIsLoading(true);
        getUpdatedProductionOrderItems();
        getWarehouses(globalCmpId).then((x) => {
            x.unshift({
                wa_id: null,
                wa_name: ''
            })
            warehouse.current = x;
            setWare(x[1].wa_id);
        });
        getBranches(globalCmpId).then((x) => {
            x.unshift({
                bra_id: null,
                bra_name: ''
            })
            branch.current = x;
            setBra(x[1].bra_id);
        });
        getDivisions(globalCmpId).then((x) => {
            x.unshift({
                div_id: null,
                div_name: ''
            })
            division.current = x;
        });
        getProjects(globalCmpId).then((x) => {
            x.unshift({
                prj_id: null,
                prj_name: ''
            })
            project.current = x;
            setProj(x[1].prj_id);
        });
        getThirdpartiesType("Production Order", globalCmpId).then((x) => {
            x.unshift({
                tp_id: null,
                tp_name: '',
                tp_type: ""
            })
            thirdParties.current = x;
        });
        getItems(globalCmpId).then((x) => {
            x.unshift({
                it_id: null,
                it_name: ''
            })
            item.current = x;
            setIsLoading(false);
        });
    }, []);

    const getUpdatedProductionOrderItems = () => {
        getProductionOrders(globalCmpId).then((x) => {
            setProductionOrders(x);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (data !== null && data !== undefined && data !== "" && data.toPage === "ProductionOrders") {
            setWare(data.prod_wa_id);
            setTransactionType(data.prod_tt_code);
            setBra(data.prod_bra_id);
            setProductionDate(data.prod_date);
            setProductionOrderNumber(data.prod_ordernumber);
            setNote(data.prod_note);
            setValueDate(data.prod_valuedate);
            setProj(data.prod_prj_id);
            setId(data.prod_id);
        }
    }, []);

    const getID = (e) => {
        setIsLoading(true);
        if (e.value !== null && e.value !== undefined && e.value !== "") {
            getProductionOrderItems(e.value).then((x) => setProductionOrderItems(x), setIsLoading(false));

            for (let prod of productionOrders) {
                if (e.value === prod.prod_id) {
                    setWare(prod.prod_wa_id);
                    setTransactionType(prod.prod_tt_code);
                    setBra(prod.prod_bra_id);
                    setProductionDate(prod.prod_date);
                    setProductionOrderNumber(prod.prod_ordernumber);
                    setNote(prod.prod_note);
                    setValueDate(prod.prod_valuedate);
                    setProj(prod.prod_prj_id);
                    break;
                }
            }
        }
        else {
            setProductionOrderItems([]);
            setIsLoading(false);
        }
        setId(e.value);
    }

    const displayTypeCode = (option) => {
        if (option !== null) {
            for (let io of globalTransTypes) {
                if (io.tt_code === option.tt_code) {
                    return io.tt_newcode + "-" + option.tt_type;
                }
            }
        }
        return "";
    }

    const displayTypeTrxnb = (option) => {
        if (option !== null) {
            for (let io of globalTransTypes) {
                if (io.tt_code === option.prod_tt_code) {
                    return io.tt_newcode + "-" + option.prod_trxnb;
                }
            }
        }
        return "";
    }

    const onReset = () => {
        setProductionOrderItems([]);
        setWare(warehouse?.current[1].wa_id);
        setBra(branch?.current[1].bra_id);
        setProj(project?.current[1].prj_id);
        setProductionDate(new Date());
        setProductionOrderNumber("");
        setTransactionType(null);
        setNote("");
        setValueDate(null);
        setId(null);
        datagridRef.current.instance.cancelEditData();
    }

    const poit_item_row = {
        poit_id: "",
        poit_prod_id: "",
        poit_it_id: "",
        poit_qty: "",
        poit_status: "",
        poit_wa_id: "",
        poit_note: "",
        poit_it_idinpack: "",
        poit_qtyinpack: "",
        poit_cost: "",
        poit_mcurratef: "",
        poit_mcurrates: "",
        poit_remqty: "",
        poit_remqtywa: "",
        poit_timestamp: "",
        poit_user_id: "",
        poit_div_id: "",
        poit_date: "",
    }

    const deleteProductionOrder = () => {
        if (id !== null) {
            const data = [];
            data.push(id);
            delProductionOrder(data);
        } else {
            swal("Info", "Please select an Transaction Number to delete.", "info");
            return;
        }
    }

    const saveProductionOrder = (e) => {
        if (trancactionType === null || trancactionType === undefined || trancactionType === "") {
            swal("Info", "Please select a Transaction Type.", "info")
            return;
        }

        let rows = datagridRef.current.instance.getVisibleRows();

        if (rows.length === 0) {
            swal("Error", "Please add at least 1 item!", "error")
            return;
        }

        if (id === null || id === undefined || id === "") {
            const params = {};

            params.prod_cmp_id = globalCmpId;
            params.prod_ordernumber = productionOrderNumber;
            params.prod_wa_id = ware;
            params.prod_date = productionDate;
            params.prod_tt_code = trancactionType;
            params.prod_trxnb = null;
            params.prod_prj_id = proj;
            params.prod_bra_id = bra;
            params.prod_note = note;
            params.prod_user_id = globalUsrId;
            params.prod_valuedate = valeuDate;

            const data = [];
            data.push(params);

            const iparams = [];
            for (let items of rows) {
                const param = {};

                param.poit_it_id = items.data.poit_it_id;
                param.poit_qty = items.data.poit_qty;
                param.poit_note = items.data.poit_note;
                param.poit_status = items.data.poit_status;
                param.poit_cost = null;
                param.poit_wa_id = items.data.poit_wa_id;
                param.poit_remqtywa = null;
                param.poit_it_idinpack = null;
                param.poit_qtyinpack = null;
                param.poit_mcurratef = null;
                param.poit_mcurrates = null;
                param.poit_remqtywahio = null;
                param.poit_timestamp = null;
                param.poit_div_id = items.data.poit_div_id;
                param.poit_date = items.data.poit_date;
                param.poit_user_id = globalUsrId;

                iparams.push(param);
            };
            addProductionOrder(data, iparams);
        } else {
            const params = {};

            params.prod_id = id;
            params.prod_cmp_id = globalCmpId;
            params.prod_ordernumber = productionOrderNumber;
            params.prod_wa_id = ware;
            params.prod_date = productionDate;
            params.prod_tt_code = trancactionType;
            params.prod_trxnb = productionOrders.filter((option) => option.prod_id === id)[0].prod_trxnb;
            params.prod_prj_id = proj;
            params.prod_bra_id = bra;
            params.prod_note = note;
            params.prod_user_id = globalUsrId;
            params.prod_valuedate = valeuDate;

            const data = [];
            data.push(params);
            updProductionOrder(data);

            let rows = datagridRef.current.instance.getVisibleRows();
            const updparams = [];
            const delparams = [];
            const addparams = [];

            for (let row of rows) {
                if (row.removed) {
                    delparams.push(row.data.poit_id);
                    continue;
                }
                if (row.data.poit_prod_id) {
                    const param = {};

                    param.poit_id = row.data.poit_id;
                    param.poit_prod_id = row.data.poit_prod_id;
                    param.poit_it_id = row.data.poit_it_id;
                    param.poit_qty = row.data.poit_qty;
                    param.poit_note = row.data.poit_note;
                    param.poit_status = row.data.poit_status;
                    param.poit_cost = null;
                    param.poit_wa_id = row.data.poit_wa_id;
                    param.poit_remqtywa = null;
                    param.poit_it_idinpack = null;
                    param.poit_qtyinpack = null;
                    param.poit_mcurratef = null;
                    param.poit_mcurrates = null;
                    param.poit_remqtywahio = null;
                    param.poit_timestamp = null;
                    param.poit_div_id = row.data.poit_div_id;
                    param.poit_date = row.data.poit_date;
                    param.poit_user_id = globalUsrId;

                    updparams.push(param);
                } else {
                    const param = {};

                    param.poit_it_id = row.data.poit_it_id;
                    param.poit_qty = row.data.poit_qty;
                    param.poit_note = row.data.poit_note;
                    param.poit_status = row.data.poit_status;
                    param.poit_cost = null;
                    param.poit_wa_id = row.data.poit_wa_id;
                    param.poit_remqtywa = null;
                    param.poit_it_idinpack = null;
                    param.poit_qtyinpack = null;
                    param.poit_mcurratef = null;
                    param.poit_mcurrates = null;
                    param.poit_remqtywahio = null;
                    param.poit_timestamp = null;
                    param.poit_div_id = row.data.poit_div_id;
                    param.poit_date = row.data.poit_date;
                    param.poit_user_id = globalUsrId;

                    addparams.push(param);
                }
            };

            if (delparams.length > 0) {
                delProductionOrderItem(delparams);
            }
            if (addparams.length > 0) {
                addProductionOrderItems(addparams, id);
            }
            if (updparams.length > 0) {
                updProductionOrderItem(updparams);
            }

        }

    }

    const addProductionOrder = (params, iparams) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}productionorders/add_productionorder`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    addProductionOrderItems(iparams, response.prod_id[0][0].prod_id);
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedProductionOrderItems();
            });
    }

    const addProductionOrderItems = (params, id) => {
        for (let poit of params)
            poit.poit_prod_id = id;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}productionorderitems/add_productionorderitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).catch((err) => console.log(err));
    }

    const updProductionOrder = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}productionorders/upd_productionorder`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedProductionOrderItems();
            });
    };

    const updProductionOrderItem = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}productionorderitems/upd_productionorderitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    }

    const delProductionOrder = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}productionorders/del_productionorder`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                setIsLoading(true);
                getUpdatedProductionOrderItems();
            });
    };

    const delProductionOrderItem = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}productionorderitems/del_productionorderitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onEditiorPreparing = (e) => {

        if (e.dataField === "poit_it_id" && e.parentType === "dataRow") {
            e.editorOptions.min = null;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value === null) {
                    swal("Error", "Please put a Payment Method", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                for (let po of item.current) {
                    if (po.it_id === args.value) {
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "poit_qty", 1);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "poit_wa_id", po.it_wa_id);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "poit_div_id", po.it_div_id);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "poit_note", po.it_note);
                        break;
                    }
                }

            }
        }
    }

    const renderColumns = () => {
        const res = [];
        for (var key in poit_item_row) {
            if (poit_item_row.hasOwnProperty(key)) {
                if ("poit_id" === key) {
                    res.push(
                        <Column
                            dataField="poit_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("poit_prod_id" === key) {
                    res.push(
                        <Column
                            dataField="poit_prod_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("poit_it_id" === key) {
                    res.push(
                        <Column
                            dataField="poit_it_id"
                            key={key}
                            caption="Items"
                            visible={true}
                        >
                            <Lookup dataSource={item.current} valueExpr="it_id" displayExpr="it_name" />
                        </Column>
                    );
                } else if ("poit_qty" === key) {
                    res.push(
                        <Column
                            dataField="poit_qty"
                            key={key}
                            caption="Quantity"
                            visible={true}
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("poit_status" === key) {
                    res.push(
                        <Column
                            dataField="poit_status"
                            key={key}
                            visible={true}
                            caption="Status"
                        ></Column>
                    );
                } else if ("poit_wa_id" === key) {
                    res.push(
                        <Column
                            dataField="poit_wa_id"
                            key={key}
                            visible={true}
                            caption="Warehouse"
                        >
                            <Lookup dataSource={warehouse.current} displayExpr="wa_name" valueExpr="wa_id" />
                        </Column>
                    );
                } else if ("poit_note" === key) {
                    res.push(
                        <Column
                            dataField="poit_note"
                            key={key}
                            visible={true}
                            caption="Note"
                        ></Column>
                    );
                } else if ("poit_it_idinpack" === key) {
                    res.push(
                        <Column
                            dataField="poit_it_idinpack"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("poit_qtyinpack" === key) {
                    res.push(
                        <Column
                            dataField="poit_qtyinpack"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("poit_cost" === key) {
                    res.push(
                        <Column
                            dataField="poit_cost"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("poit_mcurratef" === key) {
                    res.push(
                        <Column
                            dataField="poit_mcurratef"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("poit_mcurrates" === key) {
                    res.push(
                        <Column
                            dataField="poit_mcurrates"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("poit_remqty" === key) {
                    res.push(
                        <Column
                            dataField="poit_remqty"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("poit_remqtywa" === key) {
                    res.push(
                        <Column
                            dataField="poit_remqtywa"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("poit_timestamp" === key) {
                    res.push(
                        <Column
                            dataField="poit_timestamp"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("poit_user_id" === key) {
                    res.push(
                        <Column
                            dataField="poit_user_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("poit_div_id" === key) {
                    res.push(
                        <Column
                            dataField="poit_div_id"
                            key={key}
                            visible={true}
                            caption="Division"
                        >
                            <Lookup dataSource={division.current} displayExpr="div_name" valueExpr="div_id" />
                        </Column>
                    );
                } else if ("poit_date" === key) {
                    res.push(
                        <Column
                            dataField="poit_date"
                            key={key}
                            visible={true}
                            caption="Date"
                            dataType="date"
                        ></Column>
                    );
                }
            }
        }
        return res;
    }

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <div className="groups">
                    <div className="groups-btns">
                        <Button
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                            text="Save"
                            onClick={saveProductionOrder}
                            width={100}
                        />
                        <Button
                            text="Reset"
                            width={100}
                            onClick={onReset}
                        />
                        <Button
                            text="Delete"
                            width={100}
                            onClick={deleteProductionOrder}
                        />
                        <Button
                            text="Print"
                            width={100}
                        />
                    </div>
                </div>
                <div className="maingroup">
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Date</InputLabel>
                        <DateBox
                            value={productionDate}
                            placeholder="Date"
                            onValueChanged={(e) => setProductionDate(e.value)}
                            width={200}
                            type="datetime"
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Type</InputLabel>
                        <SelectBox
                            placeholder="Type"
                            value={trancactionType}
                            dataSource={ProductionOrdersTransTypes}
                            onValueChanged={(e) => setTransactionType(e.value)}
                            displayExpr={displayTypeCode}
                            valueExpr="tt_code"
                            width={200}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Transaction Number</InputLabel>
                        <SelectBox
                            dataSource={productionOrders}
                            displayExpr={displayTypeTrxnb}
                            valueExpr="prod_id"
                            placeholder="Transaction Number"
                            width={200}
                            onValueChanged={getID}
                            value={id}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Value Date</InputLabel>
                        <DateBox
                            value={valeuDate}
                            placeholder="Value Date"
                            onValueChanged={(e) => setValueDate(e.value)}
                            width={200}
                            type="datetime"
                        />
                        <InputLabel sx={{ color: "white" }}>Project</InputLabel>
                        <SelectBox
                            placeholder="Project"
                            value={proj}
                            dataSource={project.current}
                            onValueChanged={(e) => setProj(e.value)}
                            width={200}
                            valueExpr="prj_id"
                            displayExpr="prj_name"
                        />
                        <InputLabel sx={{ color: "white" }}>Branch</InputLabel>
                        <SelectBox
                            placeholder="Branch"
                            value={bra}
                            dataSource={branch.current}
                            onValueChanged={(e) => setBra(e.value)}
                            width={200}
                            valueExpr="bra_id"
                            displayExpr="bra_name"
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Warehouse</InputLabel>
                        <SelectBox
                            value={ware}
                            placeholder="Warehouse"
                            dataSource={warehouse.current}
                            width={200}
                            onValueChanged={(e) => setWare(e.value)}
                            valueExpr="wa_id"
                            displayExpr="wa_name"
                        />
                        <InputLabel sx={{ color: "white" }}>Note</InputLabel>
                        <TextBox
                            value={note}
                            placeholder="Note"
                            width={200}
                            onValueChanged={(e) => setNote(e.value)}
                        />
                        <InputLabel sx={{ color: "white" }}>Order Number</InputLabel>
                        <TextBox
                            value={productionOrderNumber}
                            placeholder="Order Number"
                            width={200}
                            onValueChanged={(e) => setProductionOrderNumber(e.value)}
                        />
                    </div>
                </div>
                <div className="datagridcss">
                    <DataGrid
                        className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        onEditorPreparing={onEditiorPreparing}
                        ref={datagridRef}
                        dataSource={productionOrderItems}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        remoteOperations={false}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        columnMinWidth={70}
                        columnResizingMode={"widget"}
                    >
                        <Paging defaultPageSize={10} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[10, 50, 100, 500]}
                            showInfo={true}
                            infoText="Page {0}. Total: {1} ({2} Items)"
                        />
                        <Toolbar>
                            <Item location="after" visible={trancactionType !== null} name="addRowButton" />
                            <Item location="after" visible={false} name="saveButton" />
                            <Item location="after" visible={true} name="revertButton" />
                            <Item location="after" visible={true} name="searchPanel" />
                            <Item location="before" visible={true} name="groupPanel" />
                            <Item location="before" visible={true} name="columnChooserButton" />
                        </Toolbar>
                        <FilterBuilder defaultFields={poit_item_row} />
                        <FilterRow visible={true} />
                        <Sorting visible={true} />
                        <GroupPanel visible={true} />
                        <SearchPanel visible={true} />
                        <Editing
                            allowAdding={true}
                            allowUpdating={true}
                            allowDeleting={true}
                            confirmDelete={true}
                            mode="batch"
                            selectTextOnEditStart={true}
                            useIcons={true}
                        />
                        <ColumnChooser enabled={false} />
                        <Scrolling columnRenderingMode="virtual" />
                        <KeyboardNavigation
                            editOnKeyPress={true}
                            enterKeyAction="moveFocus"
                            enterKeyDirection="column"
                        />
                        {renderColumns()}
                    </DataGrid>
                </div>
            </div>
        )
    }

    return <div>{renderDataGrid()}</div>;

}

export default ProductionOrders;