import React, { useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import { getUnits } from "../../utils/helper";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css";
import { store } from "../../store";

const Units = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const [units, setUnits] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getUpdatedUnits();
    }, [])

    const getUpdatedUnits = () => {
        getUnits(globalCmpId).then((x) => {
            setUnits(x);
            setIsLoading(false);
        });
    }

    const Units_rows = {
        un_id: "",
        un_code: "",
        un_desc: "",
        un_cmp_id: "",
    };

    const addUnits = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}units/add_unit`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedUnits();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updUnits = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}units/upd_unit`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedUnits();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delUnits = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}units/del_unit`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedUnits();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedUnits = [];
            const updatedUnits = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.un_id);
                } else if (row.type === "insert") {
                    addedUnits.push({ ...row.data, un_cmp_id: globalCmpId });
                } else {
                    const obj = { ...row.key, ...row.data, un_cmp_id: globalCmpId };
                    updatedUnits.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delUnits(deletedIds);
            }
            if (addedUnits.length > 0) {
                addUnits(addedUnits);
            }
            if (updatedUnits.length > 0) {
                updUnits(updatedUnits);
            }
        }
    };

    const renderColumns = () => {
        const res = [];

        for (var key in Units_rows) {
            if (Units_rows.hasOwnProperty(key)) {
                if ("un_id" === key) {
                    res.push(
                        <Column
                            dataField="un_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("un_code" === key) {
                    res.push(
                        <Column
                            dataField="un_code"
                            caption="Code"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("un_cmp_id" === key) {
                    res.push(
                        <Column
                            dataField="un_cmp_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("un_desc" === key) {
                    res.push(
                        <Column
                            dataField="un_desc"
                            caption="Description"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    dataSource={units}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <FilterBuilder defaultFields={Units_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default Units;