import swal from "sweetalert";
import * as global from '../../utils/globals';
import '../../GlobalCSS.css';
import './PaymentMethod.css'
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import { store } from "../../store";
import { getCurrencies, getPaymentMethods } from "../../utils/helper";

const PaymentMethod = ({ openDrawer, setIsLoading }) => {

    const globalCmpId = store.getState().mainReducer.cmp_id;
    const [paymentMethods, setPaymentMethods] = useState([]);
    const currency = useRef([]);

    useEffect(() => {
        setIsLoading(true);
        getCurrencies(globalCmpId).then((x) => {
            x.unshift({
                cur_id: null,
                cur_code: ''
            })
            currency.current = x;
        });
        getUpdatedPmt();
    }, [])

    const getUpdatedPmt = () => {
        getPaymentMethods(globalCmpId).then((x) => {
            setPaymentMethods(x);
            setIsLoading(false);
        });
    }

    const PaymentMethod_rows = {
        pmt_id: "",
        pmt_name: "",
        pmt_cur_id: "",
        pmt_priority: "",
        pmt_image: "",
        pmt_type: "",
        pmt_commissionamt: "",
        pmt_commissionperc: "",
        pmt_cmp_id: "",
    };

    const pmt_type = [
        { id: "", name: "" },
        { id: "Cash", name: "Cash" },
        { id: "Cheque", name: "Cheque" },
        { id: "Credit Card", name: "Credit Card" },
        { id: "Gift Card", name: "Gift Card" },
        { id: "Cash Back", name: "Cash Back" },
    ];

    const addPaymentMethod = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}paymentmethods/add_paymentmethod`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedPmt();
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updPaymentMethod = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}paymentmethods/upd_paymentmethod`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedPmt();
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delPaymentMethod = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}paymentmethods/del_paymentmethod`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedPmt();
                } else {
                    console.log(response.error);
                    swal("Error", response.error.originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedPaymentMethod = [];
            const updatedPaymentMethod = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.pmt_id);
                } else if (row.type === "insert") {
                    addedPaymentMethod.push({ ...row.data, pmt_cmp_id: globalCmpId });
                } else {
                    const obj = { ...row.key, ...row.data, pmt_cmp_id: globalCmpId };
                    updatedPaymentMethod.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delPaymentMethod(deletedIds);
            }
            if (addedPaymentMethod.length > 0) {
                addPaymentMethod(addedPaymentMethod);
            }
            if (updatedPaymentMethod.length > 0) {
                updPaymentMethod(updatedPaymentMethod);
            }
        }
    };


    const renderColumns = () => {
        const res = [];

        for (var key in PaymentMethod_rows) {
            if (PaymentMethod_rows.hasOwnProperty(key)) {
                if ("pmt_id" === key) {
                    res.push(
                        <Column
                            dataField="pmt_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("pmt_name" === key) {
                    res.push(
                        <Column
                            dataField="pmt_name"
                            key={key}
                            visible={true}
                            caption="Name"
                            dataType="string"
                        ></Column>
                    );
                } else if ("pmt_cur_id" === key) {
                    res.push(
                        <Column
                            dataField="pmt_cur_id"
                            caption="Currency"
                            key={key}
                            visible={true}
                        >
                            <Lookup valueExpr="cur_id" displayExpr="cur_code" dataSource={currency.current} />
                        </Column>
                    );
                } else if ("pmt_priority" === key) {
                    res.push(
                        <Column
                            dataField="pmt_priority"
                            key={key}
                            visible={true}
                            caption="Priority"
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("pmt_image" === key) {
                    res.push(
                        <Column
                            dataField="pmt_image"
                            key={key}
                            visible={true}
                            caption="Image"
                        ></Column>
                    );
                } else if ("pmt_type" === key) {
                    res.push(
                        <Column
                            dataField="pmt_type"
                            key={key}
                            visible={true}
                            caption="Type"
                        >
                            <Lookup dataSource={pmt_type} valueExpr="id" displayExpr="name" />
                        </Column>
                    );
                } else if ("pmt_commissionamt" === key) {
                    res.push(
                        <Column
                            dataField="pmt_commissionamt"
                            caption="Commission Ammount"
                            key={key}
                            visible={true}
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("pmt_commissionperc" === key) {
                    res.push(
                        <Column
                            dataField="pmt_commissionperc"
                            caption="Commission Percent"
                            key={key}
                            visible={true}
                            dataType="number"
                            alignment="left"
                        ></Column>
                    );
                } else if ("pmt_cmp_id" === key) {
                    res.push(
                        <Column
                            dataField="pmt_cur_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    dataSource={paymentMethods}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <FilterBuilder defaultFields={PaymentMethod_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
};

export default PaymentMethod;