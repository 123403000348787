import React, { Fragment, useRef, useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import * as global from "../../utils/globals";
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
    Toolbar,
    Item,
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import "../../GlobalCSS.css"
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import {
    getPurchaseItems, getPurchases, getCurrencies, getItems,
    getWarehouses, getDivisions, getBranches, getProjects,
    getThirdpartiesType, getCompany, convertDateOnly
} from "../../utils/helper";
import { Button, DateBox, NumberBox, SelectBox, TextBox } from "devextreme-react";
import { Dialog, DialogTitle, InputLabel, Stack, Switch, Typography } from "@mui/material";
import "./Purchase.css"
import { store } from "../../store";
import { Viewer } from "@grapecity/activereports-react";
import ClearIcon from "@mui/icons-material/Clear";
import Slide from "@mui/material/Slide";
import { useReactToPrint } from "react-to-print";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const exportsSettings = {
    pdf: {
        title: "Invoice",
        author: "GrapeCity",
        subject: "Web Reporting",
        keywords: "reporting, sample",
        userPassword: "pwd",
        ownerPassword: "ownerPwd",
        printing: "none",
        copying: false,
        modifying: false,
        annotating: false,
        contentAccessibility: false,
        documentAssembly: false,
        pdfVersion: "1.7",
        autoPrint: true,
        filename: "Invoice.pdf",
    },
    html: {
        title: "Invoice",
        filename: "Invoice.html",
        autoPrint: true,
        multiPage: true,
        embedImages: "external",
        outputType: "html",
    },
};

// remove items from this array to hide unnecessary Exports Types
const availableExports = ["pdf", "html", "tabular-data"];

const Purchases = ({ openDrawer, data, setData, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const globalUsrId = store.getState().mainReducer.usr_id;
    const globalFirstCur = store.getState().mainReducer.cur_id1;
    const globalSecondCur = store.getState().mainReducer.cur_id2;
    const globalRates = store.getState().mainReducer.rates;
    const defaultCashClient = store.getState().mainReducer.tp_name;
    const globalTransTypes = store.getState().mainReducer.transtypes;
    const purchaseTransTypes = globalTransTypes.filter((tt) => tt.tt_type === 'Purchase Invoice' || tt.tt_type === 'Return Purchase' || tt.tt_type === 'Profoma Purchase' || tt.tt_type === 'Goods Receipt' || tt.tt_type === 'Goods Return');

    const [purchase, setPurchase] = useState([]);
    const [purchaseItem, setPurchaseItem] = useState([]);
    const [company, setCompany] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [id, setId] = useState(null);
    const [discperc, setDiscperc] = useState(0);
    const [note, setNote] = useState("");
    const [discamt, setDiscAmt] = useState(0);
    const [curr, setCurr] = useState(globalFirstCur);
    const [TotalTax, setTotalTax] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [branch, setBranch] = useState(null);
    const [project, setProject] = useState(null);
    const [thirdParties, setThirdParties] = useState(null);
    const [switchPaid, setSwitchPaid] = useState(true);
    const [ware, setWare] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [popup, setPopup] = useState(false);
    const [viwerPopup, setViewerPopup] = useState(false);
    const [change, setChange] = useState(null);
    const [report, setReport] = useState();
    const [tempPur, setTempPur] = useState([]);
    const [totalOne, setTotalOne] = useState(0);
    const [switchDelivered, setSwitchDelivered] = useState(true);
    const [trancactionType, setTransactionType] = useState("");
    const datagridRef = useRef(null);
    const viewerRef = useRef(<Viewer />);
    const items = useRef([]);
    const warehouse = useRef([]);
    const division = useRef([]);
    const branches = useRef([]);
    const projects = useRef([]);
    const thirdParty = useRef([]);
    const total = subTotal - discamt + TotalTax;


    useEffect(() => {
        setIsLoading(true);
        getCurrencies(globalCmpId).then((x) => setCurrency(x));
        getItems(globalCmpId).then((x) => {
            x.unshift({
                it_id: null,
                it_name: '',
                it_cur_id: '',
                it_unitprice: null,
                it_tax: null,
                it_tax1: null,
                it_tax2: null,
                it_wa_id: null,
                it_div_id: null,
                it_note: ''
            })
            items.current = x;
        });
        getCompany(globalCmpId).then((x) => setCompany(x));
        getDivisions(globalCmpId).then((x) => {
            x.unshift({
                div_id: null,
                div_name: ''
            })
            division.current = x;
        });
        getWarehouses(globalCmpId).then((x) => {
            x.unshift({
                wa_id: null,
                wa_name: ''
            })
            warehouse.current = x;
            setWare(x[1].wa_id);
        });
        getBranches(globalCmpId).then((x) => {
            x.unshift({
                bra_id: null,
                bra_name: ''
            })
            branches.current = x;
            setBranch(x[1].bra_id);
        });
        getProjects(globalCmpId).then((x) => {
            x.unshift({
                prj_id: null,
                prj_name: ''
            })
            projects.current = x;
            setProject(x[1].prj_id);
        });
        getThirdpartiesType("Supplier", globalCmpId).then((x) => {
            x.unshift({
                tp_id: null,
                tp_name: ''
            })
            thirdParty.current = x;
        });
        getUpdatedPurchaseItem();
        return () => setData({});
    }, [])

    const getUpdatedPurchaseItem = () => {
        getPurchases(globalCmpId).then((x) => {
            setPurchase(x);
            setIsLoading(false);
            setTempPur(x);
        });
    }

    const currencyChange = (e) => {
        const newIt = [];
        setCurr(e.value);
        if (purchaseItem.length > 0) {
            for (let it of purchaseItem) {
                for (let kit of items.current) {
                    if (kit.it_id === it.pit_it_id) {
                        for (let exc of globalRates) {
                            var newPrice = kit.it_unitprice;

                            if (kit.it_cur_id === e.value) {
                                it.pit_discountamount = newPrice * it.pit_qty * it.pit_discountperc / 100;
                                it.pit_total = newPrice * it.pit_qty - it.pit_discountamount;
                                it.pit_it_price = newPrice;
                                break;
                            }
                            else if (kit.it_cur_id === exc.rate_cur_id2 && e.value === exc.rate_cur_id1) {
                                newPrice = Math.round(((kit.it_unitprice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                it.pit_discountamount = newPrice * it.pit_qty * it.pit_discountperc / 100;
                                it.pit_total = newPrice * it.pit_qty - it.pit_discountamount;
                                it.pit_it_price = newPrice;
                                break;
                            } else if (kit.it_cur_id === exc.rate_cur_id1 && e.value === exc.rate_cur_id2) {
                                newPrice = Math.round(((kit.it_unitprice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                it.pit_discountamount = newPrice * it.pit_qty * it.pit_discountperc / 100;
                                it.pit_total = newPrice * it.pit_qty - it.pit_discountamount;
                                it.pit_it_price = newPrice;
                                break;
                            }

                            if (newPrice === kit.it_unitprice) {
                                for (let exc of globalRates) {
                                    if (e.value === exc.rate_cur_id1 && kit.it_cur_id !== e.value) {
                                        newPrice = Math.round(((newPrice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                        it.pit_discountamount = newPrice * it.pit_qty * it.pit_discountperc / 100;
                                        it.pit_total = newPrice * it.pit_qty - it.pit_discountamount;
                                        continue;
                                    } else if (e.value === exc.rate_cur_id2 && kit.it_cur_id !== e.value) {
                                        newPrice = Math.round(((newPrice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                        it.pit_discountamount = newPrice * it.pit_qty * it.pit_discountperc / 100;
                                        it.pit_total = newPrice * it.pit_qty - it.pit_discountamount;
                                        continue;
                                    } else if (kit.it_cur_id === exc.rate_cur_id2 && kit.it_cur_id !== e.value) {
                                        newPrice = Math.round(((newPrice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                        it.pit_discountamount = newPrice * it.pit_qty * it.pit_discountperc / 100;
                                        it.pit_total = newPrice * it.pit_qty - it.pit_discountamount;
                                        continue;
                                    } else if (kit.it_cur_id === exc.rate_cur_id1 && kit.it_cur_id !== e.value) {
                                        newPrice = Math.round(((newPrice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                        it.pit_discountamount = newPrice * it.pit_qty * it.pit_discountperc / 100;
                                        it.pit_total = newPrice * it.pit_qty - it.pit_discountamount;
                                        continue;
                                    }
                                }
                            }

                            it.pit_it_price = newPrice;
                        }
                        newIt.push(it);
                        break;
                    }
                }
            }
            setPurchaseItem([...newIt]);
        }
    }

    useEffect(() => {
        if (data !== null && data !== undefined && data !== "" && data.toPage === "Purchase") {
            setDiscperc(data.pur_discountperc);
            setCurr(data.pur_cur_id);
            setDiscAmt(data.pur_discountamount);
            setSubTotal(data.pur_subtotal);
            setTotalTax(data.pur_totaltax);
            setNote(data.pur_note);
            setBranch(data.pur_bra_id);
            setProject(data.pur_prj_id);
            setWare(data.pur_wa_id);
            setThirdParties(data.pur_tp_id);
            setSwitchPaid(data.pur_paid);
            setChange(data.pur_change);
            setSwitchDelivered(data.pur_delivered);
            setTransactionType(data.pur_tt_code);
            setDueDate(data.pur_duedate);
            setId(data.pur_id);
        }
    }, []);

    const getID = (e) => {
        setIsLoading(true);
        if (e.value !== null && e.value !== undefined && e.value !== "") {
            getPurchaseItems(e.value).then((x) => {
                for (let row of x) {
                    row.pit_total = row.pit_it_price * row.pit_qty - row.pit_discountamount;
                }

                for (let pur of purchase) {
                    if (e.value === pur.pur_id) {
                        setDiscperc(pur.pur_discountperc);
                        setCurr(pur.pur_cur_id);
                        setDiscAmt(pur.pur_discountamount);
                        setSubTotal(pur.pur_subtotal);
                        setTotalTax(pur.pur_totaltax);
                        setNote(pur.pur_note);
                        setBranch(pur.pur_bra_id);
                        setProject(pur.pur_prj_id);
                        setWare(pur.pur_wa_id);
                        setThirdParties(pur.pur_tp_id);
                        setSwitchPaid(pur.pur_paid);
                        setChange(pur.pur_change);
                        setSwitchDelivered(pur.pur_delivered);
                        setTransactionType(pur.pur_tt_code)
                        setDueDate(pur.pur_duedate);
                        setTrxReport(pur, x);
                        break;
                    }
                }

                setPurchaseItem(x)
            }, setIsLoading(false));
        }
        else {
            setPurchaseItem([]);
            setIsLoading(false);
        }
        setId(e.value);
    }

    const openPopup = () => {
        setPopup(true);
    };

    const handleSwitchPaid = (e) => {
        setSwitchPaid(e.target.checked);
    }

    const handleSwitchDelivered = (e) => {
        setSwitchDelivered(e.target.checked)
    }

    const setTP = (e) => {
        if (e.value === null) {
            setTempPur(purchase);
            onReset();
        } else if (e.value !== null && thirdParties === null) {
            setThirdParties(e.value);
            const puId = purchaseTransTypes.find((tt) => tt.tt_type === 'Purchase Invoice')?.tt_code;
            setTransactionType(puId);
            const newPur = [];
            for (let trxnb of purchase) {
                if (trxnb.pur_tp_id === e.value) {
                    newPur.push(trxnb);
                }
            }
            setTempPur(newPur);
        } else if (e.value !== null && thirdParties !== null) {
            onReset();
            setThirdParties(e.value);
            const puId = purchaseTransTypes.find((tt) => tt.tt_type === 'Purchase Invoice')?.tt_code;
            setTransactionType(puId);
            const newPur = [];
            for (let trxnb of purchase) {
                if (trxnb.pur_tp_id === e.value) {
                    newPur.push(trxnb);
                }
            }
            setTempPur(newPur);
        }
    }

    const onReset = () => {
        setPurchaseItem([]);
        setId(null);
        setDiscperc(null);
        setTotalOne(0);
        setCurr(globalFirstCur);
        setDiscAmt(null);
        setSubTotal(0);
        setTotalTax(0);
        setNote('');
        setBranch(branches?.current[1].bra_id);
        setProject(projects?.current[1].prj_id);
        setWare(warehouse?.current[1].wa_id);
        setThirdParties(null);
        setSwitchPaid(1);
        setChange(null);
        setSwitchDelivered(1);
        setDueDate(null);
        setTransactionType(null);
        datagridRef.current.instance.cancelEditData();
    }

    const printPreview = () => {
        if (id !== null) {
            setViewerPopup(true);
        } else {
            swal("Info", "Please select a Purchase Number to print.", "info");
            return;
        }
    }

    const setTrxReport = (item, itemss) => {
        const dispReceipts = JSON.parse(JSON.stringify(itemss));

        const rows = dispReceipts.map((it, i) => {
            const purit = items.current.find((pi) => pi.it_id === it.pit_it_id)?.it_name;
            return (
                `<tr>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ purit + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ it.pit_it_price + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ it.pit_qty + `</td>
              <td style="border-right-color: black; border-right-style: solid; border-top-color: black; border-top-style: solid;">`+ it.pit_discountamount + `</td>
              <td style="border-top-color: black; border-top-style: solid;">`+ it.pit_total + `</td>
            </tr>`
            )
        })

        const table = `
            <tr class="purchase-items-table-header">
                <th style="border-right-color: black; border-right-style: solid;">Name</th>
                <th style="border-right-color: black; border-right-style: solid;">Price</th>
                <th style="border-right-color: black; border-right-style: solid;">Quantity</th>
                <th style="border-right-color: black; border-right-style: solid;">Discount</th>
                <th>Total</th>
            </tr>
            ${rows}
        `;

        fetch("/assets/reports/purchase.html").then(result => {
            return result.text();
        }).then(page => {
            var mapObj = {
                companyaddressvalue: company[0]?.cmp_address === null || company[0]?.cmp_address === "" ? " " : company[0]?.cmp_address,
                companylogovalue: "/dataharbor.png",
                companyphonevalue: company[0]?.cmp_phonenumber === null || company[0]?.cmp_phonenumber === "" ? " " : company[0]?.cmp_phonenumber,
                purchaseDateData: convertDateOnly(purchase[0]?.pur_createddate),
                purchaseOrderNumberData: purchase[0]?.pur_trxnb,
                purchaseSupplierIDData: thirdParty.current.find((pt) => pt.tp_id === purchase[0]?.pur_tp_id)?.tp_name,
                purchaseDeliveryDateData: convertDateOnly(purchase[0]?.pur_duedate),
                purchaseShipFromData: branches.current.find((bp) => bp.bra_id === purchase[0]?.pur_bra_id)?.bra_name,
                purchaseTypeData: purchaseTransTypes.find((tt) => tt.tt_code === purchase[0]?.pur_tt_code)?.tt_type,
                purchaseSubTotal: purchase[0]?.pur_subtotal,
                purchaseDiscount: purchase[0]?.pur_discountamount,
                purchaseTaxTotal: purchase[0]?.pur_totaltax,
                purchaseTotal: purchase[0]?.pur_total,
                tablepurchasevalue: table,
            };
            const newPage = page.replace(/purchaseSupplierIDData|purchaseShipFromData|purchaseTotal|purchaseTaxTotal|purchaseDiscount|purchaseSubTotal|purchaseTypeData|purchaseDeliveryDateData|purchaseOrderNumberData|tablepurchasevalue|companyaddressvalue|companyphonevalue|companylogovalue|purchaseDateData/gi,
                (matched) => mapObj[matched]);
            setReport(newPage);
        });
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const displayTypeTrxnb = (option) => {
        if (option !== null) {
            for (let tt of globalTransTypes) {
                if (tt.tt_code === option.pur_tt_code) {
                    return tt.tt_newcode + "-" + option.pur_trxnb;
                }
            }
        }
        return "";
    }

    const pur_item_row = {
        pit_pur_id: "",
        pit_it_id: "",
        pit_it_price: "",
        pit_qty: "",
        pit_discountperc: "",
        pit_discountamount: "",
        pit_vat: "",
        pit_wa_id: "",
        pit_note: "",
        pit_user_id: "",
        pit_div_id: "",
        pit_tax1: "",
        pit_tax2: "",
        pit_total: "",
    }

    const onInitNewRow = (e) => {
        e.data.pit_it_price = 0;
        e.data.pit_qty = 1;
        e.data.pit_discountperc = 0;
        e.data.pit_discountamount = 0;
        e.data.pit_vat = 0;
        e.data.pit_tax1 = 0;
        e.data.pit_tax2 = 0;
        e.data.pit_total = 0;
    }

    useEffect(() => {

        var gross = 0;
        var totalTax = 0;
        var totalTax1 = 0;
        var totalTax2 = 0;

        for (let row of purchaseItem) {
            if (row.pit_qty && row.pit_it_price) {
                gross = gross + (row.pit_qty * row.pit_it_price - row.pit_discountamount);
            }

            if (row.pit_vat && row.pit_total) {
                totalTax = totalTax + (row.pit_vat * row.pit_total / 100);
            }

            if (row.pit_tax1 && row.pit_total) {
                totalTax1 = totalTax1 + (row.pit_tax1 * row.pit_total / 100);
            }

            if (row.pit_tax2 && row.pit_total) {
                totalTax2 = totalTax2 + (row.pit_tax2 * row.pit_total / 100);
            }
        }
        setDiscAmt(gross * discperc / 100);
        setSubTotal(gross);
        setTotalTax(roundNumber((totalTax - totalTax * discperc / 100) + (totalTax1 - totalTax1 * discperc / 100) + (totalTax2 - totalTax2 * discperc / 100)));

        var st1 = gross - (gross * discperc / 100) + (roundNumber((totalTax - totalTax * discperc / 100) + (totalTax1 - totalTax1 * discperc / 100) + (totalTax2 - totalTax2 * discperc / 100)))

        for (let cur of globalRates) {
            if (curr === globalFirstCur) {
                if (curr === cur.rate_cur_id1 && globalSecondCur === cur.rate_cur_id2) {
                    setTotalOne(st1 * cur.rate_rate);
                    break;
                } else if (globalSecondCur === cur.rate_cur_id1 && curr === cur.rate_cur_id2) {
                    setTotalOne(st1 / cur.rate_rate);
                    break;
                }
            }
            else if (curr === cur.rate_cur_id1 && globalFirstCur === cur.rate_cur_id2) {
                setTotalOne(st1 * cur.rate_rate);
                break;
            } else if (globalFirstCur === cur.rate_cur_id1 && curr === cur.rate_cur_id2) {
                setTotalOne(st1 / cur.rate_rate);
                break;
            }
        }

    }, [purchaseItem])

    const onEditiorPreparing = (e) => {

        if (e.dataField === "pit_discountperc" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0 || args.value > 100) {
                    swal("Error", "Value must be between 0 and 100", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                let qty = datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_qty");
                let price = datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_it_price");

                // calculate discAmt
                datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_discountamount", price * qty * args.value / 100);
                // calculate row total
                datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_total",
                    qty * price - datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_discountamount")
                );

                datagridRef.current.instance.saveEditData();
                setPurchaseItem([...purchaseItem]);
            }
        }

        if (e.dataField === "pit_discountamount" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                let price = datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_it_price");
                let qty = datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_qty");
                if (args.value < 0 || args.value > (price * qty)) {
                    swal("Error", "Value must be between 0 and " + (price * qty), "error");
                    return;
                }
                defaultValueChangeHandler(args);

                // calculate discPerc
                datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_discountperc",
                    ((args.value / (price * qty)) * 100));

                // calculate row total
                datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_total",
                    qty * price - args.value);

                datagridRef.current.instance.saveEditData();
                setPurchaseItem([...purchaseItem]);
            }
        }

        if (e.dataField === "pit_it_price" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value cannot be less than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                // calculate discAmt
                datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_discountamount",
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_qty") * datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_discountperc") * args.value / 100
                );

                // calculate row total
                datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_total",
                    datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_qty") * args.value - datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_discountamount")
                );

                datagridRef.current.instance.saveEditData();
                setPurchaseItem([...purchaseItem]);
            }
        }

        if (e.dataField === "pit_qty" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value cannot be less than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);
                let price = datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_it_price");

                // calculate discAmt
                datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_discountamount",
                    args.value * datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_discountperc") * price / 100
                );

                // calculate row total
                datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_total",
                    price * args.value - datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_discountamount")
                );

                datagridRef.current.instance.saveEditData();
                setPurchaseItem([...purchaseItem]);
            }
        }

        if (e.dataField === "pit_vat" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value cannot be less than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                datagridRef.current.instance.saveEditData();
                setPurchaseItem([...purchaseItem]);
            }
        }

        if (e.dataField === "pit_tax1" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value cannot be less than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                datagridRef.current.instance.saveEditData();
                setPurchaseItem([...purchaseItem]);
            }
        }

        if (e.dataField === "pit_tax2" && e.parentType === "dataRow") {
            e.editorOptions.min = 0;
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value < 0) {
                    swal("Error", "Value cannot be less than 0", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                datagridRef.current.instance.saveEditData();
                setPurchaseItem([...purchaseItem]);
            }
        }

        if (e.dataField === "pit_wa_id" && e.parentType === "dataRow") {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                defaultValueChangeHandler(args);

                datagridRef.current.instance.saveEditData();
                setPurchaseItem([...purchaseItem]);
            }
        }

        if (e.dataField === "pit_note" && e.parentType === "dataRow") {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                defaultValueChangeHandler(args);

                datagridRef.current.instance.saveEditData();
                setPurchaseItem([...purchaseItem]);
            }
        }

        if (e.dataField === "pit_div_id" && e.parentType === "dataRow") {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                defaultValueChangeHandler(args);

                datagridRef.current.instance.saveEditData();
                setPurchaseItem([...purchaseItem]);
            }
        }

        if (e.dataField === "pit_it_id" && e.parentType === "dataRow") {
            const defaultValueChangeHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
                if (args.value === null) {
                    swal("Error", "Please select an Item", "error");
                    return;
                }
                defaultValueChangeHandler(args);

                for (let i of items.current) {
                    if (i.it_id === args.value) {
                        var newPrice = i.it_unitprice;
                        for (let exc of globalRates) {
                            if (i.it_cur_id === exc.rate_cur_id2 && curr === exc.rate_cur_id1) {
                                newPrice = Math.round(((i.it_unitprice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                break;
                            } else if (i.it_cur_id === exc.rate_cur_id1 && curr === exc.rate_cur_id2) {
                                newPrice = Math.round(((i.it_unitprice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                break;
                            }
                        }

                        if (newPrice === i.it_unitprice) {
                            for (let exc of globalRates) {
                                if (curr === exc.rate_cur_id1 && i.it_cur_id !== curr) {
                                    newPrice = Math.round(((newPrice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                    continue;
                                } else if (curr === exc.rate_cur_id2 && i.it_cur_id !== curr) {
                                    newPrice = Math.round(((newPrice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                    continue;
                                } else if (i.it_cur_id === exc.rate_cur_id2 && i.it_cur_id !== curr) {
                                    newPrice = Math.round(((newPrice / exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                    continue;
                                } else if (i.it_cur_id === exc.rate_cur_id1 && i.it_cur_id !== curr) {
                                    newPrice = Math.round(((newPrice * exc.rate_rate) + Number.EPSILON) * 100) / 100;
                                    continue;
                                }
                            }
                        }

                        datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_it_price", newPrice);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_vat", i.it_tax);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_tax1", i.it_tax1);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_tax2", i.it_tax2);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_total", newPrice);
                        datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_note", i.it_note);
                        for (let w of warehouse.current) {
                            if (w.wa_id === i.it_wa_id) {
                                datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_wa_id", w.wa_id);
                                break;
                            }
                        }
                        for (let d of division.current) {
                            if (d.div_id === i.it_div_id) {
                                datagridRef.current.instance.cellValue(e.row.rowIndex, "pit_div_id", d.div_id);
                                break;
                            }
                        }
                    }
                }

                datagridRef.current.instance.saveEditData();
                setPurchaseItem([...purchaseItem]);
            }
        }

    }

    const roundNumber = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }
    const amount = (e) => {
        if (e > 0 && e <= subTotal) {
            setDiscAmt(e);
            setDiscperc((e / subTotal) * 100);
        }
        if (e <= 0) {
            setDiscAmt(0);
            setDiscperc(0);
        }
        setPurchaseItem([...purchaseItem]);
    }
    const percent = (e) => {
        if (e > 0 && e <= 100) {
            setDiscperc(e);
            setDiscAmt(e * subTotal / 100);
        }
        if (e <= 0) {
            setDiscAmt(0);
            setDiscperc(0);
        }
        setPurchaseItem([...purchaseItem]);
    }

    const deletePurchase = (e) => {
        if (id !== null) {
            const data = [];
            data.push(id);
            delPurchase(data);
        } else {
            swal("Info", "Please select a Purchase Number to delete.", "info");
            return;
        }
    }

    const savePurchase = (e) => {
        if (thirdParties === null || thirdParties === undefined || thirdParties === "") {
            swal("Info", "Please select a Supplier.", "info")
            return;
        }

        let rows = datagridRef.current.instance.getVisibleRows();
        let pitid = datagridRef.current.instance.cellValue(0, "pit_it_id");

        if (rows.length === 0) {
            swal("Error", "Please add at least 1 item!", "error")
            return;
        }

        if (pitid === undefined) {
            swal("Error", "Please add at least 1 item!", "error")
            return;
        }

        if (id === null || id === undefined || id === "") {
            const params = {};

            params.pur_cmp_id = globalCmpId;
            params.pur_duedate = dueDate;
            params.pur_trxnb = null;
            params.pur_cur_id = curr;
            params.pur_discountperc = roundNumber(discperc);
            params.pur_discountamount = roundNumber(discamt);
            params.pur_wa_id = ware;
            params.pur_bra_id = branch;
            params.pur_prj_id = project;
            params.pur_note = note;
            params.pur_tp_id = thirdParties;
            params.pur_paid = switchPaid;
            params.pur_subtotal = roundNumber(subTotal);
            params.pur_totaltax = roundNumber(TotalTax);
            params.pur_total = roundNumber(total);
            params.pur_total1 = roundNumber(totalOne);
            params.pur_user_id = globalUsrId;
            params.pur_change = roundNumber(change);
            params.pur_tt_code = trancactionType;
            params.pur_docurl = "";
            params.pur_delivered = switchDelivered;

            const data = [];
            data.push(params);

            const iparams = [];
            for (let item of rows) {
                const param = {};
                param.pit_it_id = item.data.pit_it_id;
                param.pit_it_price = item.data.pit_it_price;
                param.pit_qty = item.data.pit_qty;
                param.pit_discountperc = item.data.pit_discountperc;
                param.pit_discountamount = item.data.pit_discountamount;
                param.pit_vat = item.data.pit_tax;
                param.pit_wa_id = item.data.pit_wa_id;
                param.pit_note = item.data.pit_note;
                param.pit_user_id = globalUsrId;
                param.pit_div_id = item.data.pit_div_id;
                param.pit_tax1 = item.data.pit_tax1;
                param.pit_tax2 = item.data.pit_tax2;
                iparams.push(param);
            };
            addPurchase(data, iparams);
        } else {

            let rowdel = datagridRef.current.instance.getVisibleRows();
            var delGross = 0;
            var delTotalTax = 0;
            var delTotalTax1 = 0;
            var delTotalTax2 = 0;

            for (let row of rowdel) {
                if (row.removed) {
                    if (row.data.pit_qty && row.data.pit_it_price) {
                        delGross = delGross + (row.data.pit_qty * row.data.pit_it_price - row.data.pit_discountamount);
                    }

                    if (row.pit_vat && row.pit_total) {
                        delTotalTax = delTotalTax + (row.pit_vat * row.pit_total / 100);
                    }

                    if (row.pit_tax1 && row.pit_total) {
                        delTotalTax1 = delTotalTax1 + (row.pit_tax1 * row.pit_total / 100);
                    }

                    if (row.pit_tax2 && row.pit_total) {
                        delTotalTax2 = delTotalTax2 + (row.pit_tax2 * row.pit_total / 100);
                    }
                }
            }

            const params = {};
            params.pur_id = id;
            params.pur_cmp_id = globalCmpId;
            params.pur_duedate = dueDate;
            params.pur_tt_code = trancactionType;
            params.pur_trxnb = purchase.filter((option) => option.pur_id === id)[0].pur_trxnb;
            params.pur_cur_id = curr;
            params.pur_discountperc = roundNumber(discperc);
            params.pur_discountamount = roundNumber(discamt - (delGross * discperc / 100));
            params.pur_wa_id = ware;
            params.pur_bra_id = branch;
            params.pur_prj_id = project;
            params.pur_note = note;
            params.pur_tp_id = thirdParties;
            params.pur_paid = switchPaid;
            params.pur_subtotal = roundNumber(subTotal - delGross);
            params.pur_totaltax = roundNumber(TotalTax - delTotalTax - delTotalTax1 - delTotalTax2);
            params.pur_total = roundNumber(total - delGross - delTotalTax - delTotalTax1 - delTotalTax2 + (delGross * discperc / 100));
            params.pur_total1 = roundNumber(totalOne - delGross - delTotalTax - delTotalTax1 - delTotalTax2 + (delGross * discperc / 100));
            params.pur_user_id = globalUsrId;
            params.pur_change = roundNumber(change);
            params.pur_docurl = "";
            params.pur_delivered = switchDelivered;

            const data = [];
            data.push(params);
            updPurchase(data);

            let rows = datagridRef.current.instance.getVisibleRows();
            const updparams = [];
            const delparams = [];
            const addparams = [];
            for (let row of rows) {
                if (row.removed) {
                    delparams.push(row.data.pit_id);
                    continue;
                }
                if (row.data.pit_pur_id) {
                    const param = {};
                    param.pit_id = row.data.pit_id;
                    param.pit_pur_id = row.data.pit_pur_id;
                    param.pit_it_id = row.data.pit_it_id;
                    param.pit_it_price = row.data.pit_it_price;
                    param.pit_qty = row.data.pit_qty;
                    param.pit_discountperc = row.data.pit_discountperc;
                    param.pit_discountamount = row.data.pit_discountamount;
                    param.pit_vat = row.data.pit_tax;
                    param.pit_wa_id = row.data.pit_wa_id;
                    param.pit_note = row.data.pit_note;
                    param.pit_user_id = globalUsrId;
                    param.pit_div_id = row.data.pit_div_id;
                    param.pit_tax1 = row.data.pit_tax1;
                    param.pit_tax2 = row.data.pit_tax2;
                    updparams.push(param);
                } else {
                    const param = {};
                    param.pit_it_id = row.data.pit_it_id;
                    param.pit_it_price = row.data.pit_it_price;
                    param.pit_qty = row.data.pit_qty;
                    param.pit_discountperc = row.data.pit_discountperc;
                    param.pit_discountamount = row.data.pit_discountamount;
                    param.pit_vat = row.data.pit_tax;
                    param.pit_wa_id = row.data.pit_wa_id;
                    param.pit_note = row.data.pit_note;
                    param.pit_user_id = globalUsrId;
                    param.pit_div_id = row.data.pit_div_id;
                    param.pit_tax1 = row.data.pit_tax1;
                    param.pit_tax2 = row.data.pit_tax2;
                    addparams.push(param);
                }
            };

            if (delparams.length > 0) {
                delPurchaseItem(delparams);
            }
            if (addparams.length > 0) {
                addPurchaseItem(addparams, id);
            }
            if (updparams.length > 0) {
                updPurchaseItems(updparams);
            }
        }
    }

    const addPurchase = (params, iparams) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}purchases/add_purchase`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    addPurchaseItem(iparams, response.pur_id[0][0].pur_id);
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                getUpdatedPurchaseItem();
            });
    }

    const addPurchaseItem = (params, id) => {
        for (let it of params)
            it.pit_pur_id = id;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}purchaseitems/add_purchaseitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).catch((err) => console.log(err));
    }

    const updPurchase = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}purchases/upd_purchase`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                getUpdatedPurchaseItem();
            });
    };

    const updPurchaseItems = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}purchaseitems/upd_purchaseitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    }

    const delPurchase = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}purchases/del_purchase`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    onReset();
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            }).then(() => {
                getUpdatedPurchaseItem();
            });
    };

    const delPurchaseItem = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}purchaseitems/del_purchaseitem`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    setIsLoading(false);
                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const formatOptions = {
        type: "fixedPoint",
        precision: 2
    };

    const renderColumns = () => {
        const res = [];

        for (var key in pur_item_row) {
            if (pur_item_row.hasOwnProperty(key)) {
                if ("pit_pur_id" === key) {
                    res.push(
                        <Column
                            dataField="pit_pur_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("pit_it_id" === key) {
                    res.push(
                        <Column
                            dataField="pit_it_id"
                            key={key}
                            visible={true}
                            caption="Item"
                        >
                            <Lookup dataSource={items.current} displayExpr="it_name" valueExpr="it_id" />
                        </Column>
                    );
                } else if ("pit_it_price" === key) {
                    res.push(
                        <Column
                            dataField="pit_it_price"
                            key={key}
                            caption="Price"
                            visible={true}
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("pit_qty" === key) {
                    res.push(
                        <Column
                            dataField="pit_qty"
                            key={key}
                            visible={true}
                            caption="Quantity"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("pit_discountperc" === key) {
                    res.push(
                        <Column
                            dataField="pit_discountperc"
                            key={key}
                            caption="Disc %"
                            visible={true}
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("pit_discountamount" === key) {
                    res.push(
                        <Column
                            dataField="pit_discountamount"
                            key={key}
                            visible={true}
                            caption="Disc Amount"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("pit_vat" === key) {
                    res.push(
                        <Column
                            dataField="pit_vat"
                            key={key}
                            visible={true}
                            caption="Vat"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("pit_wa_id" === key) {
                    res.push(
                        <Column
                            dataField="pit_wa_id"
                            key={key}
                            visible={true}
                            caption="Warehouse"
                        >
                            <Lookup displayExpr="wa_name" valueExpr="wa_id" dataSource={warehouse.current} />
                        </Column>
                    );
                } else if ("pit_note" === key) {
                    res.push(
                        <Column
                            dataField="pit_note"
                            key={key}
                            visible={true}
                            caption="Note"
                            dataType="string"
                        ></Column>
                    );
                } else if ("pit_user_id" === key) {
                    res.push(
                        <Column
                            dataField="pit_user_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("pit_div_id" === key) {
                    res.push(
                        <Column
                            dataField="pit_div_id"
                            key={key}
                            visible={true}
                            caption="Division"
                        >
                            <Lookup displayExpr="div_name" valueExpr="div_id" dataSource={division.current} />
                        </Column>
                    );
                } else if ("pit_tax1" === key) {
                    res.push(
                        <Column
                            dataField="pit_tax1"
                            key={key}
                            visible={true}
                            caption="Tax 1"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("pit_tax2" === key) {
                    res.push(
                        <Column
                            dataField="pit_tax2"
                            key={key}
                            visible={true}
                            caption="Tax 2"
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                } else if ("pit_total" === key) {
                    res.push(
                        <Column
                            dataField="pit_total"
                            key={key}
                            visible={true}
                            caption="Total"
                            allowEditing={false}
                            dataType="number"
                            alignment='left'
                            format={formatOptions}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <div className="groups">
                    <div className="groups-btns">
                        <Button
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                            text="Advanced Options"
                            onClick={openPopup}
                        />
                        <Button
                            text="Save"
                            width={100}
                            onClick={savePurchase}
                        />
                        <Button
                            text="Reset"
                            width={100}
                            onClick={onReset}
                        />
                        <Button
                            text="Print"
                            width={100}
                            onClick={printPreview}
                        />
                        <Button
                            text="Delete"
                            width={100}
                            onClick={deletePurchase}
                        />
                    </div>
                </div>
                <div className="maingroup">
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Supplier</InputLabel>
                        <SelectBox
                            dataSource={thirdParty.current}
                            displayExpr="tp_name"
                            valueExpr="tp_id"
                            placeholder="Supplier"
                            value={thirdParties}
                            onValueChanged={setTP}
                            width={200}
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'} >Transaction Type</InputLabel>
                        <SelectBox
                            dataSource={purchaseTransTypes}
                            value={trancactionType}
                            displayExpr="tt_type"
                            valueExpr="tt_code"
                            placeholder="Transaction Type"
                            onValueChanged={(e) => setTransactionType(e.value)}
                            width={200}
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                        <InputLabel sx={{ color: "white" }} className={openDrawer ? 'shiftRight' : 'shiftLeft'}>Purchase Number</InputLabel>
                        <SelectBox
                            dataSource={tempPur}
                            displayExpr={displayTypeTrxnb}
                            valueExpr="pur_id"
                            placeholder="Purchase"
                            onValueChanged={getID}
                            value={id}
                            width={200}
                            showClearButton
                            className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Discount %</InputLabel>
                        <NumberBox
                            showClearButton
                            showSpinButtons={false}
                            min={0}
                            max={100}
                            placeholder="Discount %"
                            onValueChange={percent}
                            value={roundNumber(discperc)}
                            width={200}
                        />
                        <InputLabel sx={{ color: "white" }}>Discount Amount</InputLabel>
                        <NumberBox
                            showClearButton
                            showSpinButtons={false}
                            min={0}
                            placeholder="Discount Amount"
                            onValueChange={amount}
                            value={roundNumber(discamt)}
                            width={200}
                        />
                        <InputLabel sx={{ color: "white" }}>Total Tax</InputLabel>
                        <NumberBox
                            showSpinButtons={false}
                            min={0}
                            placeholder="Total Tax"
                            value={TotalTax}
                            width={200}
                            readOnly
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Sub Total</InputLabel>
                        <NumberBox
                            showSpinButtons={false}
                            min={0}
                            placeholder="Sub Total"
                            value={roundNumber(subTotal)}
                            width={200}
                            readOnly
                        />
                        <InputLabel sx={{ color: "white" }}>Total</InputLabel>
                        <NumberBox
                            showSpinButtons={false}
                            min={0}
                            placeholder="Total"
                            value={roundNumber(total)}
                            width={200}
                            readOnly
                        />
                        <InputLabel sx={{ color: "white" }}>Total 1</InputLabel>
                        <NumberBox
                            showSpinButtons={false}
                            min={0}
                            placeholder="Total 1"
                            value={roundNumber(totalOne)}
                            width={200}
                            readOnly
                        />
                    </div>
                    <div className="groups">
                        <InputLabel sx={{ color: "white" }}>Note</InputLabel>
                        <TextBox
                            value={note}
                            placeholder="Note"
                            width={200}
                            onValueChanged={(e) => setNote(e.value)}
                        />
                    </div>
                </div>
                <div>
                    <Popup
                        visible={popup}
                        onHiding={() => setPopup(false)}
                        dragEnabled={false}
                        showCloseButton={true}
                        showTitle={true}
                        width="auto"
                        height="auto"
                    >
                        <Position
                            at="center"
                            my="center"
                        />
                        <ToolbarItem
                            text="Advanced Options"
                            location="before">
                        </ToolbarItem>
                        <div className="groups-popup">
                            <InputLabel sx={{ color: "black" }}>Warehouse</InputLabel>
                            <SelectBox
                                dataSource={warehouse.current}
                                displayExpr="wa_name"
                                valueExpr="wa_id"
                                placeholder="Warehouse"
                                onValueChanged={(e) => setWare(e.value)}
                                value={ware}
                                width={200}
                            />
                            <InputLabel sx={{ color: "black" }}>Project</InputLabel>
                            <SelectBox
                                dataSource={projects.current}
                                displayExpr="prj_name"
                                valueExpr="prj_id"
                                placeholder="Project"
                                onValueChanged={(e) => setProject(e.value)}
                                value={project}
                                width={200}
                            />
                            <InputLabel sx={{ color: "black" }}>Branch</InputLabel>
                            <SelectBox
                                dataSource={branches.current}
                                displayExpr="bra_name"
                                valueExpr="bra_id"
                                placeholder="Branches"
                                onValueChanged={(e) => setBranch(e.value)}
                                value={branch}
                                width={200}
                            />
                        </div>
                        <div className="groups-popup">
                            <InputLabel sx={{ color: "black" }}>Currency</InputLabel>
                            <SelectBox
                                dataSource={currency}
                                displayExpr="cur_code"
                                valueExpr="cur_id"
                                placeholder="Currency"
                                onValueChanged={currencyChange}
                                defaultValue={globalFirstCur}
                                value={curr}
                                width={200}
                            />
                            <InputLabel sx={{ color: "black" }}>Change</InputLabel>
                            <NumberBox
                                showClearButton
                                showSpinButtons={false}
                                min={0}
                                placeholder="Change"
                                onValueChanged={(e) => setChange(e.value)}
                                value={change}
                                width={200}
                            />
                            <InputLabel sx={{ color: "black" }}>Due Date</InputLabel>
                            <DateBox
                                value={dueDate}
                                placeholder="Due Date"
                                onValueChanged={(e) => setDueDate(e.value)}
                                width={200}
                                type="datetime"
                            />
                        </div>
                        <div className="groups">
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Not Paid</Typography>
                                <Switch defaultChecked value={switchPaid} onChange={(e) => handleSwitchPaid(e)} />
                                <Typography>Paid</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Not Delivered</Typography>
                                <Switch defaultChecked value={switchDelivered} onChange={(e) => handleSwitchDelivered(e)} />
                                <Typography>Delivered</Typography>
                            </Stack>
                        </div>
                    </Popup>
                </div>
                <div>
                    <Dialog
                        open={viwerPopup}
                        TransitionComponent={Transition}
                        keepMounted
                        fullWidth
                        maxWidth="md"
                        onClose={() => setViewerPopup(false)}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <DialogTitle>Purchase</DialogTitle>
                            <Button className="button in_button2" onClick={handlePrint}>Print</Button>
                            <div className="me-4 cursor" onClick={() => setViewerPopup(false)}>
                                <ClearIcon />
                            </div>
                        </div>
                        <div ref={componentRef} dangerouslySetInnerHTML={{ __html: report }} />
                    </Dialog>
                </div>
                <div style={{ marginTop: 14 }}></div>
                <DataGrid
                    ref={datagridRef}
                    onInitNewRow={onInitNewRow}
                    className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    onEditorPreparing={onEditiorPreparing}
                    dataSource={purchaseItem}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <Toolbar>
                        <Item location="after" visible={thirdParties !== null} name="addRowButton" />
                        <Item location="after" visible={false} name="saveButton" />
                        <Item location="after" visible={true} name="revertButton" />
                        <Item location="after" visible={true} name="searchPanel" />
                        <Item location="before" visible={true} name="groupPanel" />
                        <Item location="before" visible={true} name="columnChooserButton" />
                    </Toolbar>
                    <FilterBuilder defaultFields={pur_item_row} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default Purchases;