import { useState, useEffect } from 'react';
import './EmployeeSalary.css';
import '../../GlobalCSS.css';
import * as global from '../../utils/globals';
import swal from "sweetalert";
import { getEmployeesSalaries, getDepartment, getEmployeesSalariesAllDeps, getEmployees, convertDateOnly } from "../../utils/helper";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    RequiredRule,
    Lookup
} from "devextreme-react/data-grid";
import DataGrid from "devextreme-react/data-grid";
import { SelectBox } from 'devextreme-react';
import { store } from '../../store';

const EmployeeSalary = ({ openDrawer, setIsLoading }) => {
    const globalCmpId = store.getState().mainReducer.cmp_id;
    const [employeesSalaries, setEmployeesSalaries] = useState([]);
    const [department, setDepartment] = useState([]);
    const [id, setId] = useState(null);
    const [employee, setEmployee] = useState([]);
    const [tempEmployee, setTempEmployee] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getEmployees(globalCmpId).then((x) => {
            setEmployee(x);
            setTempEmployee(x);
            setIsLoading(false);
        });
        getUpdatedDep();
    }, []);

    const getUpdatedDep = () => {
        getDepartment(globalCmpId).then((x) => {
            setDepartment([{ dep_id: "All", dep_name: "All" }]);
            for (let i = 0; i < x.length; i++) {
                setDepartment((olddata) => [...olddata, x[i]]);
            }
            setIsLoading(false);
        });
    }

    const getID = (e) => {
        setIsLoading(true);
        if (e.value === "All") {
            getEmployeesSalariesAllDeps(globalCmpId).then((x) => {
                for (let es of x) {
                    for (let emp of employee) {
                        if (es.es_emp_id === emp.emp_id)
                            es.emp_number = emp.emp_id;
                    }
                    es.total_earnings = es.es_basicpay + es.es_housing + es.es_overtime + es.es_leaveallowance + es.es_nightshift + es.es_meals + es.es_transport + es.es_utility;
                    es.total_deductions = es.es_arrears + es.es_payee + es.es_staffloan;;
                    es.net_amount = es.total_earnings - es.total_deductions;
                }
                setEmployeesSalaries(x);
            }, setIsLoading(false));
            setTempEmployee(employee);
        } else {
            getEmployeesSalaries(e.value, globalCmpId).then((x) => {
                for (let es of x) {
                    for (let emp of employee) {
                        if (es.es_emp_id === emp.emp_id)
                            es.emp_number = emp.emp_id;
                    }
                    es.total_earnings = es.es_basicpay + es.es_housing + es.es_overtime + es.es_leaveallowance + es.es_nightshift + es.es_meals + es.es_transport + es.es_utility;
                    es.total_deductions = es.es_arrears + es.es_payee + es.es_staffloan;
                    es.net_amount = es.total_earnings - es.total_deductions;
                }
                setEmployeesSalaries(x)
            }, setIsLoading(false));
            const newTempEmployee = employee.filter((emp) => emp.emp_dep_id === e.value);
            setTempEmployee(newTempEmployee);
        }
        setId(e.value);
    }

    const emp_sal_rows = {
        es_id: "",
        es_emp_id: "",
        emp_number: "",
        es_startdate: "",
        es_basicpay: "",
        es_overtime: "",
        es_housing: "",
        es_leaveallowance: "",
        es_meals: "",
        es_nightshift: "",
        es_transport: "",
        es_utility: "",
        total_earnings: "",
        es_staffloan: "",
        es_payee: "",
        es_arrears: "",
        es_datecreated: "",
        total_deductions: "",
        net_amount: "",
    }

    const addEmployeeSalary = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}employeessalaries/add_employeesalary`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedDep();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updEmployeeSalary = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}employeessalaries/upd_employeesalary`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedDep();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delEmployeeSalary = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}employeessalaries/del_employeesalary`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedDep();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedEmployeeSalary = [];
            const updatedEmployeeSalary = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.es_id);
                } else if (row.type === "insert") {
                    addedEmployeeSalary.push({ ...row.data, es_startdate: convertDateOnly(row.data.es_startdate) });
                } else {
                    const obj = { ...row.key, ...row.data, es_startdate: convertDateOnly(row.data.es_startdate) };
                    updatedEmployeeSalary.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delEmployeeSalary(deletedIds);
            }
            if (addedEmployeeSalary.length > 0) {
                addEmployeeSalary(addedEmployeeSalary);
            }
            if (updatedEmployeeSalary.length > 0) {
                updEmployeeSalary(updatedEmployeeSalary);
            }
        }
    };

    const setEmployeeName = (value) => {
        return value.emp_firstname + " " + value.emp_lastname;
    }

    const setCellValueName = (rowdata, value) => {
        rowdata.es_emp_id = value;

        for (let i of tempEmployee) {
            if (i.emp_id === value) {
                rowdata.emp_number = i.emp_id;
            }
        }
    }
    const setCellValueNumber = (rowdata, value) => {
        rowdata.emp_number = value;

        for (let i of tempEmployee) {
            if (i.emp_id === value) {
                rowdata.es_emp_id = i.emp_id;
            }
        }
    }

    const setCellValueBasicPay = (rowdata, value, prevrowdata) => {
        rowdata.es_basicpay = value;

        rowdata.total_earnings = value + prevrowdata.es_housing + prevrowdata.es_overtime + prevrowdata.es_leaveallowance
            + prevrowdata.es_nightshift + prevrowdata.es_meals + prevrowdata.es_transport + prevrowdata.es_utility;

        rowdata.net_amount = rowdata.total_earnings - prevrowdata.total_deductions;
    }

    const setCellValueHousing = (rowdata, value, prevrowdata) => {
        rowdata.es_housing = value;

        rowdata.total_earnings = prevrowdata.es_basicpay + value + prevrowdata.es_overtime + prevrowdata.es_leaveallowance
            + prevrowdata.es_nightshift + prevrowdata.es_meals + prevrowdata.es_transport + prevrowdata.es_utility;

        rowdata.net_amount = rowdata.total_earnings - prevrowdata.total_deductions;
    }

    const setCellValueOverTime = (rowdata, value, prevrowdata) => {
        rowdata.es_overtime = value;

        rowdata.total_earnings = prevrowdata.es_basicpay + prevrowdata.es_housing + value + prevrowdata.es_leaveallowance
            + prevrowdata.es_nightshift + prevrowdata.es_meals + prevrowdata.es_transport + prevrowdata.es_utility;

        rowdata.net_amount = rowdata.total_earnings - prevrowdata.total_deductions;
    }

    const setCellValueLeaveAllowance = (rowdata, value, prevrowdata) => {
        rowdata.es_leaveallowance = value;

        rowdata.total_earnings = prevrowdata.es_basicpay + prevrowdata.es_housing + prevrowdata.es_overtime + value
            + prevrowdata.es_nightshift + prevrowdata.es_meals + prevrowdata.es_transport + prevrowdata.es_utility;

        rowdata.net_amount = rowdata.total_earnings - prevrowdata.total_deductions;
    }

    const setCellValueNightShift = (rowdata, value, prevrowdata) => {
        rowdata.es_nightshift = value;

        rowdata.total_earnings = prevrowdata.es_basicpay + prevrowdata.es_housing + prevrowdata.es_overtime + prevrowdata.es_leaveallowance
            + value + prevrowdata.es_meals + prevrowdata.es_transport + prevrowdata.es_utility;

        rowdata.net_amount = rowdata.total_earnings - prevrowdata.total_deductions;
    }

    const setCellValueMeals = (rowdata, value, prevrowdata) => {
        rowdata.es_meals = value;

        rowdata.total_earnings = prevrowdata.es_basicpay + prevrowdata.es_housing + prevrowdata.es_overtime + prevrowdata.es_leaveallowance
            + prevrowdata.es_nightshift + value + prevrowdata.es_transport + prevrowdata.es_utility;

        rowdata.net_amount = rowdata.total_earnings - prevrowdata.total_deductions;
    }

    const setCellValueTransport = (rowdata, value, prevrowdata) => {
        rowdata.es_transport = value;

        rowdata.total_earnings = prevrowdata.es_basicpay + prevrowdata.es_housing + prevrowdata.es_transport + prevrowdata.es_leaveallowance
            + prevrowdata.es_nightshift + prevrowdata.es_meals + value + prevrowdata.es_utility;

        rowdata.net_amount = rowdata.total_earnings - prevrowdata.total_deductions;
    }

    const setCellValueUtility = (rowdata, value, prevrowdata) => {
        rowdata.es_utility = value;

        rowdata.total_earnings = prevrowdata.es_basicpay + prevrowdata.es_housing + prevrowdata.es_overtime + prevrowdata.es_leaveallowance
            + prevrowdata.es_nightshift + prevrowdata.es_meals + prevrowdata.es_transport + value;

        rowdata.net_amount = rowdata.total_earnings - prevrowdata.total_deductions;
    }

    const setCellValueStaffLoan = (rowdata, value, prevrowdata) => {
        rowdata.es_staffloan = value;

        rowdata.total_deductions = prevrowdata.es_arrears + prevrowdata.es_payee + value;

        rowdata.net_amount = prevrowdata.total_earnings - rowdata.total_deductions;
    }

    const setCellValueArrears = (rowdata, value, prevrowdata) => {
        rowdata.es_arrears = value;

        rowdata.total_deductions = value + prevrowdata.es_payee + prevrowdata.es_staffloan;

        rowdata.net_amount = prevrowdata.total_earnings - rowdata.total_deductions;
    }

    const setCellValuePayee = (rowdata, value, prevrowdata) => {
        rowdata.es_payee = value;

        rowdata.total_deductions = prevrowdata.es_arrears + value + prevrowdata.es_staffloan;

        rowdata.net_amount = prevrowdata.total_earnings - rowdata.total_deductions;
    }

    const renderColumns = () => {
        const res = [];

        for (var key in emp_sal_rows) {
            if (emp_sal_rows.hasOwnProperty(key)) {
                if ("es_id" === key) {
                    res.push(
                        <Column
                            dataField="es_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("es_emp_id" === key) {
                    res.push(
                        <Column
                            dataField="es_emp_id"
                            caption='Employee'
                            key={key}
                            visible={true}
                            setCellValue={setCellValueName}
                        >
                            <RequiredRule message='Employee is required!'></RequiredRule>
                            <Lookup dataSource={tempEmployee} valueExpr="emp_id" displayExpr={setEmployeeName} />
                        </Column>
                    );
                } else if ("emp_number" === key) {
                    res.push(
                        <Column
                            dataField="emp_number"
                            key={key}
                            visible={true}
                            caption='Emp Number'
                            setCellValue={setCellValueNumber}
                        >
                            <Lookup dataSource={tempEmployee} valueExpr="emp_id" displayExpr="emp_numb" />
                        </Column>
                    );
                } else if ("es_startdate" === key) {
                    res.push(
                        <Column
                            dataField="es_startdate"
                            key={key}
                            visible={true}
                            caption='Start Date'
                            dataType='date'
                        ></Column>
                    );
                } else if ("es_basicpay" === key) {
                    res.push(
                        <Column
                            dataField="es_basicpay"
                            caption="Basic Pay"
                            key={key}
                            visible={true}
                            dataType='number'
                            alignment='left'
                            format={{ type: 'fixedPoint', precision: 2 }}
                            setCellValue={setCellValueBasicPay}
                        ></Column>
                    );
                } else if ("es_overtime" === key) {
                    res.push(
                        <Column
                            dataField="es_overtime"
                            key={key}
                            visible={true}
                            caption="Overtime"
                            dataType='number'
                            alignment='left'
                            format={{ type: 'fixedPoint', precision: 2 }}
                            setCellValue={setCellValueOverTime}
                        ></Column>
                    );
                } else if ("es_housing" === key) {
                    res.push(
                        <Column
                            dataField="es_housing"
                            caption='Housing'
                            key={key}
                            visible={true}
                            dataType='number'
                            alignment='left'
                            format={{ type: 'fixedPoint', precision: 2 }}
                            setCellValue={setCellValueHousing}
                        ></Column>
                    );
                } else if ("es_leaveallowance" === key) {
                    res.push(
                        <Column
                            dataField="es_leaveallowance"
                            key={key}
                            visible={true}
                            caption="Leave Allowance"
                            dataType='number'
                            alignment='left'
                            format={{ type: 'fixedPoint', precision: 2 }}
                            setCellValue={setCellValueLeaveAllowance}
                        ></Column>
                    );
                } else if ("es_meals" === key) {
                    res.push(
                        <Column
                            dataField="es_meals"
                            key={key}
                            visible={true}
                            caption="Meals"
                            dataType='number'
                            alignment='left'
                            format="#0.00"
                            setCellValue={setCellValueMeals}
                        ></Column>
                    );
                } else if ("es_nightshift" === key) {
                    res.push(
                        <Column
                            dataField="es_nightshift"
                            key={key}
                            visible={true}
                            caption='Nightshift'
                            dataType='number'
                            alignment='left'
                            format={{ type: 'fixedPoint', precision: 2 }}
                            setCellValue={setCellValueNightShift}
                        ></Column>
                    );
                } else if ("es_transport" === key) {
                    res.push(
                        <Column
                            dataField="es_transport"
                            key={key}
                            visible={true}
                            caption='Transport'
                            dataType='number'
                            alignment='left'
                            format={{ type: 'fixedPoint', precision: 2 }}
                            setCellValue={setCellValueTransport}
                        ></Column>
                    );
                } else if ("es_utility" === key) {
                    res.push(
                        <Column
                            dataField="es_utility"
                            key={key}
                            visible={true}
                            caption='Utility'
                            dataType='number'
                            alignment='left'
                            format={{ type: 'fixedPoint', precision: 2 }}
                            setCellValue={setCellValueUtility}
                        ></Column>
                    );
                } else if ("total_earnings" === key) {
                    res.push(
                        <Column
                            dataField="total_earnings"
                            key={key}
                            visible={true}
                            caption='Total earnings'
                            dataType='number'
                            alignment='left'
                            format={{ type: 'fixedPoint', precision: 2 }}
                            allowEditing={false}
                        ></Column>
                    );
                } else if ("es_staffloan" === key) {
                    res.push(
                        <Column
                            dataField="es_staffloan"
                            key={key}
                            visible={true}
                            caption='Staff Loan'
                            dataType='number'
                            alignment='left'
                            format={{ type: 'fixedPoint', precision: 2 }}
                            setCellValue={setCellValueStaffLoan}
                        ></Column>
                    );
                } else if ("es_payee" === key) {
                    res.push(
                        <Column
                            dataField="es_payee"
                            key={key}
                            visible={true}
                            caption='Payee'
                            dataType='number'
                            alignment='left'
                            format={{ type: 'fixedPoint', precision: 2 }}
                            setCellValue={setCellValuePayee}
                        ></Column>
                    );
                } else if ("es_arrears" === key) {
                    res.push(
                        <Column
                            dataField="es_arrears"
                            key={key}
                            visible={true}
                            caption='Arrears'
                            dataType='number'
                            alignment='left'
                            format={{ type: 'fixedPoint', precision: 2 }}
                            setCellValue={setCellValueArrears}
                        ></Column>
                    );
                } else if ("total_deductions" === key) {
                    res.push(
                        <Column
                            dataField="total_deductions"
                            key={key}
                            visible={true}
                            caption='Total Deductions'
                            dataType='number'
                            alignment='left'
                            allowEditing={false}
                            format={{ type: 'fixedPoint', precision: 2 }}
                        ></Column>
                    );
                } else if ("net_amount" === key) {
                    res.push(
                        <Column
                            dataField="net_amount"
                            key={key}
                            visible={true}
                            caption='Net Amount'
                            dataType='number'
                            alignment='left'
                            allowEditing={false}
                            format={{ type: 'fixedPoint', precision: 2 }}
                        ></Column>
                    );
                } else if ("es_datecreated" === key) {
                    res.push(
                        <Column
                            dataField="es_datecreated"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const onInitNewRow = (e) => {
        e.data.es_arrears = 0;
        e.data.es_basicpay = 0;
        e.data.es_housing = 0;
        e.data.es_leaveallowance = 0;
        e.data.es_meals = 0;
        e.data.es_nightshift = 0;
        e.data.es_overtime = 0;
        e.data.es_payee = 0;
        e.data.es_staffloan = 0;
        e.data.es_transport = 0;
        e.data.es_utility = 0;
        e.data.total_earnings = 0;
        e.data.total_deductions = 0;
        e.data.net_amount = 0;
    }

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <SelectBox
                    dataSource={department}
                    displayExpr="dep_name"
                    valueExpr="dep_id"
                    placeholder="Department"
                    onValueChanged={getID}
                    value={id}
                    width={200}
                    className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                />
                <div style={{ marginTop: 14 }}></div>
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    onInitNewRow={onInitNewRow}
                    dataSource={employeesSalaries}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <FilterBuilder defaultFields={emp_sal_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default EmployeeSalary;