import { configureStore, createSlice, combineReducers } from "@reduxjs/toolkit";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

// Define initial state
const initialState = {
    data: null,
    closeTab: false
};

const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const mainSlice = createSlice({
    name: "Main Slice",
    initialState: { initialState },
    reducers: {
        setDefaultCashClient: (state, action) => {
            state.tp_name = action.payload;
        },
        setUserId: (state, action) => {
            state.usr_id = action.payload;
        },
        setCmpId: (state, action) => {
            state.cmp_id = action.payload;
        },
        setProfileId: (state, action) => {
            state.pro_id = action.payload;
        },
        setUserType: (state, action) => {
            state.usr_type = action.payload;
        },
        setFirstCur: (state, action) => {
            state.cur_id1 = action.payload;
        },
        setSecondCur: (state, action) => {
            state.cur_id2 = action.payload;
        },
        setTransTypes: (state, action) => {
            state.transtypes = action.payload;
        },
        setRates: (state, action) => {
            state.rates = action.payload;
        },
    },
});

const mainReducer = combineReducers({
    mainReducer: mainSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, mainReducer);

export const mainSliceActions = mainSlice.actions;

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});