import '../../GlobalCSS.css'
import './MoneyBills.css'
import { useState, useEffect } from 'react';
import swal from "sweetalert";
import * as global from '../../utils/globals';
import { getMoneyBills, getPaymentMethods } from '../../utils/helper';
import "devextreme/data/odata/store";
import {
    Column,
    FilterBuilder,
    FilterRow,
    GroupPanel,
    Paging,
    Pager,
    Popup,
    SearchPanel,
    Editing,
    ColumnChooser,
    Sorting,
    Scrolling,
    KeyboardNavigation,
    Lookup,
} from "devextreme-react/data-grid";
import SelectBox from 'devextreme-react/select-box';
import DataGrid from "devextreme-react/data-grid";
import { store } from '../../store';

const MoneyBills = ({ openDrawer, setIsLoading }) => {

    const globalCmpId = store.getState().mainReducer.cmp_id;
    const [moneyBills, setMoneyBills] = useState([]);
    const [payment, setPayment] = useState([]);
    const [id, setID] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        getUpdatedBills();
    }, [])

    const getUpdatedBills = () => {
        getPaymentMethods(globalCmpId).then((x) => {
            setPayment(x);
            setIsLoading(false);
        });
    }

    const getID = (e) => {
        setIsLoading(true);
        getMoneyBills(e.value).then((x) => {
            setMoneyBills(x);
            setIsLoading(false);
        });
        setID(e.value)
    }

    const money_rows = {
        mb_id: "",
        mb_pmt_id: "",
        mb_value: "",
        mb_priority: "",
        mb_image: "",
    }

    const addMoneyBill = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}moneybills/add_moneybill`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedBills();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const updMoneyBill = (params) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ params });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}moneybills/upd_moneybill`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    swal("success", response.success, "success");
                    getUpdatedBills();
                    setIsLoading(false);
                }
                if (!response.error) {

                } else {
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const delMoneyBill = (listIds) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ listIds });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        setIsLoading(true);
        fetch(`${global.baseUrl}moneybills/del_moneybill`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (!response.error) {
                    swal("success", response.success, "success");
                    getUpdatedBills();
                    setIsLoading(false);
                } else {
                    console.log(response.error);
                    swal("Error", response.error[0].originalError.info.message, "error");
                    setIsLoading(false);
                }
            });
    };

    const onSaving = (data) => {
        if (data.changes.length > 0) {
            const deletedIds = [];
            const addedMoneyBill = [];
            const updatedMoneyBill = [];
            data.changes.forEach((row, i) => {
                if (row.type === "remove") {
                    deletedIds.push(row.key.mb_id);
                } else if (row.type === "insert") {
                    addedMoneyBill.push({ ...row.data, mb_pmt_id: id });
                } else {
                    const obj = { ...row.key, ...row.data, mb_pmt_id: id };
                    updatedMoneyBill.push(obj);
                }
            });
            if (deletedIds.length > 0) {
                delMoneyBill(deletedIds);
            }
            if (addedMoneyBill.length > 0) {
                addMoneyBill(addedMoneyBill);
            }
            if (updatedMoneyBill.length > 0) {
                updMoneyBill(updatedMoneyBill);
            }
        }
    };


    const renderColumns = () => {
        const res = [];

        for (var key in money_rows) {
            if (money_rows.hasOwnProperty(key)) {
                if ("mb_id" === key) {
                    res.push(
                        <Column
                            dataField="mb_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("mb_pmt_id" === key) {
                    res.push(
                        <Column
                            dataField="mb_pmt_id"
                            key={key}
                            visible={false}
                        ></Column>
                    );
                } else if ("mb_value" === key) {
                    res.push(
                        <Column
                            dataField="mb_value"
                            caption="Value"
                            dataType="number"
                            alignment="left"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("mb_priority" === key) {
                    res.push(
                        <Column
                            dataField="mb_priority"
                            caption="Priority"
                            dataType="number"
                            alignment="left"
                            key={key}
                            visible={true}
                        ></Column>
                    );
                } else if ("mb_image" === key) {
                    res.push(
                        <Column
                            dataField="mb_image"
                            caption="Image"
                            key={key}
                            visible={true}
                            dataType="string"
                        ></Column>
                    );
                }
            }
        }
        return res;
    };

    const renderDataGrid = () => {
        return (
            <div className="grid">
                <SelectBox
                    dataSource={payment}
                    displayExpr="pmt_name"
                    valueExpr="pmt_id"
                    placeholder="Payment Method"
                    onValueChanged={getID}
                    value={id}
                    width={200}
                    className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                />
                <div style={{ marginTop: 14 }}></div>
                <DataGrid className={openDrawer ? 'shiftRight' : 'shiftLeft'}
                    dataSource={moneyBills}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    remoteOperations={false}
                    columnAutoWidth={true}
                    onSaving={onSaving}
                    allowColumnResizing={true}
                    columnMinWidth={70}
                    columnResizingMode={"widget"}
                >
                    <Popup showTitle={true} title="Row in the editing state" />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 50, 100, 500]}
                        showInfo={true}
                        infoText="Page {0}. Total: {1} ({2} Items)"
                    />
                    <FilterBuilder defaultFields={money_rows} />
                    <FilterRow visible={true} />
                    <Sorting visible={true} />
                    <GroupPanel visible={true} />
                    <SearchPanel visible={true} />
                    <Editing
                        allowAdding={id !== null}
                        allowUpdating={true}
                        allowDeleting={true}
                        confirmDelete={true}
                        mode="batch"
                        selectTextOnEditStart={true}
                        useIcons={true}
                    />
                    <ColumnChooser enabled={false} />
                    <Scrolling columnRenderingMode="virtual" />
                    <KeyboardNavigation
                        editOnKeyPress={true}
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                    />
                    {renderColumns()}
                </DataGrid>
            </div>
        );
    };

    return <div className="table-container">{renderDataGrid()}</div>;
}

export default MoneyBills;