import "./ReportDesigner.css";
import "../../GlobalCSS.css";
import { Designer, Viewer } from "@grapecity/activereports-react";
import { Fragment, useRef, useState } from "react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";
import { Button, Popup } from "devextreme-react";
import { useEffect } from "react";

const ReportDesigner = ({ openDrawer }) => {

    const [designerVisible, setDesignerVisible] = useState(true);
    const [selectedReport, setSelectedReport] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const viewerRef = useRef();
    const designerRef = useRef();
    const [reportPromise, setReportPromise] = useState(null);
    const counter = useRef(0);
    const [reportStorage, setReportStorage] = useState(new Map());

    const getReportInfo = async () => {
        const reportInfo = await designerRef.current.getReport();
        console.log(JSON.stringify(reportInfo.definition))
    }

    function onCloseDialog() {
        setModalOpen(false);
        if (reportPromise) {
            reportPromise.resolve(null);
            setReportPromise(null);
        }
    }

    const onSave = function (info) {
        console.log(JSON.stringify(info.definition))
        const reportId = info.id || `report${counter.current++}`;
        setReportStorage(new Map(reportStorage.set(reportId, info.definition)));
        return Promise.resolve({ displayName: reportId });
    };

    const onSaveAs = function (info) {
        const reportId = info.id || `report${counter.current++}`;
        setReportStorage(new Map(reportStorage.set(reportId, info.definition)));
        return Promise.resolve({ id: reportId, displayName: reportId });
    };

    function updateToolbar() {
        var designButton = {
            key: "$openDesigner",
            text: "Edit in Designer",
            iconCssClass: "mdi mdi-pencil",
            enabled: true,
            action: () => {
                setDesignerVisible(true);
            },
        };
        viewerRef.current.toolbar.addItem(designButton);
        viewerRef.current.toolbar.updateLayout({
            default: [
                "$openDesigner",
                "$split",
                "$navigation",
                "$split",
                "$refresh",
                "$split",
                "$history",
                "$split",
                "$zoom",
                "$fullscreen",
                "$split",
                "$print",
                "$split",
                "$singlepagemode",
                "$continuousmode",
                "$galleymode",
            ],
        });
    }

    function onSelectReport(report) {
        designerRef.current.setReport({ id: report });
        setModalOpen(false);
    }

    useEffect(() => {
        if (selectedReport && reportPromise) {
            reportPromise.resolve(selectedReport);
            setReportPromise(null);
            setSelectedReport(null);
        }
    }, [selectedReport, reportPromise]);

    function onReportPreview(report) {
        updateToolbar();
        setDesignerVisible(false);
        viewerRef.current.open(report.definition);
        return Promise.resolve();
    }

    function onOpenReport() {
        getReportInfo();
        setModalOpen(true);
        return new Promise((resolve, reject) => {
            setReportPromise({ resolve, reject });
        });
    }

    return (
        <div id="designer-host" className={openDrawer ? 'shiftReportRight' : 'shiftReportLeft'}>
            <Fragment>
                <div id="viewer-host" style={{ display: designerVisible ? "none" : "block" }}>
                    <Viewer ref={viewerRef} />
                </div>
                <div id="designer-host" style={{ display: designerVisible ? "block" : "none" }}>
                    <Designer ref={designerRef} onRender={onReportPreview} onSave={onSave} onOpen={onOpenReport} />
                </div>
            </Fragment>

            <div>
                <Popup
                    visible={modalOpen}
                    onHiding={() => onCloseDialog(false)}
                    dragEnabled={false}
                    //hideOnOutsideClick={true}
                    showTitle={true}
                    title="Open Report"
                    height={350}
                    width={300}
                >
                    <div className="modal-body">
                        <strong><u>Select Report</u></strong>
                    </div>
                    <div>
                        <div className="modal-body">
                            <button
                                style={{ width: 160, height: 35, marginBlockStart: 10, fontSize: 16 }}
                                type="button"
                                className="list-group-item list-group-item-action"
                                onClick={() =>
                                    onSelectReport("/assets/reports/receipt.rdlx-json")
                                }
                            >
                                Receipt Report
                            </button>
                            <button
                                style={{ width: 160, height: 35, marginBlockStart: 10, fontSize: 16 }}
                                type="button"
                                className="list-group-item list-group-item-action"
                                onClick={() => onSelectReport("/assets/reports/payment.rdlx-json")}
                            >
                                Payment Report
                            </button>
                            <button
                                style={{ width: 160, height: 35, marginBlockStart: 10, fontSize: 16 }}
                                type="button"
                                className="list-group-item list-group-item-action"
                                onClick={() =>
                                    onSelectReport("/assets/reports/invoice.rdlx-json")
                                }
                            >
                                Invoice Report
                            </button>
                            <button
                                style={{ width: 160, height: 35, marginBlockStart: 10, fontSize: 16 }}
                                type="button"
                                className="list-group-item list-group-item-action"
                                onClick={() => onSelectReport("/assets/reports/purchase.rdlx-json")}
                            >
                                Purchase Report
                            </button>
                        </div>
                    </div>
                    <div className="modal-close">
                        <Button className="button in_button3"
                            onClick={() => onCloseDialog()}
                            width={100}
                        >
                            Close
                        </Button>
                    </div>
                </Popup>
            </div>
        </div>
    );
};

export default ReportDesigner;